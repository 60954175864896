import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import storeComponentWrapper from '../../stores/jobDispatcher'
import {proteinStatus} from "../../stores/job/JobParameters";
import {jobValidationStatus} from "../../stores/job/JobValidationStatus";

const styles = theme => ({
    root: {
        width: '100%',
        position: "sticky",
        top: "1rem"
    },
    resetContainer: {
        marginTop: 0,
        padding: theme.spacing.unit * 3
    },
    transition: {
        paddingBottom: 4
    }
});


const getSteps = function() {
    return [
        'Protein or sequence',
        'Job options',
        'Confirm submission'
    ];
};

class VerticalLinearStepper extends React.Component {
    render() {
        const { classes } = this.props;
        const steps = getSteps();
        let activeStep = -1;

        if(proteinStatus.isValid(this.props.jobParameters.proteinStatus)) activeStep = 1;

        switch (this.props.jobValidationStatus.jobValidity){
            case jobValidationStatus.SUBMITTED:
                activeStep = 3;
                break;
            default:
                break;
        }

        return (
            <Paper elevation={2}  className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length && (
                    <div className={classes.resetContainer}>
                        <Typography>All steps completed</Typography>
                    </div>
                )}
            </Paper>
        );
    }
}

VerticalLinearStepper.propTypes = {
    classes: PropTypes.object,
    jobParameters: PropTypes.object,
    jobValidationStatus: PropTypes.object
};

export default storeComponentWrapper(withStyles(styles)(VerticalLinearStepper));