import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import storeComponentWrapper from '../../../stores/jobDispatcher';
import { jobValidationStatus } from '../../../stores/job/JobValidationStatus';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import blue from '@material-ui/core/colors/blue';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import validator from 'validate.js';

import SimpleDialog from '../../dialog/SimpleDialog';
import SubmissionErrorDialog from './SubmissionErrorDialog';

import delay from "../../../utilities/ActionDelayer";

import NavigateNext from '@material-ui/icons/NavigateNext';
import Check from '@material-ui/icons/Check';
import Error from '@material-ui/icons/ErrorOutline';

import Typography from '@material-ui/core/Typography';

import JobSubmission from '../../../brokers/jobs/create/JobSubmission';
import ComplexJobSubmission from '../../../brokers/jobs/create/ComplexJobSubmission';
import UserInfoBroker from '../../../brokers/users/UserInfoBroker';

const status = {
    "LOADING": 0,
    "LOGGED_IN": 1,
    "LOGGED_OUT": 2
};


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    buttonProgress: {
        color: 'inherit',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    code: {
        color:"#00ff08",
        backgroundColor: "#000000f5",
        paddingLeft: 3,
        width: "100%"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },


    textFieldRoot: {
        width: 'calc(100% - 100px)',
        color: "inherit"
    },
    textFieldInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '10px 12px',
        width: '100%',
    },
    textFieldFormLabel: {
        fontSize: 18,
    },
});

function getSteps() {
    return ['Submit Job', 'Done'];
}

class SubmitJob extends React.Component {

    constructor(props){
        super(props);

        this.complexJob = this.props.complexJob !== undefined;

        this.state = {
            loading: false,
            name: this.complexJob ? this.props.complexJobParameters.name : this.props.jobParameters.name,
            email: this.complexJob ? this.props.complexJobParameters.email : this.props.jobParameters.email,
            error: undefined,
            resultsURL: "",
            jobHash: "",
            userLogged: false,
            status: status.LOADING,
        };
    }

    componentDidMount(){
        UserInfoBroker.run()
            .then((results) => {
                this.setState({
                    status: status.LOGGED_IN,
                    email: results[0].email
                })
            })
            .catch(() => {
                this.setState({
                    status: status.LOGGED_OUT
                })
            });
    }

    submitJob = () => {
        this.setState({
            loading: true
        });


        (this.complexJob ? ComplexJobSubmission.run(this.props.complexJobParameters) : JobSubmission.run(this.props.jobParameters))
            .then((response) => {
                this.props.action({
                    type: "UPDATE_JOB_STATUS",
                    payload: {
                        jobValidity: jobValidationStatus.SUBMITTED
                    }
                });
                this.setState({
                    loading: false,
                    jobHash: response.jobHash,
                    resultsURL: window.location.origin + "/jobs/" + response.jobHash
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    error: error
                });
                this.props.action({
                    type: "UPDATE_JOB_STATUS",
                    payload: {
                        jobValidity: jobValidationStatus.INVALID
                    }
                });
                throw error
            });
    };

    handleNext = () => {
        switch (this.props.jobValidationStatus.jobValidity){
            case jobValidationStatus.INVALID:
            case jobValidationStatus.UNSUBMITTED:
                this.submitJob();
                break;
            case jobValidationStatus.SUBMITTED:
                let { history } = this.props;
                history.push("/jobs/" + this.state.jobHash);
                break;
            default:
                break;
        }
    };

    handleBack = () => {
        switch (this.props.jobValidationStatus.jobValidity){
            case jobValidationStatus.INVALID:
            case jobValidationStatus.UNSUBMITTED:
                this.props.action({
                    type: "RESET_JOB_SUBMISSION"
                });
                break;
            case jobValidationStatus.SUBMITTED:
                this.props.action({
                    type: "UPDATE_JOB_STATUS",
                    payload: {
                        jobValidity: jobValidationStatus.UNSUBMITTED,
                    }
                });
                break;
            default:
                break;
        }
    };

    handleTextChange = key => event => {
        let value = event.target.value;

        this.setState({
            [key]: value,
        });

        if(this.complexJob){
            delay("SET_COMPLEX_JOB_PARAMETERS_" + key, () => this.props.action({
                type: "SET_COMPLEX_JOB_PARAMETERS",
                payload: {
                    [key]: value,
                }
            }), 500);
        } else {
            delay("SET_JOB_PARAMETERS_" + key, () => this.props.action({
                type: "SET_JOB_PARAMETERS",
                payload: {
                    [key]: value,
                }
            }), 500);
        }
    };

    render() {
        const { classes } = this.props;
        const steps = getSteps();
        let activeStep = 0;

        let button = <NavigateNext />;
        let text = "Click below to validate, see a summary and submit your job!";

        switch (this.props.jobValidationStatus.jobValidity){
            case jobValidationStatus.SUBMITTED:
                text = "Done. Please check back in a while to download your job results.";
                activeStep = 1;
                button = <Check />;
                break;
            case jobValidationStatus.INVALID:
                button = <Error />;
                text = "There was an error submitting your job. The error response is written below. " +
                    "You can try to resubmit your job.";
                activeStep = 0;
                break;
            case jobValidationStatus.UNSUBMITTED:
            default:
                button = <NavigateNext />;
                text = "Click below to submit your job!";
                activeStep = 0;
                break;
        }

        return (<Paper square elevation={2} >
            {this.state.error !== undefined && <SimpleDialog
                component={<SubmissionErrorDialog error={this.state.error}/>}
                open={true}
                dialogClosed={()=> this.setState({error: undefined})}
                title={"Request Error"}/>}
            {this.state.loading && <LinearProgress />}
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>

                                <Typography>{text}</Typography>
                                {jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity) && (
                                    this.state.status === status.LOGGED_IN ? <TextField
                                        id="jobName"
                                        label="Job Name (optional)"
                                        value={this.state.name}
                                        className={classes.textField}
                                        onChange={this.handleTextChange('name')}
                                        margin="normal"
                                    /> : <TextField
                                        id="email"
                                        label="Email Address (optional)"
                                        value={this.state.email}
                                        className={classes.textField}
                                        onChange={this.handleTextChange('email')}
                                        margin="normal"
                                        error={this.state.email === "" ? false : validator({
                                            form: this.state.email
                                        }, {
                                            form: {
                                                email: true
                                            }
                                        }) !== undefined}
                                    />
                                )}
                                {this.props.jobValidationStatus.jobValidity === jobValidationStatus.SUBMITTED &&
                                <TextField
                                    value={this.state.resultsURL}
                                    InputProps={{
                                        disableUnderline: true,
                                        disabled: true,
                                        classes: {
                                            root: classes.textFieldRoot,
                                            input: classes.textFieldInput,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        className: classes.textFieldFormLabel,
                                    }}
                                    className={classes.textFieldRoot}
                                />
                                }
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={this.handleBack}
                                        color="primary"
                                        className={classes.button}
                                    >
                                        {activeStep === 0 || activeStep === steps.length ? 'Reset' : 'Back'}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Go to job status page' : 'Next'}
                                        {button}
                                    </Button>
                                </div>
                            </StepContent>
                        </Step>
                    );
                })}
            </Stepper>
        </Paper>)
    }
}


SubmitJob.propTypes = {
    action: PropTypes.func,
    classes: PropTypes.object.isRequired,
    jobParameters: PropTypes.object,
    complexJob: PropTypes.bool,
    jobValidationStatus: PropTypes.object,
};

export default storeComponentWrapper(withRouter(withStyles(styles)(SubmitJob)));