import {sizes} from "./Component";

export const COMPONENTS = {
    contact_map: {
        title: "Contact map",
        size: sizes.medium
    },
    pdb_file: {
        title: "PDB file",
        size: sizes.medium
    },
    // MSA: {
    //     title: "MSA viewer",
    //     size: sizes.large
    // }
};