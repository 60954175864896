/*  Full Example:


    {
        identifier: "",
        title: "",
        description: [""],
        references: [""],
        seeAlso:[],
        valueLimitations: "The value ranges between 0 and 1.",
        values: [{
            value: "",
            meaning: ""
        }]
    }

*/

export const options = [
    {
        identifier: "execution",
        title: "Job execution",
        description: [
            "When you submit a job, the computation will be executed in various stages (see below). Each of the stages produces " +
            "some output files that can be viewed after that stage is completed (e.g. you will be able to view the MSA after align " +
            "and before other computations will be finished). Even if the job fails to run through completely, you will have access to " +
            "the results of earlier stages.",
            "In case your job fails, there will be a pre-formatted email that you can send us through a button in the webpage, so that we can have a look " +
            "at your job and resubmit it. If we resubmit your job, you will be able to view its results on the same page as before.",
            "At the end of all stages, an archive (.zip file) with the outcomes of all stages will be downloadable."
        ]
    },
    {
        identifier: "limitations",
        title: "Web submission limitations",
        description: [
            "The maximum length of a sequence submitted throught the web interface is 500 amino acids for monomers or 500 + 500 for complexes.",
            "Every monomer run (box in the job results page) runs independently and has 15GB RAM and 2 CPU cores allocated for 12 hours. " +
            "Complex runs are allocated an execution time of 24 hours. "   +
            "Runs will be automatically terminated after 12 hours (for monomers) or 24 hours (for complexes). " +
            "There is no automatic way of telling if your run was cancelled, " +
            "but we will try to hint to you that the job has been executing for too long on the results page."
        ]
    },
    {
        identifier: "monomer",
        title: "Monomer pipeline",
        description: [
            "The monomer pipeline runs 5 different computations on your submitted protein.",
            "You can submit a protein with a maximum length of 500 amino acids, and the submitted job will have a set runtime of 12 hours."
        ],
        values: [{
            value: "align",
            meaning: "The align stage will produce a multiple sequence alignment (MSA) of your reference sequence."
        },{
            value: "couplings",
            meaning: "The coupling stage will compute evolutionary couplings (ECs) between two positions in the sequence."
        },{
            value: "compare",
            meaning: "The compare stage will look for known 3D structures in the MSA produced from the first stage. " +
                "If there are some known structures, the pipeline will compare the validity of the computed ECs with respect to the ground truth of known structures."
        },{
            value: "mutate",
            meaning: "The mutate stage will asses the fitness of a sequence after mutating amino acids along the reference sequence, based on the EC model."
        },{
            value: "fold",
            meaning: "Using ECs and other constraints, the fold stage will predict several structures for the reference sequence and rank them based on various heuristics."
        },
        ],
        references: [
            "https://doi.org/10.1093/bioinformatics/bty862",
            "https://doi.org/10.1038/nbt.3769"
        ],
    },
    {
        identifier: "complex",
        title: "Complex pipeline",
        description: [
            "The complex pipeline runs 6 different computations on two submitted proteins.",
        ],
        values: [{
            value: "align_1",
            meaning: "The align_1 stage will produce a multiple sequence alignment (MSA) of your first sequence."
        },{
            value: "align_2",
            meaning: "The align_2 stage will produce a multiple sequence alignment (MSA) of your second sequence."
        },{
            value: "concatenate",
            meaning: "This stage will concatenate the two MSAs produced above to form one joint MSA."
        },{
            value: "couplings",
            meaning: "The coupling stage will compute evolutionary couplings (ECs) between two positions in the sequence based on the joint MSA."
        }, {
            value: "compare",
            meaning: "The compare stage will look for known 3D structures in the joint MSA. " +
                "If there are some known structures, the pipeline will compare the validity of the computed ECs with respect to the ground truth of known structures."
        },{
            value: "mutate",
            meaning: "The mutate stage will asses the fitness of a sequence after mutating amino acids along the original sequences, based on the EC model."
        }
        ],
        references: [
            "https://doi.org/10.1093/bioinformatics/bty862",
            "https://doi.org/10.1038/nbt.3769"
        ],
    },
];
