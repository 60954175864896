import { createMuiTheme } from '@material-ui/core/styles';

const palettes = [
    {
        primary: {
            light: '#d05ce3',
            main: '#9c27b0',
            dark: '#6a0080',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffff72',
            main: '#ffeb3b',
            dark: '#c8b900',
            contrastText: '#000000',
        },
    },
    {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        },
    },
    {
        primary: {
            light: '#58a5f0',
            main: '#0277bd',
            dark: '#004c8c',
            contrastText: '#fff',
        },
        secondary: {
            light: '#62727b',
            main: '#37474f',
            dark: '#102027',
            contrastText: '#ffffff',
        },
    },
    {
        primary: {
            main: '#d50000',
        },
        secondary: {
            main: '#304ffe',
        },
    },
];

export const theme = createMuiTheme({
    palette: palettes[2],
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiButton: {
            root: {
                border: 0,
                height: 32,
                padding: '0 30px',
                flexGrow: 1
            }
        },
    },
});