export function userDataSerializer(data) {

    let payload = {
      password: data.password
    };
    // basic empty checks
    // TODO: do these better!
    if (data.email !== "") payload["email"] = data.email;
    if (data.phone !== "") payload["phone"] = "001" + data.phone;

    return payload;
}