import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import {getTitle, getOption} from "../../../static/options";
import storeComponentWrapper from '../../../stores/jobDispatcher';
import SimpleDialog from '../../dialog/SimpleDialog';
import OptionDescription from '../../paperElements/OptionDescription';
import delay from '../../../utilities/ActionDelayer';

const styles = theme => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridInput: {
        width: "100%"
    },
    badge: {
        padding: "0 .6em 0 0"
    },
    infoIcon: {
        width: 17,
        color: "#858787",
        cursor: "pointer",
        height: "inherit"
    }
});

class JobOptions extends React.Component {

    constructor(props){
        super(props);

        this.state = {...this.props.jobParameters};
    }

    handleChange = key => event => {
        let value = event.target.value;

        this.setState({
            [key]: value,
        });

        delay("UPDATE_JOB_OPTIONS_" + key, () => this.props.action({
            type: "SET_JOB_PARAMETERS",
            payload: {
                [key]: value,
            }
        }), 1500);

    };

    handleArrayChange = (name, position) => event => {
        let tempNewArray = this.state[name];
        tempNewArray[position] = event.target.value;

        this.setState({
            [name]: tempNewArray,
        });

        this.props.action({
            type: "SET_JOB_PARAMETERS",
            payload: {
                [name]: tempNewArray,
            }
        });
    };

    addArrayElement = (name) => event => {
        let tempNewArray = this.state[name];
        tempNewArray.push("");

        this.setState({
            [name]: tempNewArray,
        });

        this.props.action({
            type: "SET_JOB_PARAMETERS",
            payload: {
                [name]: tempNewArray,
            }
        });
    };

    openOptionDialog = (optionName) => () => {
        this.setState({
            dialogObject: getOption(optionName)
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                {this.state.dialogObject && <SimpleDialog
                    component={<OptionDescription component={this.state.dialogObject}/>}
                    open={true}
                    dialogClosed={()=> this.setState({dialogObject: undefined})}
                    title={""}/>}
                <form className={classes.form}>
                    <Grid spacing={16} container>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Outputs
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xs={12} >
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("saveModel")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('saveModel')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.saveModel}
                                            onChange={this.handleChange('saveModel')}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="no" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Global parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xs={12} >
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("theta")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('theta')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.theta}
                                        onChange={this.handleChange('theta')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.theta < 0 || this.state.theta > 1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Alignment parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={5} md={5} xs={12} >
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCalculationFunction")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCalculationFunction')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.distanceCalculationFunction}
                                            onChange={this.handleChange('distanceCalculationFunction')}
                                        >
                                            <FormControlLabel value="bitscore" control={<Radio />} label="Bitscore" />
                                            <FormControlLabel value="eval" control={<Radio />} label="E value" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {this.state.distanceCalculationFunction === "bitscore" &&
                                <Grid item className={classes.gridInput} xl={7} md={7} xs={12}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCalculationFunctionBitscoreRange")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCalculationFunctionBitscoreRange')}
                                    />

                                    <Grid spacing={16} container>
                                        {this.state.distanceCalculationFunctionBitscoreRange.map( (value, position) =>
                                            <Grid item xs={2} key={position}>
                                                <Input
                                                    className={classes.gridInput}
                                                    value={value}
                                                    onChange={this.handleArrayChange('distanceCalculationFunctionBitscoreRange', position)}
                                                    inputProps={{
                                                        type: "number",
                                                        min: 0,
                                                        max: 1,
                                                        step: .1
                                                    }}
                                                    error={value < 0 || value > 1}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={2}>
                                            <AddIcon onClick={this.addArrayElement('distanceCalculationFunctionBitscoreRange')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }

                                {this.state.distanceCalculationFunction === "eval" && <Grid item className={classes.gridInput} xs={7}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCalculationFunctionEValRange")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCalculationFunctionEValRange')}
                                    />
                                    <Grid spacing={16}  container>
                                        {this.state.distanceCalculationFunctionEValRange.map( (value, position) =>
                                            <Grid item xs={2} key={position}>
                                                <Input
                                                    className={classes.gridInput}
                                                    value={value}
                                                    onChange={this.handleArrayChange('distanceCalculationFunctionEValRange', position)}
                                                    inputProps={{
                                                        type: "number",
                                                        min: 0,
                                                        step: .1
                                                    }}
                                                    error={value < 0}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={2}>
                                            <AddIcon onClick={this.addArrayElement('distanceCalculationFunctionEValRange')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("database")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('database')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.database}
                                            onChange={this.handleChange('database')}
                                        >
                                            <FormControlLabel value="uniref100" control={<Radio />} label="UniRef100" />
                                            <FormControlLabel value="uniref90" control={<Radio />} label="UniRef90" />
                                            <FormControlLabel value="uniprot" control={<Radio />} label="UniProt" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("columnThreshold")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('columnThreshold')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.columnThreshold}
                                        onChange={this.handleChange('columnThreshold')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.columnThreshold < 0 || this.state.columnThreshold > 1}
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("fragmentThreshold")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('fragmentThreshold')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.fragmentThreshold}
                                        onChange={this.handleChange('fragmentThreshold')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.fragmentThreshold < 0 || this.state.fragmentThreshold > 1}
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("nOfIterations")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('nOfIterations')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.nOfIterations}
                                        onChange={this.handleChange('nOfIterations')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 10,
                                            step: 1
                                        }}
                                        error={this.state.nOfIterations < 1 || this.state.nOfIterations > 10}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Couplings parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("couplingsProtocol")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('couplingsProtocol')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.couplingsProtocol}
                                            onChange={this.handleChange('couplingsProtocol')}
                                        >
                                            <FormControlLabel value="standard" control={<Radio />} label="PLMDCA" />
                                            <FormControlLabel value="mean_field" control={<Radio />} label="Mean field" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.couplingsProtocol === "standard" && <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("plmcIterations")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('plmcIterations')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.plmcIterations}
                                        onChange={this.handleChange('plmcIterations')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            step: 1
                                        }}
                                        error={this.state.plmcIterations < 1}
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Compare parameters
                            </Typography>
                            <Grid spacing={16}  container>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("compareSearchBy")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('compareSearchBy')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.compareSearchBy}
                                            onChange={this.handleChange('compareSearchBy')}
                                        >
                                            <FormControlLabel value="jackhmmer" control={<Radio />} label="Jackhmmer" />
                                            <FormControlLabel value="hmmsearch" control={<Radio />} label="HMM Search" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("compareBitscore")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('compareBitscore')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.compareBitscore}
                                        onChange={this.handleChange('compareBitscore')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            step: .1
                                        }}
                                        error={this.state.compareBitscore < 0}
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("nOfStructures")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('nOfStructures')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.nOfStructures}
                                        onChange={this.handleChange('nOfStructures')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 100,
                                            step: 1
                                        }}
                                        error={this.state.nOfStructures < 0 || this.state.nOfStructures > 100 }
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("nOfHits")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('nOfHits')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.nOfHits}
                                        onChange={this.handleChange('nOfHits')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 100,
                                            step: 1
                                        }}
                                        error={this.state.nOfHits < this.state.nOfStructures || this.state.nOfHits > 100 }
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("atomFilter")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('atomFilter')}
                                    />
                                    <br/>
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.atomFilter}
                                            onChange={this.handleChange('atomFilter')}
                                        >
                                            <FormControlLabel value="closest_atom" control={<Radio />} label="Closest atom" />
                                            <FormControlLabel value="CA" control={<Radio />} label="C-Alpha" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCutOff")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCutOff')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.distanceCutOff}
                                        onChange={this.handleChange('distanceCutOff')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 15,
                                            step: 1
                                        }}
                                        error={this.state.distanceCutOff < 1 || this.state.distanceCutOff > 15 }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Fold parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={4} md={4} xs={4}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("foldHighest")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('foldHighest')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.foldHighest}
                                        onChange={this.handleChange('foldHighest')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 3,
                                            step: .1
                                        }}
                                        error={this.state.foldHighest < 0 || this.state.foldHighest > 3 }
                                    />
                                </Grid>
                                <Grid item xl={4} md={4} xs={4}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("foldLowest")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('foldLowest')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.foldLowest}
                                        onChange={this.handleChange('foldLowest')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 3,
                                            step: .1
                                        }}
                                        error={this.state.foldLowest < 0 || this.state.foldLowest > 3 }
                                    />
                                </Grid>
                                <Grid item xl={4} md={4} xs={4}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("foldIncrease")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('foldIncrease')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.foldIncrease}
                                        onChange={this.handleChange('foldIncrease')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 2,
                                            step: .1
                                        }}
                                        error={this.state.foldIncrease < 0 || this.state.foldIncrease > 2 }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

JobOptions.propTypes = {
    action: PropTypes.func,
    classes: PropTypes.object.isRequired,
    jobParameters: PropTypes.object,
};

export default storeComponentWrapper(withStyles(styles)(JobOptions));
