import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        textAlign: "center"
    }),
    big: {
        fontSize: "5em"
    }
});


class ThreeDSeq extends React.Component {

    componentDidMount() {
        setTimeout(() => window.location.replace("https://api.evcouplings.org/3Dseq/"), 2000);

    }

    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>
                    Give us a moment, we are redirecting you!
                </Typography>
                <br />
                <br />
                <span role={"img"} aria-label={"don't know"} className={classes.big}>⚡️️</span>
            </Paper>
        </div>);
    }
}

ThreeDSeq.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default withStyles(styles)(ThreeDSeq);