import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';
import {getTitle, getOption} from "../../../static/options";
import storeComponentWrapper from '../../../stores/jobDispatcher';
import SimpleDialog from '../../dialog/SimpleDialog';
import OptionDescription from '../../paperElements/OptionDescription';
import delay from '../../../utilities/ActionDelayer';

const styles = theme => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridInput: {
        width: "100%"
    },
    badge: {
        padding: "0 .6em 0 0"
    },
    infoIcon: {
        width: 17,
        color: "#858787",
        cursor: "pointer",
        height: "inherit"
    }
});

class JobOptions extends React.Component {

    constructor(props){
        super(props);

        this.state = {...this.props.complexJobParameters};
    }

    handleChange = key => event => {
        let value = event.target.value;

        this.setState({
            [key]: value,
        });

        delay("UPDATE_SHARED_COMPLEX_JOB_OPTIONS_" + key, () => this.props.action({
            type: "SET_COMPLEX_JOB_PARAMETERS",
            payload: {
                [key]: value,
            }
        }), 1500);

    };

    openOptionDialog = (optionName) => () => {
        this.setState({
            dialogObject: getOption(optionName)
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                {this.state.dialogObject && <SimpleDialog
                    component={<OptionDescription component={this.state.dialogObject}/>}
                    open={true}
                    dialogClosed={()=> this.setState({dialogObject: undefined})}
                    title={""}/>}
                <form className={classes.form}>
                    <Grid spacing={16} container>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Outputs
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xs={12} >
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("saveModel")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('saveModel')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.saveModel}
                                            onChange={this.handleChange('saveModel')}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="no" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Global parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xs={12} >
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("theta")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('theta')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.theta}
                                        onChange={this.handleChange('theta')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.theta < 0 || this.state.theta > 1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Alignment parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("database")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('database')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.database}
                                            onChange={this.handleChange('database')}
                                        >
                                            <FormControlLabel value="uniref100" control={<Radio />} label="UniRef100" />
                                            <FormControlLabel value="uniprot" control={<Radio />} label="UniProt" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Concatenate parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={6} md={6} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("concatenateProtocol")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('concatenateProtocol')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.concatenateProtocol}
                                            onChange={this.handleChange('concatenateProtocol')}
                                        >
                                            <FormControlLabel value="best_hit" control={<Radio />} label="Best hit" />
                                            <FormControlLabel value="genome_distance" control={<Radio />} label="Genome distance" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.concatenateProtocol === "genome_distance" &&
                                <Grid item xl={6} md={6} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("genomeDistanceThreshold")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('genomeDistanceThreshold')}
                                    />
                                    <br />
                                    <Input
                                        value={this.state.genomeDistanceThreshold}
                                        onChange={this.handleChange('genomeDistanceThreshold')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 100000,
                                            step: 1
                                        }}
                                        error={this.state.genomeDistanceThreshold < 0 || this.state.genomeDistanceThreshold > 100000}
                                    />
                                </Grid>}
                                {this.state.concatenateProtocol === "best_hit" &&
                                <Grid item xl={6} md={6} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("useBestReciprocal")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('useBestReciprocal')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.useBestReciprocal}
                                            onChange={this.handleChange('useBestReciprocal')}
                                        >
                                            <FormControlLabel value={"yes"} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={"no"} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                }

                                <Grid item xl={6} md={6} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("columnThreshold")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('columnThreshold')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.columnThreshold}
                                        onChange={this.handleChange('columnThreshold')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.columnThreshold < 0 || this.state.columnThreshold > 1}
                                    />
                                </Grid>
                                <Grid item xl={6} md={6} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("fragmentThreshold")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('fragmentThreshold')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.fragmentThreshold}
                                        onChange={this.handleChange('fragmentThreshold')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.fragmentThreshold < 0 || this.state.fragmentThreshold > 1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Couplings parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("plmcIterations")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('plmcIterations')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.plmcIterations}
                                        onChange={this.handleChange('plmcIterations')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            step: 1
                                        }}
                                        error={this.state.plmcIterations < 1}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Compare parameters
                            </Typography>
                            <Grid spacing={16}  container>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("compareSearchBy")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('compareSearchBy')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.compareSearchBy}
                                            onChange={this.handleChange('compareSearchBy')}
                                        >
                                            <FormControlLabel value="jackhmmer" control={<Radio />} label="Jackhmmer" />
                                            <FormControlLabel value="hmmsearch" control={<Radio />} label="HMM Search" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("compareBitscore")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('compareBitscore')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.compareBitscore}
                                        onChange={this.handleChange('compareBitscore')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            step: .1
                                        }}
                                        error={this.state.compareBitscore < 0}
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("nOfStructures")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('nOfStructures')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.nOfStructures}
                                        onChange={this.handleChange('nOfStructures')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 100,
                                            step: 1
                                        }}
                                        error={this.state.nOfStructures < 0 || this.state.nOfStructures > 100 }
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("nOfHits")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('nOfHits')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.nOfHits}
                                        onChange={this.handleChange('nOfHits')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 100,
                                            step: 1
                                        }}
                                        error={this.state.nOfHits < this.state.nOfStructures || this.state.nOfHits > 100 }
                                    />
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("atomFilter")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('atomFilter')}
                                    />
                                    <br/>
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.atomFilter}
                                            onChange={this.handleChange('atomFilter')}
                                        >
                                            <FormControlLabel value="closest_atom" control={<Radio />} label="Closest atom" />
                                            <FormControlLabel value="CA" control={<Radio />} label="C-Alpha" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} md={3} xs={6}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCutOff")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCutOff')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.distanceCutOff}
                                        onChange={this.handleChange('distanceCutOff')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 15,
                                            step: 1
                                        }}
                                        error={this.state.distanceCutOff < 1 || this.state.distanceCutOff > 15 }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

JobOptions.propTypes = {
    action: PropTypes.func,
    classes: PropTypes.object.isRequired,
    jobParameters: PropTypes.object,
};

export default storeComponentWrapper(withStyles(styles)(JobOptions));
