import React from 'react';
import PropTypes from "prop-types";
import PfamBroker from '../../../brokers/pfam/PfamBroker';
import {FeatureViewer, TintedChell1DSection, ColorMapper} from "chell-viz";

// Test with uniprotID X1WG39

class PfamHits extends React.Component {

    constructor(){
        super();

        this.state = {
            data: [],
            rawData: []
        };
    }

    regionSelection = (id, position) => {
        let element = this.state.rawData[position];

        let result  =
            "ID: <a href='https://pfam.xfam.org/family/" + element['@accession'] + "'>" + element['@id'] + "</a>" +
            "\n" +
            "Start: " + element.location['@start'] +
            "\n" +
            "End: "+ element.location['@end'];

        return result;
    };

    featureClicked = (data) => {
        let firstFeature = data[0];

        this.props.regionSelected([firstFeature.sectionStart, firstFeature.sectionEnd]);
    };

    regionSelected = (selection) => {
        this.props.regionSelected([selection.start, selection.end]);
    };

    componentDidMount(){
        const colorMapper = new ColorMapper();

        PfamBroker.run(this.props.uniprotAccession)
            .then((results) => {

                if(results && results.pfam && results.pfam.entry && results.pfam.entry.matches){
                    let matches = results.pfam.entry.matches.match;

                    let data = [];
                    let rawData = [];

                    if(Array.isArray(matches)){
                        data = matches.map(match => {
                            return new TintedChell1DSection(
                                match['@id'],
                                match.location['@start'],
                                match.location['@end'],
                                colorMapper.getColorFor(match['@accession'])
                            );
                        });

                        rawData = matches;
                    } else if(matches){
                        data.push(new TintedChell1DSection(
                            matches['@id'],
                            matches.location['@start'],
                            matches.location['@end'],
                            colorMapper.getColorFor(matches['@accession'])
                            )
                        );

                        rawData = [matches];
                    }

                    this.setState({
                        data: data,
                        rawData: rawData
                    })
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        return (
            <FeatureViewer
                data={this.state.data}
                width={"100%"}
                maxLength={this.props.proteinLength}
                showGrouped={true}
                getTextForHover={this.regionSelection}
                onClickCallback={this.featureClicked}
                onSelectCallback={this.regionSelected}
            />
        )
    }
}

PfamHits.propTypes = {
    uniprotAccession: PropTypes.string.isRequired,
    regionSelected: PropTypes.func.isRequired,
    proteinLength: PropTypes.number
};


export default PfamHits;