import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";
import {userDataSerializer} from "./UserPayloadSerializer";

class LoginBroker {

    static run(userData){

        const endpoint = API_ENDPOINTS.userLogin;

        const data = userDataSerializer(userData);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                data: data,
                xhrFields: {
                    withCredentials: true
                },
                url: endpoint.uri,
                timeout: REQUEST_TIMEOUT,
                async: true,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default LoginBroker;