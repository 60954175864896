import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class ResponsiveDialog extends React.Component {

    constructor(props){
        super(props);

        this.state = {...props};
    }

    componentWillReceiveProps(nextProps) {
        this.setState({...nextProps});
    }

    handleRequestClose = () => {
        this.setState({ open: false });
        this.state.dialogClosed !== undefined && this.state.dialogClosed();
    };

    render() {
        const { fullScreen, component, title } = this.state;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={this.state.open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {component}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRequestClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);