import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link, withRouter} from 'react-router-dom';

import Work from '@material-ui/icons/Work';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import Reorder from '@material-ui/icons/Reorder';
import Login from '@material-ui/icons/AccountCircle';
import Logout from '@material-ui/icons/ExitToApp';
import People from '@material-ui/icons/People';
import Tool from '@material-ui/icons/Build';
import Home from '@material-ui/icons/Home';
import BorderClear from '@material-ui/icons/BorderClear';
import FormatQuote from '@material-ui/icons/FormatQuote';
import Folder from '@material-ui/icons/Folder';

import UserInfoBroker from "../../brokers/users/UserInfoBroker";
import UserLogoutBroker from "../../brokers/users/UserLogoutBroker";

import LinearProgress from '@material-ui/core/LinearProgress';


const style = {
    textDecoration: "none"
};

export const markslab = (
    <div>
        <Link to="/" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <Home />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
        </Link>
        <Link to="/resources" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <Tool />
                </ListItemIcon>
                <ListItemText primary="Resources" />
            </ListItem>
        </Link>
        <Link to="/people" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <People />
                </ListItemIcon>
                <ListItemText primary="People" />
            </ListItem>
        </Link>
        <Link to="/cite" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <FormatQuote />
                </ListItemIcon>
                <ListItemText primary="Cite" />
            </ListItem>
        </Link>
    </div>
);

export const evcouplings = (
    <div>
        <Link to="/choice" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <Work />
                </ListItemIcon>
                <ListItemText primary="New job" />
            </ListItem>
        </Link>
        <Link to="/precomputed" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <Folder />
                </ListItemIcon>
                <ListItemText primary="Precomputed jobs" />
            </ListItem>
        </Link>
        <Link to="/studio" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <BorderClear />
                </ListItemIcon>
                <ListItemText primary="Contact maps" />
            </ListItem>
        </Link>
        <Link to="/faq" style={style}>
            <ListItem button>
                <ListItemIcon>
                    <QuestionAnswer />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
            </ListItem>
        </Link>
    </div>
);


const status = {
    LOGGED_IN: 1,
    LOGGED_OUT: 2,
    REQUESTING: 3,
};

export const UserSection = withRouter(class _UserSection extends React.Component {
    constructor(){
        super();

        this.state = {
            email: "",
            phone: "",
            loggedIn: status.REQUESTING
        };

        this.getUserInfo();
    }

    getUserInfo = () => {
        UserInfoBroker.run()
            .then(results => {
                let userInfo = results[0];
                this.setState({
                    email: userInfo.email,
                    phone: userInfo.phone,
                    loggedIn: status.LOGGED_IN
                })
            })
            .catch(response => {
                let code = response[0].status;

                switch(code){
                    case 401:
                        this.setState({
                            loggedIn: status.LOGGED_OUT
                        });
                        break;
                    default:
                        throw response[0];
                }
            });
    };

    logout = () => {
        UserLogoutBroker.run()
            .then(() => {
                this.setState({
                    email: "",
                    phone: "",
                    loggedIn: status.LOGGED_OUT
                });

                let { history } = this.props;
                history.push("/");
            });
    };

    render() {

        switch(this.state.loggedIn){
            case status.LOGGED_IN:
                return <div>
                    <Link to="/user/jobs" style={style}>
                        <ListItem button>
                            <ListItemIcon>
                                <Reorder />
                            </ListItemIcon>
                            <ListItemText primary="My jobs" />
                        </ListItem>
                    </Link>
                    <ListItem button onClick={this.logout}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </div>;
            case status.REQUESTING:
                return <div>
                    <LinearProgress />
                    <ListItem button disabled={true}>
                        <ListItemIcon>
                            <Reorder />
                        </ListItemIcon>
                        <ListItemText primary="My jobs" />
                    </ListItem>
                    <ListItem button disabled={true}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </div>;
            case status.LOGGED_OUT:
            default:
                return <div>
                    <ListItem button disabled={true}>
                        <ListItemIcon>
                            <Reorder />
                        </ListItemIcon>
                        <ListItemText primary="My jobs" />
                    </ListItem>
                    <Link to="/login" style={style}>
                        <ListItem button>
                            <ListItemIcon>
                                <Login />
                            </ListItemIcon>
                            <ListItemText primary="Login" />
                        </ListItem>
                    </Link>
                </div>;
        }
    }
});