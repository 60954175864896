import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { people } from "../../static/markslab/people/people";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import ScrollableAnchor from 'react-scrollable-anchor';
import Launch from '@material-ui/icons/Launch';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        paddingBottom: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 20,
        paddingBottom: 20
    }),
    link: {
        color: "inherit"
    },
    launch: {
        height: ".6em"
    }
});

class People extends Component {
    render() {
        const { classes } = this.props;

        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xl={3} md={3} xs={12}>
                    <Paper square elevation={2} className={classes.paper}  >
                        <Typography variant="caption">
                            Go to:
                        </Typography>
                        <List>
                            {people.map(component =>
                                <a className={classes.link} key={component.category} href={"#" + component.identifier}>
                                    <ListItem button>
                                        <ListItemText primary={component.category} />
                                    </ListItem>
                                </a>
                            )}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xl={9} md={9} xs={12}>
                    <Grid spacing={16} container>
                        {people.map(component =>
                            <Grid key={component.category} item xs={12}>
                                <ScrollableAnchor id={component.identifier}>
                                    <Paper square elevation={2} className={classes.paper} >
                                        <Typography id={component.category} variant="display1">{component.category}</Typography>

                                        <List>
                                            {component.people.map(p =>
                                                <ListItem key={p.name}>
                                                    <Avatar alt={p.name} src={p.image} style={{
                                                        width: "150px",
                                                        height: "150px"
                                                    }} />
                                                    <ListItemText
                                                        primary={
                                                            <div>
                                                                <strong>{p.name}</strong> {p.role && " - " + p.role}
                                                            </div>
                                                        }
                                                        secondary={
                                                            <Typography component={"span"} variant={"body1"}>
                                                                <span style={
                                                                    {
                                                                        "textAlign": "justify"
                                                                    }
                                                                }>{p.bio}
                                                                </span>
                                                                <br />
                                                                <br />
                                                                {p.email && <a className={classes.link} href={"mailto:" + p.email}>Email<Launch className={classes.launch}/></a>}
                                                                <br />
                                                                {p.web && <a className={classes.link} target="_blank" rel="noopener noreferrer" href={p.web}>Web-page<Launch className={classes.launch}/></a>}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Paper>
                                </ScrollableAnchor>
                            </Grid>)}
                    </Grid>
                </Grid>
            </Grid>
        </div>);

    }
}

People.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(People);