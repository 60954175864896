/*  Full Example:


    {
        identifier: "",
        title: "",
        description: [""],
        references: [""],
        seeAlso:[],
        valueLimitations: "The value ranges between 0 and 1.",
        values: [{
            value: "",
            meaning: ""
        }]
    }

*/

export const options = [
    {
        identifier: "timeout",
        title: "Job timeout",
        description: ["If your job exceeds the allocated time, it will timeout (see \"Pipelines explained\" in the FAQ. ",
            "There are common reasons which drive a job to time out, and a time out is not an indication that something is wrong, " +
            "although, most likely, you will need to tweak some of the default parameters in your submission. ",
            "If a job terminates at the \"align\" stage (or align_1, align_2 for complexes), it most likely means you are " +
            "getting to many sequence hits in the reference databases. Try setting a more stringent b-value cutoff (higher number). " +
            "If you get this error in the complex pipeline, we suggest you first find an optimal cutoff via the monomer pipeline (which " +
            "allows you to submit several cutoffs at once), and then pick the \"best\" cutoff for each sequence individually. " +
            "If in the momoner pipeline one of your submitted cutoffs times out but the other ones didn't and produce good results, " +
            "you can use the other cutoffs instead.",
        ],
    },
    {
        identifier: "quality",
        title: "Run quality",
        description: ["The run quality is calculated as: n_eff/L, where n_eff: number of effective sequences, and l: protein length."],
        values: [{
            value: ">5",
            meaning: "A value bigger than 5 usually means that the run is very good."
        },{
            value: ">1",
            meaning: "A value bigger than 1 usually means that the run is good."
        },{
            value: ">0",
            meaning: "A value bigger than 0 usually means that the run is discrete."
        },
        ]
    },
    {
        identifier: "runCutoff",
        title: "Run cutoff",
        description: ["Run cutoffs are used to create different alignments on top of which to calculate ECs.",
        "When submitting protein complex jobs (protein-protein), only one cutoff is allowed."],
        values: [{
            value: "bX.XX",
            meaning: "A 'b' followed by a float means this run was aligned at a bitscore of X.XX."
        },{
            value: "eY",
            meaning: "An 'e' followed by a number Y means an E value cutoff of Y."
        },{
            value: "complex",
            meaning: "'complex' cutoffs means that you submitted a complex calculation, which only allows a single run with one cutoff per sequence."
        },
        ]
    },
];