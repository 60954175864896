import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Components
import MSAViewer from './MSAViewer';
import ContactMap from './ContactMap';
import PDBViewer from './PDBViewer';


const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        minHeight: 500
    }),
    title: {
        textDecoration: "underline",
    }
});

export const sizes = {
    medium: 6,
    large: 12
};


class Component extends React.Component {

    render() {
        const { classes, type, size, title } = this.props;

        let content = <div></div>;

        switch (type){
            case "MSA":
                content = <MSAViewer run={this.props.run} />;
                break;
            case "contact_map":
                content = <ContactMap run={this.props.run} />;
                break;
            case "pdb_file":
                content = <PDBViewer run={this.props.run} />;
                break;
            default:
                content = <div></div>;
        }

        return (
            <Grid item xl={size} md={size} xs={12}>
                <Paper square elevation={0} className={classes.paper}>
                    <Typography variant="body2" className={classes.title}>
                        {title}
                    </Typography>
                    {content}
                </Paper>
            </Grid>
        )
    }
}

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    run: PropTypes.object.isRequired
};

export default withStyles(styles)(Component);