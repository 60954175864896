export function jobParametersSerializer(data) {

    let payload = {
        "theta": parseFloat(data.theta),
        "distance_calculation_function": data.distanceCalculationFunction,
        "minimum_column_coverage": parseFloat(data.columnThreshold),
        "minimum_sequence_coverage": parseFloat(data.fragmentThreshold),
        "alignment_iterations": parseInt(data.nOfIterations, 10),
        "database": data.database,
        "couplings_protocol": data.couplingsProtocol,
        "plmc_iterations": parseInt(data.plmcIterations, 10),
        "compare_search_by": data.compareSearchBy,
        // SET TO THE SAME
        "compare_domain_threshold": parseFloat(data.compareBitscore),
        "compare_sequence_threshold": parseFloat(data.compareBitscore),
        //
        "compare_max_num_structures": parseInt(data.nOfStructures, 10),
        "compare_max_num_hits": parseInt(data.nOfHits, 10),
        "compare_distance_cutoff": parseInt(data.distanceCutOff, 10),
        "atom_filter": data.atomFilter,
        "fold_highest_count": parseFloat(data.foldHighest),
        "fold_lowest_count": parseFloat(data.foldLowest),
        "fold_increase": parseFloat(data.foldIncrease),
        "first_index": parseInt(data.startingPosition, 10)
    };

    if(data.protein.getUniprotAccession() !== undefined){
        payload["sequence"] = data.protein.getUniprotAccession();
    } else {
        payload["sequence"] = data.protein.sequence;
    }

    switch (data.distanceCalculationFunction){
        case "eval":
            payload["alignment_thresholds"] = data.distanceCalculationFunctionEValRange
                .filter((e) => e !== undefined && e !== "")
                .map((e) => parseFloat(e));
            break;
        case "bitscore":
        default:
            payload["alignment_thresholds"] = data.distanceCalculationFunctionBitscoreRange
                .filter((e) => e !== undefined && e !== "")
                .map((e) => parseFloat(e));
            break;
    }

    if(data.regionStart !== undefined && data.regionEnd !== undefined){
        payload['region_start'] = parseInt(data.regionStart, 10);
        payload['region_end'] = parseInt(data.regionEnd, 10);
    }

    // basic empty checks
    if (data.name !== "") payload["job_name"] = data.name;
    if (data.email !== "") payload["email"] = data.email;

    return payload;
}

export function complexJobParametersSerializer(data) {

    let payload = {
        "theta": parseFloat(data.theta),
        "concatenate_minimum_column_coverage": parseFloat(data.columnThreshold),
        "concatenate_minimum_sequence_coverage": parseFloat(data.fragmentThreshold),
        "database": data.database,
        "plmc_iterations": parseInt(data.plmcIterations, 10),
        "compare_search_by": data.compareSearchBy,
        // SET TO THE SAME
        "compare_domain_threshold": parseFloat(data.compareBitscore),
        "compare_sequence_threshold": parseFloat(data.compareBitscore),
        //
        "compare_max_num_structures": parseInt(data.nOfStructures, 10),
        "compare_max_num_hits": parseInt(data.nOfHits, 10),
        "compare_distance_cutoff": parseInt(data.distanceCutOff, 10),
        "atom_filter": data.atomFilter,
        "concatenate_protocol": data.concatenateProtocol,
        "genome_distance_threshold": parseInt(data.genomeDistanceThreshold, 10),
        "use_best_reciprocal": data.useBestReciprocal === "yes"
    };

    data.proteins.forEach((p, i) => {
        if(p.protein.getUniprotAccession() !== undefined){
            payload["sequence_" + (i+1)] = p.protein.getUniprotAccession();
        } else {
            payload["sequence_" + (i+1)] = p.protein.sequence;
        }

        if(p.regionStart !== undefined && p.regionEnd !== undefined){
            payload["region_start_" + (i+1)] = parseInt(p.regionStart, 10);
            payload["region_end_" + (i+1)] = parseInt(p.regionEnd, 10);
        }

        payload["first_index_" + (i+1)] = parseInt(p.startingPosition, 10);

        payload["distance_calculation_function_" + (i+1)] = p.distanceCalculationFunction;
        payload["alignment_iterations_" + (i+1)] = parseInt(p.nOfIterations, 10);

        switch (p.distanceCalculationFunction){
            case "eval":
                payload["alignment_threshold_" + (i+1)] = parseFloat(p.distanceCalculationFunctionEValRange);
                break;
            case "bitscore":
            default:
                payload["alignment_threshold_" + (i+1)] = parseFloat(p.distanceCalculationFunctionBitscoreRange);
                break;
        }
    });


    // basic empty checks
    if (data.name !== "") payload["job_name"] = data.name;
    if (data.email !== "") payload["email"] = data.email;

    return payload;
}