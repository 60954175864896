import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = ({ studio }) => ({ studio });

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators({
        studioAction: (a) => {
            return a
        },
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps);