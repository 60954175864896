// From https://github.com/facebook/create-react-app/issues/1277#issuecomment-345516463

export default class WebWorker {
    constructor(worker) {
        // let code = worker.toString();
        // code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

        // TODO: When updating the code in the worker, paste in the contentent of console.log(code) below!

        const blob = new Blob([`
        var onmessage = function onmessage(e) {
            // eslint-disable-line no-unused-vars
            // Write your code here...
            var _e$data = e.data,
                endpoint = _e$data.endpoint,
                method = _e$data.method,
                type = _e$data.type;
    
    
            fetch(endpoint, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: method
            }).then(function (res) {
                if (type === "json") {
                    return res.json();
                } else if (type === "text") {
                    return res.text();
                } else {
                    return {};
                }
            }).then(postMessage).catch(function (error) {
                return postMessage(error);
            });
        };
        `], { type: "application/javascript" });
        return new Worker(URL.createObjectURL(blob));
    }
}