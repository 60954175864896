import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {states as requestStates} from "../../utilities/Requests";

// Store
import storeComponentWrapper from '../../stores/jobDispatcher';

// Components
import JobInfoNugget from "../../components/jobs/info/JobInfoNugget";
import RegionDisplay from "../../components/jobs/info/RegionDisplay";
import RunInfoNugget from "../../components/runs/info/RunInfoNugget";
import JobInfo from "../../brokers/jobs/info/JobInfo";
import RunResults from '../../components/runs/info/RunResults';

const refreshRate = (process.env.REACT_REFRESH_RATE !== undefined ? parseInt(process.env.REACT_REFRESH_RATE, 10) : 30 * 10000);


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    fadingComponents: {
        transition: '.5s'
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    })
});

class JobStatusPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            requestStatus: requestStates.LOADING,
            jobInfo: {},
            runs: {}
        };

        this.fetchJobInfo();
    }

    fetchJobInfo = () => {
        JobInfo.run(this.props.match.params.jobHash)
            .then(([result]) => {

                let runs = {};

                result.runHashes.forEach(hash => {
                    runs[hash] = true;
                });

                this.setState({
                    jobInfo: result,
                    requestStatus: requestStates.LOADED,
                    runs: runs
                });
            })
            .catch(([error]) => {
                switch(error.status){
                    case requestStates.NOT_FOUND:
                        this.setState({
                            requestStatus: requestStates.NOT_FOUND
                        });
                        break;
                    default:
                        this.setState({
                            requestStatus: requestStates.ERROR
                        });
                }

            });
    };

    switchAutoUpdate = (runHash) => () => {

        let runs = this.state.runs;

        runs[runHash] = !runs[runHash];

        this.setState({
            runs: {...runs}
        });
    };

    render(){
        const { className, classes, match } = this.props;

        return (<Grid spacing={16} container className={classNames(classes.content, className)}>
            <Grid item xs={12}>
                <JobInfoNugget
                    jobHash={match.params.jobHash}
                    requestStatus={this.state.requestStatus}
                    jobInfo={this.state.jobInfo}
                    fetchJobInfo={this.fetchJobInfo}
                />
                <RegionDisplay jobHash={match.params.jobHash} />
            </Grid>

            {this.state.jobInfo.runHashes && Object.keys(this.state.runs).map(run =>
                <Grid
                    item
                    xl={(this.state.jobInfo.runHashes && this.state.jobInfo.runHashes.length) > 1 ? 6 : 12}
                    md={(this.state.jobInfo.runHashes && this.state.jobInfo.runHashes.length) > 1 ? 6 : 12}
                    xs={12}
                    key={run}
                >
                    <RunInfoNugget refreshRate={refreshRate} refreshSwitch={this.switchAutoUpdate(run)} refresh={this.state.runs[run]} runHash={run} />
                    <RunResults refreshRate={refreshRate} refresh={this.state.runs[run]} runHash={run} />
                </Grid>
            )}
        </Grid>);
    }
}

JobStatusPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    match: PropTypes.object,
    runSearch: PropTypes.object,
};

export default storeComponentWrapper(withStyles(styles)(JobStatusPage));