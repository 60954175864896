const publicUrl = "/resources/people/";

export const people = [
    {
        category: "Principal investigators",
        identifier: "principal",
        people: [

            /*
            Copy-pase for convenience

            {
                name: "name",
                role: "role",
                web: "https://marks.hms.harvard.edu/",
                bio: "Bio",
                email: "",
                image: publicUrl + "debbie.jpg"
            },

            */

            {
                name: "Debora S. Marks",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Systems Biology, Harvard Medical School\n",
                email: "debbie@hms.harvard.edu",
                image: publicUrl + "debbie.jpg"
            },
            {
                name: "Chris Sander",
                role: "",
                web: "http://www.sanderlab.org",
                bio: "Dana-Farber Cancer Institute, Harvard Medical School, Memorial Sloan Kettering Cancer Center",
                email: "",
                image: publicUrl + "chris.jpg"
            },
        ]
    },
    {
        category: "Development lead",
        identifier: "dev_lead",
        people: [
            {
                name: "Thomas Hopf",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School / Department of Cell Biology, Harvard Medical School",
                email: "thomas_hopf@hms.harvard.edu",
                image: publicUrl + "thomas.jpg"
            },
        ]
    },
    {
        category: "EVcouplings pipeline contributors",
        identifier: "contributors",
        people: [
            {
                name: "Thomas Hopf",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School / Department of Cell Biology, Harvard Medical School",
                email: "thomas_hopf@hms.harvard.edu",
                image: publicUrl + "thomas.jpg"
            },
            {
                name: "Anna G. Green",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School",
                email: "annaggreen@fas.harvard.edu",
                image: publicUrl + "anna.jpg"
            },
            {
                name: "Benjamin Schubert",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School / Department of Cell Biology, Harvard Medical School / cBio Center, Department of Biostatistics and Computational Biology, Dana-Farber Cancer Institute",
                email: "benjamin_schubert@hms.harvard.edu",
                image: publicUrl + "benjamin.jpg"
            },
            {
                name: "Sophia Mersmann",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Center for Bioinformatics, University of Tübingen",
                email: "sophia.mersmann1@gmail.com",
                image: publicUrl + "sophia.jpg"
            },
            {
                name: "Charlotta Schärfe",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School / Center for Bioinformatics, University of Tübingen / Applied Bioinformatics, Dept. of Computer Science, Tübingen",
                email: "charlotta_scharfe@hms.harvard.edu",
                image: publicUrl + "charlotta.jpg"
            },
            {
                name: "John Ingraham",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School",
                email: "ingraham@fas.harvard.edu",
                image: publicUrl + "john.jpg"
            },
            {
                name: "Agnes Toth-Petroczy",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School / Max Plank Institute of Molecular Cell Biology and Genetics",
                email: "agnes_toth-petroczy@hms.harvard.edu",
                image: publicUrl + "agnes.jpg"
            },
            {
                name: "Kelly Brock",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School",
                email: "kelly_brock@hms.harvard.edu",
                image: publicUrl + "kelly.jpg"
            },
            {
                name: "Adam Riesselman",
                role: "",
                web: "https://www.linkedin.com/in/adam-riesselman-2731ba57",
                bio: "Department of Systems Biology, Harvard Medical School / Program in Biomedical Informatics, Harvard Medical School",
                email: "ariesselman@g.harvard.edu",
                image: publicUrl + "adam.jpg"
            },
            {
                name: "Chan Kang",
                role: "",
                web: "https://marks.hms.harvard.edu/",
                bio: "Department of Systems Biology, Harvard Medical School",
                email: "",
                image: publicUrl + "chang.jpg"
            },
            {
                name: "Christian Dallago",
                role: "",
                web: "https://github.com/sacdallago",
                bio: "Department of Systems Biology, Harvard Medical School / Department of Cell Biology, Harvard Medical School / Department of Informatics, Technische Universität München",
                email: "sacdallago+request@googlemail.com",
                image: publicUrl + "chris_d.png"
            }
        ]
    },
    {
        category: "Web applications and API",
        identifier: "web",
        people: [
            {
                name: "Christian Dallago",
                role: "",
                web: "https://github.com/sacdallago",
                bio: "Department of Systems Biology, Harvard Medical School / Department of Cell Biology, Harvard Medical School / Department of Informatics, Technische Universität München",
                email: "sacdallago+request@googlemail.com",
                image: publicUrl + "chris_d.png"
            }
        ]
    },
];