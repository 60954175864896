import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

// CHANGE IN Drawer.js TOO!!
const DRAWERWIDTH = 250;

const styles = theme => ({
    appBar: {
        position: 'absolute',
        zIndex: theme.zIndex.navDrawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: DRAWERWIDTH,
        width: `calc(100% - ${DRAWERWIDTH}px)`
    },
    menuButton: {
        marginLeft: 15,
        color: theme.palette.primary.contrastText,
    },
    hide: {
        display: 'none',
    },
    title: {
        flex: 1
    },
    loginButton: {
        flex: 0
    }
});

class Navigation extends React.Component {
    state = {
        open: false,
        count: 0
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.drawerState,
        });
    }

    handleDrawerOpen = () => {
        this.setState({
            count: this.state.count + 1
        });

        this.props.onDrawerChange(true);
    };

    render() {
        const { classes } = this.props;

        return (
            <AppBar className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
                <Toolbar disableGutters={!this.state.open}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpen}
                        className={classNames(classes.menuButton, this.state.open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {this.state.count < 1 && "⇠ Click here to open the Menu"}
                </Toolbar>
            </AppBar>
        );
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
    drawerState: PropTypes.bool,
    onDrawerChange: PropTypes.func,
};

export default withStyles(styles)(Navigation);