import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";

class UserInfoBroker {

    static run(){

        const endpoint = API_ENDPOINTS.userInfo;

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                url: endpoint.uri,
                xhrFields: { withCredentials: true },
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default UserInfoBroker;