import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import delay from "../../utilities/ActionDelayer";
import TextField from '@material-ui/core/TextField';
import classnames from "classnames";
import {states as requestStatus} from "../../utilities/Requests";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import PrecomputedBatchInfo from '../../components/precomputed/PrecomputedBatchInfo';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

// Brokers
import PrecomputedSearch from '../../brokers/precomputed/PrecomputedSearch';
import PrecomputedBroker from '../../brokers/precomputed/PrecomputedFetch';
import URI from "urijs";


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1100,
        },
    },
    fadingComponents: {
        transition: '.5s'
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        minHeight: 62
    }),
    paperMargin: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
    }),
    a: {
        textDecoration: "none"
    },
    textfield: {
        width: "100%"
    },
    underline: {
        textDecoration: "underline"
    },
    card: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

class PrecomputedSearchPage extends React.Component {

    constructor(props) {
        super(props);

        let queries = new URI().search(true);

        this.state = {
            query: queries.q || "",
            status: requestStatus.LOADING,
        };
    }

    componentDidMount(){
        this.handleChange();
    }

    handleChange = () => {
        let textInput = this.state.query;

        PrecomputedSearch.run(textInput)
            .then(results => {
                this.hits = results[0];

                if(results[0].length === 1){
                    this.selected(results[0][0])();
                } else {
                    this.setState({
                        status: requestStatus.RESULTS
                    });
                }
            })
            .catch(error => {
                this.hits = null;

                console.error(error);

                this.setState({
                    status: requestStatus.NO_RESULTS
                })
            });
    };

    queryChange = (event) => {
        this.setState({
            query: event.target.value.toUpperCase(),
            status: requestStatus.LOADING
        });

        delay("LOCAL_RUNS_INPUT_CHANGE", this.handleChange, 1000);
    };

    loadIdentifier = (identifier) => () => {
        switch (identifier) {
            case "uniprot":
                this.setState({
                    query: "W0T9X4",
                    status: requestStatus.LOADING
                }, this.handleChange);
                break;

            case "pfam":
                this.setState({
                    query: "PF12063",
                    status: requestStatus.LOADING
                }, this.handleChange);
                break;
            default:
                break;
        }
    };

    selected = (hit) => () => {

        let url = new URI();
        let { history } = this.props;

        this.hits = [hit];

        url.search({
            q: hit.uniprotID,
            // start: hit.start,
            // end: hit.end
        });

        history.push({
            search: url.search()
        });

        this.setState({
            query: hit.uniprotID,
            status: requestStatus.LOADING
        });

        PrecomputedBroker.run(hit.uniprotID, hit.start, hit.end)
            .then(results => {
                this.selectedRun = results[0];

                this.setState({
                    status: requestStatus.RESULTS
                });
            })
            .catch(error => {
                this.hits = null;

                this.setState({
                    status: requestStatus.NO_RESULTS
                });

                console.error(error);
            });
    };

    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xs={12}>
                    {this.state.status === requestStatus.LOADING && <LinearProgress />}
                    <Paper square className={classes.paperMargin}>
                        <Typography variant={"h5"}>
                            Search for jobs
                        </Typography>
                        <TextField
                            className={classes.textfield}
                            id="uniprot-search"
                            margin="normal"
                            onChange={this.queryChange}
                            value={this.state.query}
                            autoFocus
                        />
                        <Typography component={"div"} variant="body1">
                            {'Search can be a '}
                            <strong className={classnames("pointer", classes.underline)} onClick={this.loadIdentifier('uniprot')}>{"UniProt Accession"}</strong>
                            {', or a '}
                            <strong className={classnames("pointer", classes.underline)} onClick={this.loadIdentifier('pfam')}>{"PFAM Accession"}</strong>
                            .
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid spacing={16} container>
                        {this.hits && this.hits.map(e => {
                            return <Grid xs={12} md={4} xl={4} item key={"" + e.uniprotID + "" + e.start + "" + e.end}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={"https://uniprot.org/uniprot/" +  e.uniprotID}>
                                                {e.uniprotID}
                                            </a>
                                        </Typography>
                                        <Typography className={classes.pos} color="textSecondary" component={"span"}>
                                            {"Pfam Accession: "}
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={"https://pfam.xfam.org/family/" +  e.pfamAccession}>
                                                {e.pfamAccession}
                                            </a>
                                        </Typography>
                                        <Typography component="p">
                                            {"Region: " + e.start + ", " + e.end}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" variant={"contained"} color={"secondary"} onClick={this.selected(e)}>View Job results</Button>
                                    </CardActions>
                                </Card>
                            </Grid>;
                        })}
                    </Grid>
                </Grid>
                {this.selectedRun && Object.keys(this.selectedRun.batches).sort().map(batchKey => {
                    let batch = this.selectedRun.batches[batchKey];

                    return <Grid item xl={6} md={6} xs={12} key={batchKey}>
                        <PrecomputedBatchInfo
                            batch={batch}
                            job={this.selectedRun}
                            batchKey={batchKey}
                        />
                    </Grid>;
                })}
            </Grid>
        </div>);
    }
}

PrecomputedSearchPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withRouter(withStyles(styles)(PrecomputedSearchPage));