import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import storeComponentWrapper from '../../../stores/jobDispatcher';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import JobInfo from '../../../brokers/jobs/info/JobInfo';
import Grid from '@material-ui/core/Grid';
import {states as requestStates} from "../../../utilities/Requests";
import Download from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import SequenceBroker, {FORMATS} from '../../../brokers/sequences/SequencesBroker';


import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    center: {
        textAlign: "center",
        margin: "auto"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
        fontSize: "2em"
    },
    icon: {
        marginLeft: theme.spacing.unit,
    },
    actionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: 1,
        paddingRight: 10,
        color: "inherit"
    },
    a: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        textDecoration: "none",
        color: "inherit",
    },
    big: {
        fontSize: "5em",
    },
});


class JobInfoNugget extends React.Component {

    constructor(props){
        super(props);

        if(this.props.jobInfo !== undefined && !isNaN(this.props.requestStatus)){
            this.state = {
                requestStatus: this.props.requestStatus,
                jobInfo: this.props.jobInfo
            };
        } else {
            this.state = {
                requestStatus: requestStates.LOADING,
                jobInfo: {},
            };

            this.fetchJobInfo();
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({...nextProps});
    }

    downloadConfig = () => {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.state.jobInfo.config));
        element.setAttribute('download', "config.txt");

        element.style.display = 'none';
        document.body.appendChild(element);
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    downloadProteinAndRegion = () => {
        SequenceBroker.run({
            jobHash: this.props.jobHash,
            format: FORMATS.fasta
        })
            .then(result => {
                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(result));
                element.setAttribute('download', "job.fasta");

                element.style.display = 'none';
                document.body.appendChild(element);
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            })
            .catch(e => console.error(e))
    };

    fetchJobInfo = () => {
        JobInfo.run(this.props.jobHash)
            .then(([result]) => {
                this.setState({
                    jobInfo: result,
                    requestStatus: requestStates.LOADED
                });
            })
            .catch(() => {
                this.setState({
                    requestStatus: requestStates.ERROR
                });
            });
    };

    render() {
        const { classes } = this.props;

        const jobName = this.state.jobInfo.jobName ? this.state.jobInfo.jobName : "Anonymous job";

        switch (this.state.requestStatus) {
            case requestStates.LOADED:
                return (<Paper className={classes.paper} square elevation={2} >
                    <Grid spacing={16} container>
                        <Grid item xl={10} md={10} xs={12}>
                            <Typography variant="h6">
                                {jobName}
                            </Typography>
                            <Typography variant="caption">
                                This job was created on {new Date(this.state.jobInfo.createdAt).toUTCString()}.
                            </Typography>
                            <Typography variant="caption">
                                This job was last updated on {new Date(this.state.jobInfo.updatedAt).toUTCString()}.
                            </Typography>
                        </Grid>
                        <Grid item xl={2} md={2} xs={12} className={classes.center}>
                            <Button
                                color="secondary"
                                onClick={this.downloadConfig}
                                fullWidth={true}
                            >
                                Config
                                <Download className={classes.icon}/>
                            </Button>
                            <Button
                                color="primary"
                                onClick={this.downloadProteinAndRegion}
                                fullWidth={true}
                            >
                                Fasta
                                <Download className={classes.icon}/>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>);
            case requestStates.LOADING:
                return (<Paper className={classes.paper} square elevation={2} >
                    <div className={classes.center}>
                        <CircularProgress/>
                    </div>
                    <Typography variant="body1" align="center">
                        Fetching information
                    </Typography>
                </Paper>);
            case requestStates.ERROR:
                return (<Paper className={classes.paper} square elevation={2} >
                    <Typography variant="body1" align="center">
                        There was an error fetching the info for this job.
                    </Typography>
                    <Typography variant="body2" align="center" className={"pointer"} onClick={this.props.fetchJobInfo ? this.props.fetchJobInfo : this.fetchJobInfo}>
                        You can retry fetching the job info by clicking here.
                    </Typography>
                </Paper>);
            case requestStates.NOT_FOUND:
                return <Paper className={classNames(classes.paper, classes.center)} square elevation={2} >
                    <Typography variant={"h6"} align="center">
                        This job was not found. Most likely it has expired. You can resubmit it.
                    </Typography>
                    <br />
                    <br />
                    <span role={"img"} aria-label={"don't know"} className={classes.big}>🔍⏱🤷‍👩🏻‍💻️</span>
                </Paper>;
            default:
                return <Paper/>;
        }
    }
}

JobInfoNugget.propTypes = {
    classes: PropTypes.object.isRequired,
    jobHash: PropTypes.string,
    jobInfo: PropTypes.object,
    requestStatus: PropTypes.number,
    fetchJobInfo: PropTypes.func
};

export default storeComponentWrapper(withStyles(styles)(JobInfoNugget));