import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";
import {userDataSerializer} from "./UserPayloadSerializer";

class SignupBroker {

    static run(userData){

        const endpoint = API_ENDPOINTS.userSignup;

        const data = userDataSerializer(userData);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                data: JSON.stringify(data),
                contentType: "application/json; charset=utf-8",
                url: endpoint.uri,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default SignupBroker;