import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Component from "./Component";
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { COMPONENTS } from "./components";
import {Link} from 'react-router-dom';
import red from '@material-ui/core/colors/red';
import classnames from "classnames";
import {CouplingContextProvider} from 'chell-viz';

import Delete from '@material-ui/icons/Delete';
import OpenInNew from '@material-ui/icons/OpenInNew';
import StudioDispatcher from "../../stores/studioDispatcher";

const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    center: {
        textAlign: "center"
    },
    button: {
        margin: theme.spacing.unit,
    },
    right: {
        textAlign: "right"
    },
    title: {
        paddingBottom: 4
    },
    infoIcon: {
        width: 17,
        color: "black",
        height: "inherit"
    },
    link: {
        color: "inherit",
        textDecoration: "underline",
        cursor: "pointer"
    },
    red: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
});

class Panel extends React.Component {

    state = {
        components: [
            {...COMPONENTS.contact_map, type: "contact_map"},
            {...COMPONENTS.pdb_file, type: "pdb_file"}
        ],
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = key => event => {
        if(key){
            this.setState({
                components: [...this.state.components, {...COMPONENTS[key], type: key}],
                anchorEl: null
            });
        } else {
            this.setState({ anchorEl: null });
        }
    };

    removeFromStudio = () => {
        this.props.studioAction({
            type: "REMOVE_RUN_STUDIO",
            payload: {
                run: this.props.run
            }
        });
    };

    render() {
        const { classes, run } = this.props;
        const { anchorEl } = this.state;

        let header = null;
        let returnToJob = null;

        if(run.runHash !== undefined){
            header = <div>
                <Typography variant="h6" className={classes.title}>
                    {"Run id: " + run.runHash.substr(0, 32)}
                </Typography>
                <Typography variant="caption" className={classes.title}>
                    {"Cut-off: " + run.runHash.substr(33)}
                </Typography>
            </div>;
            returnToJob = <Typography variant="body1" component={"div"}>
                <Link to={"/jobs/" + run.runHash.substr(0, 32)} className={classes.link}>
                    {"Go back to job "}
                    <OpenInNew className={classes.infoIcon}/>
                </Link>
            </Typography>;
        } else {
            header = <div>
                <Typography variant="h6" className={classes.title}>
                    {"Uniprot Accession: " + run.uniprotID}
                </Typography>
                <Typography variant="caption" className={classes.title}>
                    {"Cut-off: " + run.batch + ", region: " + run.start + "-" + run.end}
                </Typography>
            </div>;

            returnToJob = <Typography variant="body1" component={"div"}>
                <Link to={"/precomputed?q=" + run.uniprotID} className={classes.link}>
                    {"Go back to search "}
                    <OpenInNew className={classes.infoIcon}/>
                </Link>
            </Typography>;
        }

        return (
            <Grid item xl={12} md={12} xs={12}>
                <Paper square elevation={2} className={classes.paper}  >
                    {header}
                    {returnToJob}
                    <Grid spacing={16} container>
                        <CouplingContextProvider>
                            {this.state.components.map((componentType, i) => <Component key={i} run={run} {...componentType}/>)}
                        </CouplingContextProvider>
                        <Grid item xl={12} md={12} xs={12} className={classes.right}>
                            <Button variant="fab"
                                    aria-label="add"
                                    className={classnames(classes.button, classes.red)}
                                    onClick={this.removeFromStudio}
                            >
                                <Delete />
                            </Button>
                            <Button variant="fab"
                                    color="primary"
                                    aria-label="add"
                                    className={classes.button}
                                    onClick={this.handleClick}
                            >
                                <AddIcon />
                            </Button>
                            <Menu
                                id={"component-selection-" + this.props.hash}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose()}
                            >
                                {Object.keys(COMPONENTS).map(e => <MenuItem key={e} onClick={this.handleClose(e)}>{COMPONENTS[e].title}</MenuItem>)}
                            </Menu>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

Panel.propTypes = {
    classes: PropTypes.object.isRequired,
    run: PropTypes.object
};

export default StudioDispatcher(withStyles(styles)(Panel));