import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Space = () => <div style={{margin: "2em 0"}} />;

class SubmissionErrorDialog extends React.Component {
    render() {
        const { error } = this.props;

        return (
            <div>
                <Typography variant="body1" gutterBottom align="justify">
                    {"There was an error submitting your request. This might be a temporary error, or an error bound " +
                    "to the job you are trying to submit. Please, try again later or make sure all your options are valid. " +
                    "A full stack trace of the error is available below."}
                </Typography>
                <Space/>
                <code>
                    {JSON.stringify(error)}
                </code>
            </div>
        );
    }
}

SubmissionErrorDialog.propTypes = {
    error: PropTypes.object.isRequired,
};

export default SubmissionErrorDialog;