import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../../global";
import jQuery from "jquery";

export const files = {
    alignmentFile: "alignment_file",
    ECComparedAllFile: "ec_compared_all_file",
    monomerContactsFile: "monomer_contacts_file",
    alignmentStatisticsFile: "statistics_file",
    foldingRankingFile: "folding_ranking_file",
    ecFile: "ec_file",
    enrichmentFile: "enrichment_file",
    mutationMatrixFile: "mutation_matrix_file",
    archiveFile: "archive_file",
    evZoomFile: "evzoom_file",
    mutationMatrixPlots: "mutation_matrix_plot_files",
    foldingComparisonFile: "folding_comparison_file",
    remappedPDBFiles: "remapped_pdb_files"
};

class DownloadFile {

    static run(hash, alias){

        const endpoint = API_ENDPOINTS.runGetFilesList;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);
        endpoint_URI.addQuery("f", alias);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                async: true,
                success: (...args) => {
                    let files = args[0];

                    this.downloadFromObjectId(hash, files[0]._id);

                    return resolve();
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }

    static getFileContent(hash, alias){

        const endpoint = API_ENDPOINTS.runGetFilesList;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);
        endpoint_URI.addQuery("f", alias);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                async: true,
                success: (...args) => {
                    let files = args[0];

                    if(files.length < 1){
                        return reject("No corresponding file or run found");
                    }

                    return this.getContentFromObjectId(hash, files[0]._id)
                        .then(r => resolve(r))
                        .catch(e => reject(e));
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }

    static getContentFromObjectId(hash, objectId){
        const endpoint = API_ENDPOINTS.runDownload;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);
        endpoint_URI.addQuery("i", objectId);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT*100,
                async: true,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }

    static downloadFromObjectId(hash, objectId){
        const endpoint = API_ENDPOINTS.runDownload;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);
        endpoint_URI.addQuery("i", objectId);

        this.download(endpoint_URI);
    }

    static download(href){
        let a = document.createElement('a');
        a.href = href;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
    }
}


export default DownloadFile;