import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {HotTable} from '@handsontable/react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import Papa from 'papaparse';
import URI from 'urijs';
import DownloadFile from '../../brokers/runs/download/DownloadFile';
import PrecomputedDownload, {queryType} from '../../brokers/precomputed/PrecomputedDownload';
import {states as requestStates} from "../../utilities/Requests";
import {files as fileNames} from "../../brokers/runs/download/DownloadFile";
import { readTextFile } from "../../utilities/UTFExtractor";
import 'handsontable/dist/handsontable.full.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Download from '@material-ui/icons/CloudDownload';

// Files
import ECComparedAllFile from './ec_compared_all_file.md';
import monomerContactsFile from './monomer_contact_file.md';
import alignmentStatisticsFile from './alignment_statistics_file.md';
import {API_ENDPOINTS} from "../../brokers/global";


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
        paddingBottom: 50
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    center: {
        textAlign: "center"
    },
    markdown: {
        lineHeight: "1.4em"
    },
    fadingComponents: {
        transition: '.5s'
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        textAlign: "justify",
        fontFamily: theme.typography.fontFamily
    }),
    link: {
        color: "inherit",
        textDecoration: "inherit"
    },
    backIcon: {
        position: "relative",
        top: "0.15em"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});

class TablePage extends React.Component {

    state = {
        request: requestStates.LOADING,
        missingQuery: false,
        ECComparedAllFile: "",
        monomerContactsFile: "",
        alignmentStatisticsFile: ""
    };

    constructor(props){
        super(props);

        let queries = new URI().search(true);

        // Needed for all jobs
        this.runHash = queries.q;
        this.file_name = queries.f;

        // Only relevant if precomputed
        this.batch = queries.b;
        this.start = queries.start;
        this.end = queries.end;
    }

    componentDidMount() {
        if(this.batch !== undefined && this.start !== undefined && this.end !== undefined && this.runHash !== undefined && this.file_name !== undefined){
            PrecomputedDownload.run(this.runHash, this.batch, this.start, this.end, {
                type: queryType.alias,
                query: this.file_name
            })
                .then(f => {
                    let data = Papa.parse(f[0], {
                        header: true,
                        skipEmptyLines: true
                    });

                    this.table = <HotTable data={data.data} rowHeaders={true} colHeaders={data.meta.fields} height={700} width={"100%"}  stretchH="all" readOnly/>;

                    this.setState({
                        request: requestStates.LOADED
                    })
                })
                .catch(e => {
                    console.error(e);

                    this.setState({
                        request: requestStates.ERROR
                    })
                });

        } else if(this.runHash !== undefined && this.file_name !== undefined) {
            DownloadFile.getFileContent(this.runHash, this.file_name)
                .then(f => {
                    let data = Papa.parse(f[0], {
                        header: true,
                        skipEmptyLines: true
                    });

                    this.table = <HotTable data={data.data} rowHeaders={true} colHeaders={data.meta.fields} height={700} width={"100%"}  stretchH="all" readOnly/>;

                    this.setState({
                        request: requestStates.LOADED
                    })
                })
                .catch(e => {
                    console.error(e);

                    this.setState({
                        request: requestStates.ERROR
                    })
                });
        } else {
            // Either f or q missing in URL
            this.setState({
                missingQuery: true
            })
        }

        switch(this.file_name){
            case fileNames.ECComparedAllFile:
                readTextFile(ECComparedAllFile)
                    .then(text => this.setState({ECComparedAllFile: text}))
                    .catch(e => console.error(e));
                break;
            case fileNames.monomerContactsFile:
                readTextFile(monomerContactsFile)
                    .then(text => this.setState({monomerContactsFile: text}))
                    .catch(e => console.error(e));
                break;
            case fileNames.alignmentStatisticsFile:
                readTextFile(alignmentStatisticsFile)
                    .then(text => this.setState({alignmentStatisticsFile: text}))
                    .catch(e => console.error(e));
                break;
            default:
            // Do nothing
        }
    }

    download = () => {
        if(this.batch !== undefined && this.start !== undefined && this.end !== undefined && this.runHash !== undefined && this.file_name !== undefined) {
            // PrecomputedDownload
            let downloadLocation = API_ENDPOINTS.precomputedJobDownload.uri.clone();

            downloadLocation.addQuery("q", this.runHash);
            downloadLocation.addQuery("b", this.batch);
            downloadLocation.addQuery("start", this.start);
            downloadLocation.addQuery("end", this.end);

            downloadLocation.addQuery("a", this.file_name);

            DownloadFile.download(downloadLocation);

        } else if(this.runHash !== undefined && this.file_name !== undefined) {
            DownloadFile.run(this.runHash, this.file_name)
                .then()
                .catch(e => console.error(e));
        }
    };

    render(){
        const { classes } = this.props;

        let display = <div/>;
        let help = null;

        if(this.state.missingQuery) {
            help = <Paper className={classes.paper}>
                <Typography variant={"h6"}>
                    Your URL is invalid!
                </Typography>
                <Typography variant={"body1"}>
                    {'This page will display table-like files of specific runs. The prerequisite for this to work ' +
                    'is that your url contains a valid file type and run hash. Your URL should be formatted like: \n' +
                    'https://v1.evcouplings.org/table/'}<strong>{'?q=RUN_HASH&f=FILE_NAME'}</strong>
                </Typography>
            </Paper>;
        } else {
            switch(this.file_name){
                case fileNames.ECComparedAllFile:
                    help = <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            Coupling Scores Compared Files
                        </Typography>
                        <ReactMarkdown className={classes.markdown} source={this.state.ECComparedAllFile} />
                    </Paper>;
                    break;
                case fileNames.monomerContactsFile:
                    help = <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            Monomer contacts file
                        </Typography>
                        <ReactMarkdown className={classes.markdown} source={this.state.monomerContactsFile} />
                    </Paper>;
                    break;
                case fileNames.alignmentStatisticsFile:
                    help = <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            Alignment statistics
                        </Typography>
                        <ReactMarkdown className={classes.markdown} source={this.state.alignmentStatisticsFile} />
                    </Paper>;
                    break;
                default:
                // Do nothing
            }

            switch(this.state.request){
                case requestStates.LOADING:
                    display= <Paper className={classes.paper} square elevation={2} >
                        <div className={classes.center}>
                            <CircularProgress/>
                        </div>
                        <Typography variant="body1" align="center">
                            Fetching information
                        </Typography>
                    </Paper>;
                    break;
                case requestStates.LOADED:
                    display = <Paper className={classes.paper}>
                        {this.table}
                    </Paper>;
                    break;
                case requestStates.ERROR:
                    help = <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            There was a problem...
                        </Typography>
                        <Typography variant={"body1"}>
                            {'There was a problem fetching your requested file. This might be a server issue ' +
                            'which can be solved by refreshing this page. If the problem persists, drop us an email.'}
                        </Typography>
                    </Paper>;
                    break;
                default:
                    break;
            }
        }

        return (<div className={classes.content}>
            {this.runHash && !this.start && <Typography variant="h6" component={"div"}>
                <Link to={"/jobs/" + this.runHash.substr(0, 32)} className={classes.link}>
                    <ArrowBack className={classes.backIcon}/>
                    {"Go back to job"}
                </Link>
            </Typography>}
            {help && <br />}
            {help}
            <br/>
            {display}
            <Button className={classes.button} variant="contained" color="secondary" onClick={this.download}>
                Download file
                <Download className={classes.rightIcon} />
            </Button>
        </div>)
    }
}

TablePage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(TablePage);