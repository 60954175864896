// From https://github.com/facebook/create-react-app/issues/1277#issuecomment-345516463

// MyWorker.js

// @args: You can pass your worker parameters on initialisation
export default function MyWorker(args) {
    let onmessage = e => { // eslint-disable-line no-unused-vars
        // Write your code here...
        let { endpoint, method, type } = e.data;

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: method
        })
            .then(res => {
                if(type === "json"){
                    return res.json();
                } else if(type === "text"){
                    return res.text();
                } else {
                    return {}
                }
            })
            .then(postMessage)
            .catch((error) => postMessage(error));
    };
}