import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Retriever from '../../utilities/Retriever';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Endpoints
import {files as fileNames} from '../../brokers/runs/download/DownloadFile';
import {queryType} from "../../brokers/precomputed/PrecomputedDownload";

const styles = theme => ({
    paper: {
        padding: 0,
        minHeight: 300,
        textAlign: "center"
    },
    loading: {
        minHeight: 300,
        backgroundColor: "#fafafa"
    },
    spinner: {
        marginTop: 125
    },
    errorIcon: {
        lineHeight: "300px",
        fontSize: "4em"
    },
    justify: {
        textAlign: "justify"
    }
});

const requestStatus = {
    loading: 1,
    error: 2,
    downloaded: 3
};

class MSAViewer extends React.Component {

    state = {
        status: requestStatus.loading,
        filesNeeded: [fileNames.alignmentFile],
        filesDownloaded: [],
    };

    componentDidMount(){
        this.getData();
    }

    componentDidUpdate(){
        if(this.state.status === requestStatus.downloaded){
            const seqs = window.msa.io.fasta.parse(this.state.filesDownloaded[0].data);

            let opts = {
                el: this.refs.MSAViewer,
                seqs: seqs,
                vis: {
                    labelId: false
                },
                conf: {
                    hasRef: true
                },
            };
            let m = window.msa(opts);

            m.render();
        }
    }

    getData = () => {
        this.state.filesNeeded.forEach(file => {
            let retriever = new Retriever(this.props.run);

            retriever
                .fetch({
                    type: queryType.alias,
                    query: file
                })
                .then(data => {
                    this.setState({
                        filesDownloaded: [...this.state.filesDownloaded, {
                            file: file,
                            data: data
                        }],
                    }, this.buildComponent)
                })
                .catch(e => {
                    console.error(e);

                    this.setState({
                        status: requestStatus.error
                    });
                });
        })
    };

    buildComponent = () => {
        let done = this.state.filesNeeded.length - this.state.filesDownloaded.length === 0;
        if(done){
            this.setState({
                status: requestStatus.downloaded
            })
        }
    };

    render() {
        const { classes } = this.props;
        const { status } = this.state;

        let display =  <Paper square elevation={0} className={classes.paper}>Loading...</Paper>;

        switch(status){
            case requestStatus.loading:
                let numerOfFiles = this.state.filesNeeded.length;
                let currentNumberOfFiles = numerOfFiles - this.state.filesDownloaded.length;

                display =  <Paper square elevation={0} className={classes.paper}>
                    <div className={classes.loading}>
                        <CircularProgress className={classes.spinner}/>
                    </div>
                    <Typography variant={"body1"}>
                        {currentNumberOfFiles} files of {numerOfFiles} left.
                    </Typography>
                </Paper>;
                break;
            case requestStatus.error:
                display =  <Paper square elevation={0} className={classes.paper}>
                    <div className={classes.loading}>
                        <span role={"img"} aria-label={"Not found"} className={classes.errorIcon}>🧐</span>
                    </div>
                    <Typography variant={"body1"} className={classes.justify}>
                        {`There was an error generating this component.
                                This usually means, the files necessary to render the component can not be downloaded.
                                If the run you are exploring has not terminated yet, check back later!
                                If this problem persists, drop us an email.
                                `}
                    </Typography>
                </Paper>;
                break;
            case requestStatus.downloaded:
            default:
                display = <Paper square elevation={0} className={classes.paper}> <div ref={"MSAViewer"}/> </Paper>;
        }

        return display;
    }
}

MSAViewer.propTypes = {
    classes: PropTypes.object.isRequired,
    run: PropTypes.object.isRequired,
};

export default withStyles(styles)(MSAViewer);