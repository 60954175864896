import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getCouplingScoresData, PredictedContactMap as ContactMapViz } from 'chell-viz';
import Retriever from '../../utilities/Retriever';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Endpoints
import {files as fileNames} from '../../brokers/runs/download/DownloadFile';
import {queryType} from "../../brokers/precomputed/PrecomputedDownload";

const styles = theme => ({
    paper: {
        padding: 0,
        minHeight: 400,
        textAlign: "center"
    },
    loading: {
        minHeight: 400,
        backgroundColor: "#fafafa"
    },
    spinner: {
        marginTop: 150
    },
    errorIcon: {
        lineHeight: "300px",
        fontSize: "4em"
    },
    justify: {
        textAlign: "justify"
    }
});

const requestStatus = {
    loading: 1,
    error: 2,
    downloaded: 3
};

class ContactMap extends React.Component {



    constructor(props){
        super(props);
        this.props = props;

        this.file = (props.filename !== undefined ? props.filename : props.run.filename) || fileNames.ecFile;

        this.state = {
            status: requestStatus.loading,
            filesNeeded: [this.file],
            filesDownloaded: [],
        };
    }

    componentDidMount(){
        this.getData();
    }

    getData = () => {
        this.state.filesNeeded.forEach(file => {
            let retriever = new Retriever(this.props.run);

            retriever
                .fetch({
                    type: queryType.alias,
                    query: file
                })
                .then(data => {
                    this.setState({
                        filesDownloaded: [...this.state.filesDownloaded, {
                            file: file,
                            data: data
                        }],
                    }, this.buildComponent)
                })
                .catch(e => {
                    console.error(e);

                    this.setState({
                        status: requestStatus.error
                    });
                });
        })
    };

    buildComponent = () => {
        let done = this.state.filesNeeded.length - this.state.filesDownloaded.length === 0;
        if(done){
            //build component
            let files = this.state.filesDownloaded;

            files = files.map(f => {
                return {
                    file: f.file,
                    data: getCouplingScoresData(f.data)
                };

            });

            this.setState({
                filesDownloaded: files,
                status: requestStatus.downloaded
            })
        }
    };

    render() {
        const { classes } = this.props;
        const { status } = this.state;

        let display =  <Paper square elevation={0} className={classes.paper}>Loading...</Paper>;

        switch(status){
            case requestStatus.error:
                display =  <Paper square elevation={0} className={classes.paper}>
                    <div className={classes.loading}>
                        <span role={"img"} aria-label={"Not found"} className={classes.errorIcon}>🧐</span>
                    </div>
                    <Typography variant={"body1"} className={classes.justify}>
                        {`There was an error generating this component.
                                This usually means, the files necessary to render the component can not be downloaded.
                                If the run you are exploring has not terminated yet, check back later!
                                If this problem persists, drop us an email.
                                `}
                    </Typography>
                </Paper>;
                break;
            case requestStatus.downloaded:
                let couplingScores = this.state.filesDownloaded.find(f => f.file === this.file).data;

                display = <Paper square elevation={0} className={classes.paper}>
                    <ContactMapViz
                        data={{
                            couplingScores: couplingScores,
                        }}
                        height={this.props.height || "500px"}
                        width={this.props.height || "500px"}
                    />
                </Paper>;
                break;
            case requestStatus.loading:
            default:
                let numerOfFiles = this.state.filesNeeded.length;
                let currentNumberOfFiles = numerOfFiles - this.state.filesDownloaded.length;

                display =  <Paper square elevation={0} className={classes.paper}>
                    <div className={classes.loading}>
                        <CircularProgress className={classes.spinner}/>
                    </div>
                    <Typography variant={"body1"}>
                        {currentNumberOfFiles} files of {numerOfFiles} left.
                    </Typography>
                </Paper>;
                break;
        }

        return display;

    }
}

ContactMap.propTypes = {
    classes: PropTypes.object.isRequired,
    run: PropTypes.object.isRequired,
    filename: PropTypes.string,
    height: PropTypes.string,
};

export default withStyles(styles)(ContactMap);