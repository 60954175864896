import {proteinStatus as ps} from "./JobParameters";

export class ComplexProtein{
    constructor(){
        // sequenceInput component
        this.proteinStatus = ps.NULL;
        this.protein = undefined;

        // RegionSelection component
        this.regionStart = undefined;
        this.regionEnd = undefined;

        // startingPositionElement component
        this.startingPosition = 1;

        // jobOptions component
        this.distanceCalculationFunction = "bitscore";
        this.distanceCalculationFunctionBitscoreRange = 0.3;
        this.distanceCalculationFunctionEValRange = 5;
        this.nOfIterations = 5;
    }
}

const initial = {
    // proteins (limit it for now)
    proteins: [new ComplexProtein(), new ComplexProtein()],
    // jobOptions component
    theta: 0.8,
    saveModel: "no",
    columnThreshold: 0.5,
    fragmentThreshold: 0.7,
    database: "uniref100",
    couplingsProtocol: "standard",
    plmcIterations: 100,
    compareSearchBy: "jackhmmer",
    compareBitscore: 0.3,
    nOfStructures: 10,
    nOfHits: 25,
    distanceCutOff: 5,
    atomFilter: "closest_atom",
    foldHighest: 1.5,
    foldLowest: 0.3,
    foldIncrease: 0.1,
    concatenateProtocol: "best_hit",
    genomeDistanceThreshold: 10000,
    useBestReciprocal: "yes",

    // SubmitJob component
    name: "",
    email: "",
};

function ComplexJobParameters(state = initial, action) {
    let currentProtein;
    let tempState;

    switch (action.type) {
        case 'SET_COMPLEX_PROTEIN_STATUS':
            tempState = {...state};
            currentProtein = tempState.proteins[action.payload.forProtein];
            currentProtein.proteinStatus = action.payload.proteinStatus;

            tempState.proteins[action.payload.forProtein] = currentProtein;

            return tempState;
        case 'SET_COMPLEX_PROTEIN_OBJECT':
            return {
                ...state,
                protein: action.payload.protein
            };
        case 'SET_COMPLEX_JOB_PARAMETERS':
            if(action.payload.forProtein !== undefined){
                tempState = {...state};
                currentProtein = tempState.proteins[action.payload.forProtein];
                currentProtein = {
                    ...currentProtein,
                    ...action.payload
                };
                tempState.proteins[action.payload.forProtein] = currentProtein;

                return tempState;
            } else {
                return {
                    ...state,
                    ...action.payload
                };
            }
        case 'RESET_JOB_SUBMISSION':
            return initial;
        default:
            return state;
    }
}

export default ComplexJobParameters;