import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import URI from 'urijs';
import {states as requestStates} from "../../utilities/Requests";
import PrecomputedResultsJSON, {queryType} from '../../brokers/precomputed/PrecomputedResultsJSON';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from "react-router-dom";
import ArrowBack from '@material-ui/icons/ArrowBack';
import RunResultsFetcher, {RESULTS_KEYS} from "../../brokers/runs/results/RunResults";
import {files as fileNames} from "../../brokers/runs/download/DownloadFile";
import Grid from "@material-ui/core/Grid/Grid";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import TextField from "@material-ui/core/TextField/TextField";
import {proteinColorSchemes} from "../../utilities/Graphics";

let d3 = require('d3');

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
        paddingBottom: 50
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1500,
        },
    },
    center: {
        textAlign: "center"
    },
    markdown: {
        lineHeight: "1.4em"
    },
    fadingComponents: {
        transition: '.5s'
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        textAlign: "justify",
        fontFamily: theme.typography.fontFamily
    }),
    link: {
        color: "inherit",
        textDecoration: "inherit"
    },
    backIcon: {
        position: "relative",
        top: "0.15em"
    }
});

class RibbonViewerPage extends React.Component {

    state = {
        request: requestStates.LOADING,
        missingQuery: false,
        probabilityFilter: 0,
        ECFilter: 0
    };

    constructor(props){
        super(props);

        let queries = new URI().search(true);

        // Needed for all jobs
        this.runHash = queries.q;

        // Only relevant if precomputed
        this.batch = queries.b;
        this.start = queries.start;
        this.end = queries.end;
    }

    componentDidMount() {
        if(this.batch !== undefined && this.start !== undefined && this.end !== undefined && this.runHash !== undefined){
            PrecomputedResultsJSON.run(this.runHash, this.batch, this.start, this.end, {
                type: queryType.alias,
                query: fileNames.ecFile
            })
                .then(ecs => {
                    this.ecs = ecs;
                    this.data = this.ecHierarchy(this.ecs);

                    this.setState({
                        request: requestStates.LOADED
                    }, this.createGraph);
                })
                .catch(e => {
                    console.error(e);

                    this.setState({
                        request: requestStates.ERROR
                    })
                });
        } else if(this.runHash !== undefined) {
            RunResultsFetcher.run(this.runHash, RESULTS_KEYS.CSVToJSON, fileNames.ecFile)
                .then(ecs => {
                    this.ecs = ecs;
                    this.data = this.ecHierarchy(this.ecs);

                    this.setState({
                        request: requestStates.LOADED
                    }, this.createGraph);
                })
                .catch(error => {
                    console.error(error);

                    this.setState({
                        request: requestStates.ERROR
                    })
                });
        } else {
            // Either f or q missing in URL
            this.setState({
                missingQuery: true
            })
        }
    }

    ecHierarchy(rawECs, filterCN, probabilityFilter) {
        filterCN = filterCN || 0;
        probabilityFilter = probabilityFilter || 0;

        const nameByIndex = new Map();

        const sparseMatrix = [];
        const sparseProbabilities = [];

        let size = 0;

        rawECs.forEach(function(d) {
            if(sparseMatrix[d.i-1] === undefined){
                sparseMatrix[d.i-1] = [];
                sparseProbabilities[d.i-1] = [];
            }

            if(d.cn > filterCN && (d.probability === null || d.probability > probabilityFilter))
                sparseMatrix[d.i-1][d.j-1] = d.cn;
            sparseProbabilities[d.i-1][d.j-1] = d.probability === null ? d.cn : d.probability;

            // Assign letter to position
            nameByIndex.set(d.i-1, {
                position: d.i,
                letter: d.A_i,
                composite: d.i + " " + d.A_i
            });
            nameByIndex.set(d.j-1, {
                position: d.j,
                letter: d.A_j,
                composite: d.j + " " + d.A_j
            });

            size = Math.max(size, d.i, d.j);
        });

        let matrix = [];
        let maxMatrix = [];
        let probabilities = [];

        for(let i = 0; i < size;i++){
            matrix[i] = Array.from({length: size}).fill(0);
            maxMatrix[i] = Array.from({length: size}).fill(0);
            probabilities[i] = Array.from({length: size}).fill(0);
        }

        sparseMatrix.forEach((row, rowIndex) => {
            let maxOfRow = 0;
            row.forEach((column, columnIndex) => {
                matrix[rowIndex][columnIndex] = column;

                probabilities[rowIndex][columnIndex] = sparseProbabilities[rowIndex][columnIndex];

                maxOfRow = Math.max(maxOfRow, column);
            });

            let maxIndex = row.indexOf(maxOfRow);

            maxMatrix[rowIndex][maxIndex] = maxOfRow;
        });

        return {
            matrix,
            maxMatrix,
            sparseMatrix,
            probabilities,
            nameByIndex
        }
    }

    minMax(val, max, min) {
        return (val - min) / (max - min);
    }

    createGraph = () => {
        this.width = 1000;

        this.height = this.width;

        this.innerRadius = Math.min(this.width, this.height) * 0.5 - 124;

        this.svg = d3.select(this.node).append('svg').attr('width', this.width).attr('height', this.height)
            .attr("viewBox", [-this.width / 2, -this.height / 2, this.width, this.height])
            .attr("font-size", 10)
            .attr("font-family", "sans-serif")
            .style("width", "100%")
            .style("height", "auto");

        this.chords =  d3.chord().padAngle(100)(this.data.matrix);

        const group = this.svg.append("g")
            .selectAll("g")
            .data(this.chords.groups)
            .enter()
            .append("g");

        group.append("text")
            .each(d => { d.angle = (d.startAngle + d.endAngle) / 2; })
            .attr("dy", ".35em")
            .attr("transform", d => `
        rotate(${(d.angle * 180 / Math.PI - 90)})
        translate(${this.innerRadius})
        ${d.angle > Math.PI ? "rotate(180)" : ""}
      `)
            .attr("text-anchor", d => d.angle > Math.PI ? "end" : null)
            .text(d => this.data.nameByIndex.get(d.index+1) && this.data.nameByIndex.get(d.index+1).composite)
            .on("mouseover", handleMouseOver)
            .on("mouseout", handleMouseOut)
        ;

        function handleMouseOver(d) {
            try {
                d3.selectAll("path").filter(data => data && data.source.index !== d.index && data.source.subindex !== d.index).attr("opacity", 0);
                // d3.selectAll("path").filter(data => data && (data.source.index === d.index || data.source.subindex === d.index))
                //     .attr("opacity", 1)
                //     .attr("stroke-width", d => d && d.source.value * 10);
            } catch (e) {
                console.warn(e);
            }
        }

        let {probabilityFilter} = this.state;

        let self = this;

        function handleMouseOut() {
            try {
                d3.selectAll("path")
                    .attr("stroke-width", d => d && d.source.value)
                    .attr("opacity", d => d && self.minMax(self.data.probabilities[d.source.index][d.source.subindex], 1 , probabilityFilter));
            } catch (e) {
                console.warn(e);
            }

        }

        this.drawCurves();
    };

    drawCurves = () => {
        let {ECFilter, probabilityFilter} = this.state;

        let ribbon = d3.ribbon().radius(this.innerRadius);

        this.svg.append("g")
            .attr("fill-opacity", 1)
            .selectAll("path")
            .data(this.chords)
            .enter()
            .filter(d => d.source.value > ECFilter && this.data.probabilities[d.source.index][d.source.subindex] > probabilityFilter )
            .append("path")
            .attr("stroke", d => proteinColorSchemes['mview']['primary'][this.data.nameByIndex.get(d.source.index).letter])
            .attr("class", d => d.source.index)
            .attr("fill", d => proteinColorSchemes['mview']['primary'][this.data.nameByIndex.get(d.source.index).letter])
            .attr("stroke-width", d => d.source.value)
            .attr("opacity", d => this.minMax(this.data.probabilities[d.source.index][d.source.subindex], 1 , probabilityFilter))
            .attr("d", ribbon);
    };

    change = key => event => {
        let value = event.target.value.replace(/[\D]+/g, '');

        this.setState({
            [key]: value
        });

        // TODO: redraw
    };

    render(){
        const { classes } = this.props;

        let display = <div/>;
        let help = null;

        if(this.state.missingQuery) {
            help = <Paper className={classes.paper}>
                <Typography variant={"h6"}>
                    Your URL is invalid!
                </Typography>
                <Typography variant={"body1"}>
                    {"This page will display a ribbon-like view of your run's EC file "}
                </Typography>
            </Paper>;
        } else {
            switch(this.state.request){
                case requestStates.LOADING:
                    display= <Paper className={classes.paper} square elevation={2} >
                        <div className={classes.center}>
                            <CircularProgress/>
                        </div>
                        <Typography variant="body1" align="center">
                            Fetching information
                        </Typography>
                    </Paper>;
                    break;
                case requestStates.LOADED:
                    display = <Paper className={classes.paper}>
                        <Grid spacing={16} container>
                            <Grid item xs={6}>
                                <FormLabel>
                                    {"Minimum probability: "}
                                </FormLabel>
                                <TextField
                                    value={this.state.probabilityFilter}
                                    className={classes.input}
                                    onChange={this.change('probabilityFilter')}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel>
                                    {"Minimum EC score "}
                                </FormLabel>
                                <TextField
                                    value={this.state.ECFilter}
                                    className={classes.input}
                                    onChange={this.change('ECFilter')}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div ref={node => this.node = node}></div>
                            </Grid>
                            <Grid item xs={12}>
                                Legend:
                                <div style={{display: "flex"}}>
                                {"ACDEFGHIKLMNPQRSTVWY".split('').map(letter => {
                                    return <div key={letter} style={{
                                        background: proteinColorSchemes['mview']['primary'][letter],
                                        color: proteinColorSchemes['mview']['contrast'][letter],
                                        width: "1em",
                                        fontSize: "1.3em",
                                        textAlign: "center",
                                        margin: "0 .1em"
                                    }}>
                                            {letter}
                                    </div>;
                                })}
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>;
                    break;
                case requestStates.ERROR:
                    help = <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            There was a problem...
                        </Typography>
                        <Typography variant={"body1"}>
                            {'There was a problem fetching your requested file. This might be a server issue ' +
                            'which can be solved by refreshing this page. If the problem persists, drop us an email.'}
                        </Typography>
                    </Paper>;
                    break;
                default:
                    break;
            }
        }

        return (<div className={classes.content}>
            {this.runHash && !this.start && <Typography variant="h6" component={"div"}>
                <Link to={"/jobs/" + this.runHash.substr(0, 32)} className={classes.link}>
                    <ArrowBack className={classes.backIcon}/>
                    {"Go back to job"}
                </Link>
            </Typography>}
            {help && <br />}
            {help}
            <br/>
            {display}
        </div>)
    }
}

RibbonViewerPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(RibbonViewerPage);