import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Motion, spring} from 'react-motion';

// Store
import storeComponentWrapper from '../../stores/jobDispatcher';
import { proteinStatus } from "../../stores/job/JobParameters";
import { jobValidationStatus } from "../../stores/job/JobValidationStatus";

// Components
import SequenceInput from '../../components/jobs/create/SequenceInput';
import SequenceStatus from '../../components/jobs/create/SequenceStatus';
import StartingPositionElement from '../../components/jobs/create/StartingPositionElement';
import ComplexIndividualJobOptions from '../../components/jobs/create/ComplexIndividualJobOptions';
import ComplexSharedJobOptions from '../../components/jobs/create/ComplexSharedJobOptions';
import AvatarMessageElement, {type, avatar} from '../../components/paperElements/AvatarMessage';
import ProceedToSummaryPaper from '../../components/jobs/create/SubmitJob';
import UserLoginStatus from '../../components/jobs/create/UserLoginStatus';
import RegionSelection from '../../components/jobs/create/RegionSelection';
import Typography from "@material-ui/core/Typography/Typography";
import {Link} from "react-router-dom";

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    fadingComponents: {
        transition: '.5s'
    },
});

class ComplexJobSubmissionPage extends React.Component {
    render(){
        const { className, classes } = this.props;
        return (<div className={classNames(classes.content, className)}>
            <Grid spacing={16} container>
                <Grid item xl={12} md={12} xs={12} style={{textAlign: "center"}}>
                    <Typography component={"div"} variant="h5">
                        Complex submission
                    </Typography>
                    <Typography component={"div"} variant="body1">
                        {"Do you want to submit a monomer? "}
                        <Link to="/job">
                            Click here.
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                    <Grid spacing={16} container>
                        <Grid item xs={12}>
                            <SequenceInput complexSubmissionIdentifier={0}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                    <SequenceStatus complexSubmissionIdentifier={0}/>
                                </div>
                                }
                            </Motion>
                        </Grid>
                        { proteinStatus.needsIndexing(this.props.complexJobParameters.proteins[0].proteinStatus) &&
                        (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"Please, use UniProt identifiers if possible and make sure the sequence passed is continuous."}
                                        />
                                    </div>
                                    }
                                </Motion>

                            </Grid>
                        )}
                        { proteinStatus.needsIndexing(this.props.complexJobParameters.proteins[0].proteinStatus) &&
                        (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"For correct folding, the starting position of the sequence is important. " +
                                            "The default starting position for a sequence is set to 1."}
                                            type={type.warning}
                                            avatar={avatar.warning}
                                            expand={true}
                                            expandedContent={<StartingPositionElement complexSubmissionIdentifier={0}/>}
                                            expandedHeading={"Expand to change the starting position of the sequence"}
                                            expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                        />
                                    </div>
                                    }
                                </Motion>

                            </Grid>
                        )}
                        { proteinStatus.isValid(this.props.complexJobParameters.proteins[0].proteinStatus) && !proteinStatus.needsIndexing(this.props.complexJobParameters.proteins[0].proteinStatus) &&
                        (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"If you don't specify a particular region, your job will be run on the entire protein"}
                                            type={type.neutral}
                                            avatar={avatar.info}
                                            expand={true}
                                            expandedContent={<RegionSelection complexSubmissionIdentifier={0}/>}
                                            expandedHeading={"Expand to see sequence and select the region of interest"}
                                            expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                        />
                                    </div>
                                    }
                                </Motion>

                            </Grid>
                        )}
                        { proteinStatus.isValid(this.props.complexJobParameters.proteins[0].proteinStatus) && (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"We defined a set of default parameters for your first sequence, which usually give good results"}
                                            avatar={avatar.options}
                                            expand={true}
                                            expandedContent={<ComplexIndividualJobOptions complexJobProteinNumber={0} />}
                                            expandedHeading={"Expand to change default parameters"}
                                            expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                        />
                                    </div>
                                    }
                                </Motion>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                    <Grid spacing={16} container>
                        <Grid item xs={12}>
                            <SequenceInput complexSubmissionIdentifier={1}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                    <SequenceStatus complexSubmissionIdentifier={1}/>
                                </div>
                                }
                            </Motion>
                        </Grid>
                        { proteinStatus.needsIndexing(this.props.complexJobParameters.proteins[1].proteinStatus) &&
                        (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"Please, use UniProt identifiers if possible and make sure the sequence passed is continuous."}
                                        />
                                    </div>
                                    }
                                </Motion>

                            </Grid>
                        )}
                        { proteinStatus.needsIndexing(this.props.complexJobParameters.proteins[1].proteinStatus) &&
                        (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"For correct folding, the starting position of the sequence is important. " +
                                            "The default starting position for a sequence is set to 1."}
                                            type={type.warning}
                                            avatar={avatar.warning}
                                            expand={true}
                                            expandedContent={<StartingPositionElement complexSubmissionIdentifier={1}/>}
                                            expandedHeading={"Expand to change the starting position of the sequence"}
                                            expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                        />
                                    </div>
                                    }
                                </Motion>

                            </Grid>
                        )}
                        { proteinStatus.isValid(this.props.complexJobParameters.proteins[1].proteinStatus) && !proteinStatus.needsIndexing(this.props.complexJobParameters.proteins[1].proteinStatus) &&
                        (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"If you don't specify a particular region, your job will be run on the entire protein"}
                                            type={type.neutral}
                                            avatar={avatar.info}
                                            expand={true}
                                            expandedContent={<RegionSelection complexSubmissionIdentifier={1}/>}
                                            expandedHeading={"Expand to see sequence and select the region of interest"}
                                            expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                        />
                                    </div>
                                    }
                                </Motion>

                            </Grid>
                        )}
                        { proteinStatus.isValid(this.props.complexJobParameters.proteins[1].proteinStatus) && (
                            <Grid item xs={12}>
                                <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                                    {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                        <AvatarMessageElement
                                            message={"We defined a set of default parameters for your second sequence, which usually give good results"}
                                            avatar={avatar.options}
                                            expand={true}
                                            expandedContent={<ComplexIndividualJobOptions complexJobProteinNumber={1} />}
                                            expandedHeading={"Expand to change default parameters"}
                                            expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                        />
                                    </div>
                                    }
                                </Motion>
                            </Grid>
                        )}

                    </Grid>
                </Grid>
                { proteinStatus.isValid(this.props.complexJobParameters.proteins[0].proteinStatus) && proteinStatus.isValid(this.props.complexJobParameters.proteins[1].proteinStatus) && (
                    <Grid item xs={12}>
                        <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                            {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                <AvatarMessageElement
                                    message={"We have defined a set of default parameters for your job, which usually give good results"}
                                    avatar={avatar.options}
                                    expand={true}
                                    expandedContent={<ComplexSharedJobOptions />}
                                    expandedHeading={"Expand to change the advanced options"}
                                    expansionDisabled={!jobValidationStatus.isEditable(this.props.jobValidationStatus.jobValidity)}
                                />
                            </div>
                            }
                        </Motion>
                    </Grid>
                )}
                { proteinStatus.isValid(this.props.complexJobParameters.proteins[0].proteinStatus) && proteinStatus.isValid(this.props.complexJobParameters.proteins[1].proteinStatus) && (
                    <Grid item xs={12}>
                        <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                            {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                <UserLoginStatus />
                            </div>
                            }
                        </Motion>
                    </Grid>
                )}
                { proteinStatus.isValid(this.props.complexJobParameters.proteins[0].proteinStatus) && proteinStatus.isValid(this.props.complexJobParameters.proteins[1].proteinStatus) && (
                    <Grid item xs={12}>
                        <Motion defaultStyle={{opacity: 0}} style={{opacity: spring(20)}}>
                            {value => <div className={classes.fadingComponents} style={{opacity: value.opacity}}>
                                <ProceedToSummaryPaper complexJob={true} />
                            </div>
                            }
                        </Motion>
                    </Grid>
                )}
            </Grid>
        </div>);
    }
}

ComplexJobSubmissionPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    jobParameters: PropTypes.object,
    jobSearch: PropTypes.object,
    jobValidationStatus: PropTypes.object
};

export default storeComponentWrapper(withStyles(styles)(ComplexJobSubmissionPage));