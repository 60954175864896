import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../../global";
import jQuery from "jquery";

class RunData {

    static run(hash){

        const endpoint = API_ENDPOINTS.runData;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default RunData;