import React from 'react';
import Typography from '@material-ui/core/Typography';
import SequenceHighlighter from '../../paperElements/SequenceHighlighter';
import storeComponentWrapper from '../../../stores/jobDispatcher';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import PfamHits from './PfamHits';
import PropTypes from "prop-types";


const styles = theme => ({
    input: {
        width: "3.4rem"
    },
});


class RegionSelection extends React.Component {

    constructor(props){
        super();

        this.props = props;

        if(this.props.complexSubmissionIdentifier !== undefined){
            let { protein, regionStart, regionEnd } = this.props.complexJobParameters.proteins[this.props.complexSubmissionIdentifier];

            this.state = {
                regionStart: regionStart || 1,
                regionEnd: regionEnd || protein.sequence.length,
                set: "regionStart",
                protein: protein
            };
        } else {
            let { protein, regionStart, regionEnd } = this.props.jobParameters;

            this.state = {
                regionStart: regionStart || 1,
                regionEnd: regionEnd || protein.sequence.length,
                set: "regionStart",
                protein: protein
            };
        }

        this.jobParametersAction = this.props.complexSubmissionIdentifier !== undefined ? "SET_COMPLEX_JOB_PARAMETERS" : "SET_JOB_PARAMETERS";
    }

    change = key => event => {
        let value = event.target.value.replace(/[\D]+/g, '');

        this.setState({
            [key]: value
        }, this.updateRegionStatus);
    };

    click = (position) => {
        this.setState({
            [this.state.set]: position,
            set: this.state.set === "regionStart" ? "regionEnd" : "regionStart"
        }, this.updateRegionStatus);
    };

    select = ([start, end]) => {
        this.setState({
            regionStart: start,
            regionEnd: end
        }, this.updateRegionStatus);
    };

    updateRegionStatus() {
        this.props.action({
            type: this.jobParametersAction,
            payload: {
                regionStart: this.state.regionStart,
                regionEnd: this.state.regionEnd,
                forProtein: this.props.complexSubmissionIdentifier
            }
        });
    };

    componentWillUnmount() {
        this.props.action({
            type: this.jobParametersAction,
            payload: {
                regionStart: undefined,
                regionEnd: undefined,
                forProtein: this.props.complexSubmissionIdentifier
            }
        });
    };

    render() {
        const { protein } = this.state;
        const { classes } = this.props;

        let startChar = protein.sequence.charAt(this.state.regionStart-1);
        let endChar = protein.sequence.charAt(this.state.regionEnd-1);

        return (
            <Grid spacing={16} container>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom align="justify">
                        {`You can define the start and end position of your region of interest. We display the entire
                        sequence of your protein below, and highlight in green the region the job will be computed upon.
                        You can change the numerical values in the two following input boxes or sequentially select in
                        the sequence of characters the first character and then the last character of your region.`}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormLabel>
                        {"Starting position of region: "}
                    </FormLabel>
                    <TextField
                        value={this.state.regionStart}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{startChar}</InputAdornment>,
                        }}
                        className={classes.input}
                        onChange={this.change('regionStart')}
                        error={!(this.state.regionStart > 0) || this.state.regionStart > this.state.regionEnd}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel>
                        {"End position of region: "}
                    </FormLabel>
                    <TextField
                        value={this.state.regionEnd}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{endChar}</InputAdornment>,
                        }}
                        className={classes.input}
                        onChange={this.change('regionEnd')}
                        error={!(this.state.regionEnd > 0) || this.state.regionStart > this.state.regionEnd || this.state.regionEnd > protein.sequence.length}

                    />
                </Grid>
                <Grid item xs={12}>
                    <PfamHits
                        uniprotAccession={protein.getUniprotAccession()}
                        regionSelected={this.select}
                        proteinLength={this.state.protein.sequence.length}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SequenceHighlighter
                        region={[this.state.regionStart, this.state.regionEnd]}
                        string={protein.sequence}
                        AAClicked={this.click}
                    />
                </Grid>
            </Grid>
        );
    }
}

RegionSelection.propTypes = {
    classes: PropTypes.object.isRequired,
    jobParameters: PropTypes.object.isRequired,
    complexSubmissionIdentifier: PropTypes.number
};

export default withStyles(styles)(storeComponentWrapper(RegionSelection));