import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { readTextFile } from "../../utilities/UTFExtractor";
import Right from '@material-ui/icons/KeyboardArrowRight';
import {Link} from 'react-router-dom';
import greeting from './markdown/greeting.md';
import news from './markdown/news.md';
import important from './markdown/important.md';
import acknowledgements from './markdown/acknowledgements.md';
import {software, webservers} from "../../static/markslab/resources/sections";
import Card from "@material-ui/core/Card/Card";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import version from '../../version';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        fontFamily: theme.typography.fontFamily
    }),
    link: {
        textDecoration: "none"
    },
    center: {
        textAlign: "center"
    },
    important: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    markdown: {
        lineHeight: "1.4em"
    },
    actionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: 20,
        paddingRight: 10,
        color: "inherit"
    },
    secondaryActionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: 5,
        paddingRight: 10,
        color: "inherit"
    },
    a: {
        textDecoration: "none",
        color: "inherit",
    },
    typography: {
        color: theme.palette.primary.contrastText,
    },
    actionPaper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    secondaryActionPaper: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    readmePaper: {
        backgroundColor: "orange",
        color: "white",
        textAlign: "right"
    },
    card: {
        position: "relative"
    },
    new: {
        position: "absolute",
        top: 5,
        right: 5,
        background: theme.palette.primary.main,
        borderRadius: "5%",
        padding: 3,
        textAlign: "center",
    },
    newText: {
        color: theme.palette.primary.contrastText,
    },
    cardContent: {
        height: 150,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: "inherit"
    },
});


class Home extends React.Component {
    state = {
        greetings: "",
        news: "",
        important: "",
        acknowledgements: ""
    };

    componentDidMount(){
        readTextFile(greeting)
            .then(text => this.setState({greetings: text}))
            .catch(e => console.error(e));

        readTextFile(news)
            .then(text => this.setState({news: text}))
            .catch(e => console.error(e));

        readTextFile(important)
            .then(text => this.setState({important: text}))
            .catch(e => console.error(e));

        readTextFile(acknowledgements)
            .then(text => this.setState({acknowledgements: text}))
            .catch(e => console.error(e));
    }

    render(){
        const { classes } = this.props;

        return (<div className={classes.content}>
            <Grid spacing={16} container>
                {this.state.important && <Grid xs={12} xl={12} md={12} item>
                    <Paper className={classNames(classes.paper, classes.important)}>
                        <ReactMarkdown className={classes.markdown} source={this.state.important} />
                    </Paper>
                </Grid>}
                {/*<Grid xs={12} xl={12} md={12} item>*/}
                {/*<Paper className={classNames(classes.paper, classes.center)}>*/}
                {/*<img alt="Evcouplings logo" height={100} src={evcouplings}/>*/}
                {/*</Paper>*/}
                {/*</Grid>*/}
                <Grid xs={12} xl={12} md={12} item>
                    <Paper className={classes.paper}>
                        <ReactMarkdown className={classes.markdown} source={this.state.greetings} />
                    </Paper>
                </Grid>
                <Grid xs={12} xl={12} md={12} item>
                    <Paper className={classes.actionPaper}>
                        <Link className={classes.a} to={"/choice"}>
                            <div className={classes.actionContainer}>
                                <Typography variant="h6" className={classes.typography}>
                                    SUBMIT JOB
                                </Typography>
                                <Right/>
                            </div>
                        </Link>
                    </Paper>
                    {/*<Paper className={classes.readmePaper}>*/}
                        {/*<Typography variant="body1" className={classes.typography}>*/}
                            {/*<strong>Note: </strong> Jobs submitted between 03/09-03/16 might have failed at the fold stage. Drop us an email to restart them. Sorry for the inconvenience.*/}
                        {/*</Typography>*/}
                    {/*</Paper>*/}
                </Grid>
                <Grid xs={12} xl={12} md={12} item>
                    <Paper className={classes.secondaryActionPaper}>
                        <a className={classes.a} href={"http://evcouplings2.hms.harvard.edu/"} target={"_blank"} >
                            <div className={classes.secondaryActionContainer}>
                                <Typography variant="h6" className={classes.typography}>
                                    Have a monomer submission? Try out the new server (in beta)!
                                </Typography>
                                <Right/>
                            </div>
                        </a>
                    </Paper>
                </Grid>

                {webservers.map((e,i) => {
                    let {title, pic, description, link, route, updated} = e;

                    let LinkElement = "a";

                    if(route !== undefined) LinkElement = Link;

                    return (
                        <Grid xs={12} md={3} xl={3} key={i} item>
                            <Card className={classes.card}>
                                {pic &&
                                <LinkElement to={route} href={link} className={classes.link}>
                                    <CardMedia
                                        className={classes.media}
                                        image={pic}
                                        title={title}
                                    />
                                </LinkElement>}
                                {updated && <div className={classes.new}>
                                    <Typography className={classes.newText} variant="body1">Updated!</Typography>
                                </div>}
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {title}
                                    </Typography>
                                    <Typography component="p">
                                        {description}
                                    </Typography>
                                </CardContent>

                                <LinkElement to={route} href={link} className={classes.link}>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Go
                                        </Button>
                                    </CardActions>
                                </LinkElement>
                            </Card>
                        </Grid>);
                })}
                <Grid xs={12} item>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Software and source code
                        </Typography>
                        <Typography variant="body1">
                            We provide access to our evolutionary couplings methods as open source software. For details about each tool, please refer to the individual repositories.
                        </Typography>
                    </Paper>
                </Grid>

                {software.map((e,i) => {
                    let {title, pic, description, link, updated} = e;

                    return (
                        <Grid xs={12} md={3} xl={3} key={i} item>
                            <Card className={classes.card}>
                                {pic &&
                                <a href={link} className={classes.link}>
                                    <CardMedia
                                        className={classes.media}
                                        image={pic}
                                        title={title}
                                    />
                                </a>
                                }
                                {updated && <div className={classes.new}>
                                    <Typography className={classes.newText} variant="body1">Updated!</Typography>
                                </div>}
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {title}
                                    </Typography>
                                    <Typography component="p">
                                        {description}
                                    </Typography>
                                </CardContent>
                                <a href={link} className={classes.link}>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Go
                                        </Button>
                                    </CardActions>
                                </a>

                            </Card>
                        </Grid>);
                })}
                <Grid xs={12} xl={12} md={12} item>
                    <Paper className={classes.paper}>
                        <ReactMarkdown className={classes.markdown} source={this.state.news} />
                    </Paper>
                </Grid>
                <Grid xs={12} xl={6} md={6} item>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Cite
                        </Typography>
                        <Typography variant="body1">
                            If you are using the EVcouplings server or python package, please cite:
                            <pre style={{whiteSpace: "pre-wrap", fontSize: 11}}>
                                Thomas A Hopf, Anna G Green, Benjamin Schubert, Sophia Mersmann, Charlotta P I Schärfe, John B Ingraham, Agnes Toth-Petroczy, Kelly Brock, Adam J Riesselman, Perry Palmedo, Chan Kang, Robert Sheridan, Eli J Draizen, Christian Dallago, Chris Sander, Debora S Marks, The EVcouplings Python framework for coevolutionary sequence analysis, Bioinformatics, Volume 35, Issue 9, 1 May 2019, Pages 1582–1584, <a href="https://doi.org/10.1093/bioinformatics/bty862" target="_blank" rel="noopener noreferrer" >https://doi.org/10.1093/bioinformatics/bty862</a>
                            </pre>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid xs={12} xl={6} md={6} item>
                    <Paper className={classes.paper}>
                        <ReactMarkdown className={classes.markdown} source={this.state.acknowledgements} />
                    </Paper>
                </Grid>

                <Grid xs={12} xl={12} md={12} item>
                    <Typography variant={"caption"}>
                        Released on {(new Date(version.release)).toLocaleDateString("it-IT")}, version {version.version + 'R' + version.releaseVersion}
                    </Typography>
                </Grid>
            </Grid>
        </div>);
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);