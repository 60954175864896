function RunSearch(state = {
    runSearchStatus: runSearchStatus.NO_RESULTS,
    runs: []

}, action) {
    switch (action.type) {
        case 'RUN_SEARCH_RESULTS':
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default RunSearch;

export const runSearchStatus = {
    NO_RESULTS: 0,
    REQUESTING: 1,
    RESULTS: 2,
    REQUEST_ERROR: 3
};

