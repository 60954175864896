import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import { capitalize } from "../../utilities/StringUtilities";
import URI from 'urijs';
import {withRouter} from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import {Link} from 'react-router-dom';
import {LocalRunStore} from "../../stores/studio/Studio";
import StudioDispatcher from "../../stores/studioDispatcher";

import {METADATA_KEYS} from "../../brokers/runs/info/RunMetadata";
import DownloadFile, {files as fileNames} from "../../brokers/runs/download/DownloadFile";
import PrecomputedStructuresRanking from "../../brokers/precomputed/PrecomputedStructuresRanking";
import {API_ENDPOINTS, ALIGNMENT_VIEWER_ENDPOINTS, EVZOOM_ENDPOINTS} from "../../brokers/global";

import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";

import Align from '@material-ui/icons/FormatAlignLeft';
import Archive from '@material-ui/icons/Archive';
import Table from '@material-ui/icons/TableChart';
import Figure from '@material-ui/icons/Gradient';


const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    noFlex: {
        display: "inherit"
    },
    infoIcon: {
        width: 17,
        color: "white",
        height: "inherit"
    },
    noColorInfoIcon: {
        width: 17,
        height: "inherit"
    },
    list: {
        width: "90%"
    },
    avatarInfo: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    avatarDownload: {

    },
    avatarExtra: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    good: {
        backgroundColor: green[300],
        color: "white"
    },
    medium: {
        backgroundColor: orange[300],
        color: "white"
    },
    bad: {
        backgroundColor: red[300],
        color: "white"
    },

});

class PrecomputedBatchInfo extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            files: props.batch,
            metadata: props.batch["outconfig"],
            PDBRanking: null
        };

        this.id = this.props.job.uniprotID ? this.props.job.uniprotID : this.props.job.caspID;
    }

    componentWillReceiveProps(newProps){
        this.props = newProps;

        this.id = newProps.job.uniprotID ? newProps.job.uniprotID : newProps.job.caspID;

        this.setState({
            files: newProps.batch,
            metadata: newProps.batch["outconfig"]
        }, this.fetchStructuresRanking);
    }

    fetchStructuresRanking = () => {
        PrecomputedStructuresRanking.run(this.id, this.props.job.start, this.props.job.end, this.props.batchKey)
            .then(results => {
                this.setState({
                    PDBRanking: results[0]
                })
            })
            .catch(e => console.error(e))
    };

    componentDidMount(){
        this.fetchStructuresRanking()
    }

    addToStudio = fileType => () => {
        this.props.studioAction({
            type: "ADD_LOCAL_RUN_STUDIO",
            payload: {
                run: new LocalRunStore(this.id, this.props.batchKey, this.props.job.start, this.props.job.end, fileType)
            }
        });

        let { history } = this.props;
        history.push("/studio");
    };

    render() {
        const {classes} = this.props;
        let qualityIndex = this.state.metadata['region'] ? (this.state.metadata[METADATA_KEYS.effectiveSequences] / (this.state.metadata['region'][1] - this.state.metadata['region'][0])) : -1;

        let qualityIndicator = undefined;

        if (qualityIndex > 5) {
            qualityIndicator = (
                <Paper square className={classnames(classes.paper, classes.good)}>
                    <Typography variant="body1" color="inherit">
                        This run has a very good ratio of effective sequences in alignment to protein length (n_eff/L={qualityIndex.toFixed(2)})
                        <Link to="/faq/results#quality" className={classes.link}>
                            <InfoIcon className={classes.infoIcon} />
                        </Link>
                    </Typography>
                </Paper>
            );
        } else if (qualityIndex > 1) {
            qualityIndicator = (
                <Paper square className={classnames(classes.paper, classes.medium)}>
                    <Typography variant="body1" color="inherit">
                        This run has a good ratio of effective sequences in alignment to protein length (n_eff/L={qualityIndex.toFixed(2)})
                        <Link to="/faq/results#quality" className={classes.link}>
                            <InfoIcon className={classes.infoIcon} />
                        </Link>
                    </Typography>
                </Paper>
            );
        } else if (qualityIndex > 0){
            qualityIndicator = (
                <Paper square className={classnames(classes.paper, classes.bad)}>
                    <Typography variant="body1" color="inherit">
                        This run has a discrete ratio of effective sequences in alignment to protein length (n_eff/L={qualityIndex.toFixed(2)})
                        <Link to="/faq/results#quality" className={classes.link}>
                            <InfoIcon className={classes.infoIcon} />
                        </Link>
                    </Typography>
                </Paper>
            );
        }

        // Metadata (info)

        let numSequencesOptions = (
            <ListItem
                key={METADATA_KEYS.numSequences}
            >
                <ListItemAvatar>
                    <Avatar>
                        <Align />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Number of sequences in alignment: " + parseInt(this.state.metadata[METADATA_KEYS.numSequences], 10)}
                />
            </ListItem>
        );

        let effectiveSequencesOptions = (
            <ListItem
                key={METADATA_KEYS.effectiveSequences}
            >
                <ListItemAvatar>
                    <Avatar>
                        <Align />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Number of effective sequences: " + parseInt(this.state.metadata[METADATA_KEYS.effectiveSequences], 10)}
                />
            </ListItem>
        );

        let metadataOptions = {
            [METADATA_KEYS.numSequences]: numSequencesOptions,
            [METADATA_KEYS.effectiveSequences]: effectiveSequencesOptions,
        };

        // Alignment Files Options

        let alignmentFilesOptions = (
            <a
                href={(() => {
                    let alignmentLocation = API_ENDPOINTS.precomputedJobDownload.uri.clone();

                    alignmentLocation.addQuery("q", this.id);
                    alignmentLocation.addQuery("b", this.props.batchKey);
                    alignmentLocation.addQuery("start", this.props.job.start);
                    alignmentLocation.addQuery("end", this.props.job.end);

                    alignmentLocation.addQuery("a", fileNames.alignmentFile);

                    let url = ALIGNMENT_VIEWER_ENDPOINTS.alignmentViewer.uri.clone();

                    url.addQuery("url", encodeURI(alignmentLocation));

                    return url.toString();
                })()}
                className={classes.link}
                key={fileNames.alignmentFile}
            >
                <ListItem button>
                    <ListItemAvatar>
                        <Avatar className={classes.avatarExtra}>
                            <Figure/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"View alignment in alignmentviewer.org"}
                    />
                </ListItem>
            </a>
        );

        let alignmentStatisticsFile = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("q", this.id);
                    url.addQuery("b", this.props.batchKey);
                    url.addQuery("start", this.props.job.start);
                    url.addQuery("end", this.props.job.end);

                    url.addQuery("f", fileNames.alignmentStatisticsFile);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.alignmentStatisticsFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Alignment statistics table"}
                    />
                </ListItem>
            </Link>
        );

        let alignOptions = {
            [fileNames.alignmentStatisticsFile]: alignmentStatisticsFile,
            [fileNames.alignmentFile]: alignmentFilesOptions,
        };

        // Couplings Files Options

        let ECFileOptions = (
            <div  key={fileNames.ecFile}>
                <Link
                    to={(() => {
                        let url = new URI("/table");

                        url.addQuery("q", this.id);
                        url.addQuery("b", this.props.batchKey);
                        url.addQuery("start", this.props.job.start);
                        url.addQuery("end", this.props.job.end);

                        url.addQuery("f", fileNames.ecFile);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarInfo}>
                                <Table/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"Raw evolutionary couplings (ECs) table"}
                        />
                    </ListItem>
                </Link>
                <Link
                    to={(() => {
                        let url = new URI("/ribbon");

                        url.addQuery("q", this.id);
                        url.addQuery("b", this.props.batchKey);
                        url.addQuery("start", this.props.job.start);
                        url.addQuery("end", this.props.job.end);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View ECs on a circle"}
                        />
                    </ListItem>
                </Link>
                <Link
                    to={(() => {
                        let url = new URI("/contact");

                        url.addQuery("q", this.id);
                        url.addQuery("b", this.props.batchKey);
                        url.addQuery("start", this.props.job.start);
                        url.addQuery("end", this.props.job.end);

                        url.addQuery("f", fileNames.ecFile);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View raw contact map"}
                        />
                    </ListItem>
                </Link>
                <ListItem
                    button
                    onClick={this.addToStudio(fileNames.ecFile)}
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarExtra}>
                            <Figure/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"View contact map and pdb comparison"}
                    />
                </ListItem>
            </div>
        );

        let enrichmentFileOptions = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("q", this.id);
                    url.addQuery("b", this.props.batchKey);
                    url.addQuery("start", this.props.job.start);
                    url.addQuery("end", this.props.job.end);

                    url.addQuery("f", fileNames.enrichmentFile);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.enrichmentFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"EC enrichment table"}
                    />
                </ListItem>
            </Link>
        );

        let evZoomOptions = (
            <a
                href={(() => {
                    let alignmentLocation = API_ENDPOINTS.precomputedJobDownload.uri.clone();

                    alignmentLocation.addQuery("q", this.id);
                    alignmentLocation.addQuery("b", this.props.batchKey);
                    alignmentLocation.addQuery("start", this.props.job.start);
                    alignmentLocation.addQuery("end", this.props.job.end);

                    alignmentLocation.addQuery("a", fileNames.evZoomFile);

                    let url = EVZOOM_ENDPOINTS.evzoom.uri.clone();

                    url.addQuery("data", encodeURI(alignmentLocation));

                    return url.toString();
                })()}
                className={classes.link}
                key={fileNames.evZoomFile}
            >
                <ListItem button>
                    <ListItemAvatar>
                        <Avatar className={classes.avatarExtra}>
                            <Figure/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"View in EVzoom.org"}
                    />
                </ListItem>
            </a>
        );

        let couplingsOptions = {
            [fileNames.enrichmentFile]: enrichmentFileOptions,
            [fileNames.ecFile]: ECFileOptions,
            [fileNames.evZoomFile]: evZoomOptions
        };

        // Compare Files Options

        let monomerContactsFileOptions = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("q", this.id);
                    url.addQuery("b", this.props.batchKey);
                    url.addQuery("start", this.props.job.start);
                    url.addQuery("end", this.props.job.end);

                    url.addQuery("f", fileNames.monomerContactsFile);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.monomerContactsFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Known i-j distances table"}
                    />
                </ListItem>
            </Link>
        );

        let ECComparedAllFileOptions = (
            <div key={fileNames.ECComparedAllFile}>
                <Link
                    to={(() => {
                        let url = new URI("/contact");

                        url.addQuery("q", this.id);
                        url.addQuery("b", this.props.batchKey);
                        url.addQuery("start", this.props.job.start);
                        url.addQuery("end", this.props.job.end);

                        url.addQuery("f", fileNames.ECComparedAllFile);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View contact map with known structures"}
                        />
                    </ListItem>
                </Link>
                <ListItem
                    button
                    onClick={this.addToStudio(fileNames.ECComparedAllFile)}
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarExtra}>
                            <Figure/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"View contact map with known structure and pdb comparison"}
                    />
                </ListItem>
                <Link
                    to={(() => {
                        let url = new URI("/table");

                        url.addQuery("q", this.id);
                        url.addQuery("b", this.props.batchKey);
                        url.addQuery("start", this.props.job.start);
                        url.addQuery("end", this.props.job.end);

                        url.addQuery("f", fileNames.ECComparedAllFile);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarInfo}>
                                <Table/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"ECs with known i-j distances table"}
                        />
                    </ListItem>
                </Link>
            </div>
        );

        let compareOptions = {
            [fileNames.monomerContactsFile]: monomerContactsFileOptions,
            [fileNames.ECComparedAllFile]: ECComparedAllFileOptions
        };

        // Mutate Files Options

        let mutationMatrixFileOptions = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("q", this.id);
                    url.addQuery("b", this.props.batchKey);
                    url.addQuery("start", this.props.job.start);
                    url.addQuery("end", this.props.job.end);

                    url.addQuery("f", fileNames.mutationMatrixFile);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.mutationMatrixFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Mutation matrix table"}
                    />
                </ListItem>
            </Link>
        );

        let mutationMatrixPlotTypes = {
            pdf: {
                name: (model) => capitalize(model)  +" model plot (pdf download)"
            },
            html: {
                name: (model) => capitalize(model) +" model"
            }
        };

        let mutationMatrixPlotOptions = (
            <div key={fileNames.mutationMatrixPlots}>
                {this.state.files[fileNames.mutationMatrixPlots] && this.state.files[fileNames.mutationMatrixPlots].map(filename => {

                    // Expect filename to be:
                    // /n/groups/marks/web/precomputed/public/A0A0M1USH3_PF13170_6-317/A0A0M1USH3_PF13170_6-317_b05/mutate/A0A0M1USH3_PF13170_6-317_b05_independent_model.pdf

                    let fileNameParts = filename
                        .split("/")
                        .pop()
                        .split("_");

                    // Expect position 2 in array to be "model.html"
                    let fileTypeParts = fileNameParts.pop().split(".");
                    let modelType = fileNameParts.pop();

                    // Expect position 2 in array to be "html"
                    let plotType = mutationMatrixPlotTypes[fileTypeParts[1]];

                    if(fileTypeParts[1] === "pdf"){
                        return <ListItem
                            key={filename}
                            onClick={() => {
                                let alignmentLocation = API_ENDPOINTS.precomputedJobDownload.uri.clone();

                                alignmentLocation.addQuery("q", this.id);
                                alignmentLocation.addQuery("b", this.props.batchKey);
                                alignmentLocation.addQuery("start", this.props.job.start);
                                alignmentLocation.addQuery("end", this.props.job.end);

                                alignmentLocation.addQuery("f", filename);

                                DownloadFile.download(alignmentLocation);
                            }}
                            button
                        >
                            <ListItemAvatar>
                                <Avatar className={classes.avatarExtra}>
                                    <Figure/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={plotType.name(modelType)}
                            />
                        </ListItem>
                    } else {
                        let downloadLocation = API_ENDPOINTS.precomputedJobDownload.uri.clone();

                        downloadLocation.addQuery("q", this.id);
                        downloadLocation.addQuery("b", this.props.batchKey);
                        downloadLocation.addQuery("start", this.props.job.start);
                        downloadLocation.addQuery("end", this.props.job.end);
                        downloadLocation.addQuery("f", filename);
                        downloadLocation.addQuery("binary", true);

                        return <ListItem
                            key={filename}
                        >
                            <ListItemText
                                primary={plotType.name(modelType)}
                            />
                            <object data={downloadLocation.toString()} width="100%" height="256px">
                                Your browser doesn’t support the object tag.
                            </object>
                        </ListItem>
                    }
                })}
            </div>
        );

        let mutateOptions = {
            [fileNames.mutationMatrixFile]: mutationMatrixFileOptions,
            [fileNames.mutationMatrixPlots]: mutationMatrixPlotOptions,
        };

        let foldingComparisonFile = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("q", this.id);
                    url.addQuery("b", this.props.batchKey);
                    url.addQuery("start", this.props.job.start);
                    url.addQuery("end", this.props.job.end);

                    url.addQuery("f", fileNames.foldingComparisonFile);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.foldingComparisonFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Folding assessment table"}
                    />
                </ListItem>
            </Link>
        );

        let foldOptions = {
            [fileNames.foldingComparisonFile]: foldingComparisonFile
        };

        let topPredictedPDFFiles = this.state.PDBRanking &&
            this.state.PDBRanking.slice(0,1).map((PDBFile, index) => (
                <Link
                    to={(() => {
                        let url = new URI("/ngl");

                        url.addQuery("q", this.id);
                        url.addQuery("b", this.props.batchKey);
                        url.addQuery("start", this.props.job.start);
                        url.addQuery("end", this.props.job.end);

                        url.addQuery("f", PDBFile.filename);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                    key={PDBFile.filename}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View predicted structures"}
                        />
                    </ListItem>
                </Link>
            ));

        // Archive File Option

        let archiveOptions = {
            [fileNames.archiveFile]: <ListItem
                key={fileNames.archiveFile}
                onClick={() => {
                    let alignmentLocation = API_ENDPOINTS.precomputedJobDownload.uri.clone();

                    alignmentLocation.addQuery("q", this.id);
                    alignmentLocation.addQuery("b", this.props.batchKey);
                    alignmentLocation.addQuery("start", this.props.job.start);
                    alignmentLocation.addQuery("end", this.props.job.end);

                    alignmentLocation.addQuery("a", fileNames.archiveFile);

                    DownloadFile.download(alignmentLocation);
                }}
                button
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatarExtra}>
                        <Archive/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Download archive"}
                />
            </ListItem>
        };

        let fileOptions = {
            alignOptions: [],
            couplingsOptions: [],
            compareOptions: [],
            mutateOptions: [],
            foldOptions: [],
            archiveOptions: []
        };

        Object.keys(this.state.files).forEach(k => {
            if(alignOptions[k]){
                return fileOptions['alignOptions'].push(alignOptions[k]);
            } else if(couplingsOptions[k]){
                return fileOptions['couplingsOptions'].push(couplingsOptions[k]);
            } else if(compareOptions[k]){
                return fileOptions['compareOptions'].push(compareOptions[k]);
            } else if(mutateOptions[k]){
                return fileOptions['mutateOptions'].push(mutateOptions[k]);
            } else if(foldOptions[k]){
                return fileOptions['foldOptions'].push(foldOptions[k]);
            } else if(archiveOptions[k]){
                return fileOptions['archiveOptions'].push(archiveOptions[k]);
            }
        });

        return <div>
            <Paper square className={classes.paper}>
                {this.props.job.uniprotID &&
                <Typography variant={"h6"}>
                    {"Uniprot Accession: " + this.props.job.uniprotID}
                </Typography>}
                {this.props.job.caspID && <Typography variant={"h6"}>
                    {"CASP ID: " + this.props.job.caspID}
                </Typography>}
                <Typography variant={"body1"}>
                    {"Alignment Cutoff: " + this.props.batchKey}
                    <Link to="/faq/results#runCutoff" className={classes.link}>
                        <InfoIcon className={classes.noColorInfoIcon} color={"primary"} />
                    </Link>
                </Typography>
                {this.props.job.start && this.props.job.end &&
                <Typography variant={"body1"}>
                    {"Region: " + this.props.job.start + "-" + this.props.job.end}
                </Typography>
                }
            </Paper>
            {qualityIndicator}
            <Paper square className={classes.paper}>
                <List subheader={<ListSubheader disableSticky={true}>Info</ListSubheader>}>
                    {Object.keys(this.state.metadata).map(k => {
                        return metadataOptions[k];
                    })}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Align stage results options</ListSubheader>}>
                    {fileOptions['alignOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Couplings stage results options</ListSubheader>}>
                    {fileOptions['couplingsOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Compare stage results options</ListSubheader>}>
                    {fileOptions['compareOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Mutate stage results options</ListSubheader>}>
                    {fileOptions['mutateOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Fold stage results options</ListSubheader>}>
                    {fileOptions['foldOptions']}

                    {topPredictedPDFFiles}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Archive file options</ListSubheader>}>
                    {fileOptions['archiveOptions']}
                </List>
            </Paper>
        </div>;
    }
}

PrecomputedBatchInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    batch: PropTypes.object.isRequired,
    job: PropTypes.object.isRequired,
    batchKey: PropTypes.string.isRequired
};

export default StudioDispatcher(withRouter(withStyles(styles)(PrecomputedBatchInfo)));