import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import bibtexParser from 'bibtex-parse-js';
import bibtex from './citations.bib';

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    link: {
    },
    justify: {
        textAlign: "justify"
    }
});


class Cite extends React.Component {

    state = {
        text: ""
    };

    componentDidMount(){
        this.readTextFile(bibtex);
    }

    readTextFile = file => {
        let rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, true);
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    let allText = rawFile.responseText;
                    this.setState({
                        text: allText
                    });
                }
            }
        };
        rawFile.send();
    };

    render(){
        const { classes } = this.props;

        let citations = bibtexParser.toJSON(this.state.text);

        return (<div className={classes.content}>
            <Grid spacing={16} container>
                {citations.map(c =>
                    <Grid key={c.citationKey} xs={12} md={12} xl={12} item>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {c.entryTags.title}
                                </Typography>
                                <Typography variant="caption">
                                    <strong>{"Year: " }</strong>{ c.entryTags.year}
                                </Typography>
                                <Typography component="p">
                                    <strong>{"Authors: " }</strong>{ c.entryTags.author}
                                </Typography>
                                <br/>
                                <Typography component="p" className={classes.justify}>
                                    <strong>{"Abstract: "}</strong>{c.entryTags.abstract}
                                </Typography>
                                <br/>
                                <Typography variant="caption">
                                    <strong>{"DOI: " }</strong>
                                    <a target="_blank" rel="noopener noreferrer" href={c.entryTags.url} className={classes.link}>
                                        {c.entryTags.doi}
                                    </a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>)}
            </Grid>
        </div>);
    }
}

Cite.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Cite);