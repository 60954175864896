import React from 'react';
import PropTypes from 'prop-types';
import storeComponentWrapper from '../../../stores/jobDispatcher';

import UserInfoBroker from '../../../brokers/users/UserInfoBroker';
import AvatarMessageElement, {type, avatar} from '../../paperElements/AvatarMessage';
import ActionButtonElement from '../../paperElements/ActionButton';
import LinearProgress from '@material-ui/core/LinearProgress';


const status = {
    "LOADING": 0,
    "LOGGED_IN": 1,
    "LOGGED_OUT": 2
};

class UserLoginStatus extends React.Component {

    constructor(){
        super();

        this.state = {
            status: status.LOADING,
            email: ""
        };
    }

    componentDidMount(){
        UserInfoBroker.run()
            .then((results) => {
                this.setState({
                    status: status.LOGGED_IN,
                    email: results[0].email
                })
            })
            .catch(() => {
                this.setState({
                    status: status.LOGGED_OUT
                })
            });
    }

    render() {
        let messageAvatar = avatar.warning;
        let messageType = type.neutral;
        let message = "Loading...";

        switch(this.state.status){
            case status.LOADING:
                messageAvatar = avatar.warning;
                messageType = type.neutral;
                message = "Loading...";
                break;
            case status.LOGGED_IN:
                messageAvatar = avatar.check;
                messageType = type.positive;
                message = "You are logged in. Your job will be stored for a month (and you can extend it further). " +
                    "You will receive states updates at you email address: " +
                    this.state.email;
                break;
            case status.LOGGED_OUT:
                messageAvatar = avatar.warning;
                messageType = type.neutral;
                message = "You are not logged in. You can still submit your job, but your results will be kept only " +
                    "for one week and if you lose your link, you won't be able to retrieve your results.";
                break;
            default:
                messageAvatar = avatar.warning;
                messageType = type.neutral;
                message = "Loading...";
        }

        return (
            <div>
                {this.state.status === status.LOADING && <LinearProgress/>}

                <AvatarMessageElement
                    message={message}
                    avatar={messageAvatar}
                    type={messageType}
                />
                {this.state.status === status.LOGGED_OUT && <ActionButtonElement href={"/login/"}
                                                                                 text={"Click here to login"}/>}
            </div>
            )
    }
}

UserLoginStatus.propTypes = {
    action: PropTypes.func,
    jobParameters: PropTypes.object,
};


export default storeComponentWrapper(UserLoginStatus);