import React from 'react';
import PropTypes from "prop-types";
import SequenceHighlighter from '../../paperElements/SequenceHighlighter';
import storeComponentWrapper from '../../../stores/jobDispatcher';
import { withStyles } from '@material-ui/core/styles';
import SequenceBroker, {FORMATS} from "../../../brokers/sequences/SequencesBroker";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";

const styles = theme => ({
});


class RegionDisplay extends React.Component {

    state = {
        sequences: [],
        collapsed: false
    };

    componentDidMount(){
        this.fetchProteinAndRegion();
    }

    fetchProteinAndRegion = () => {
        SequenceBroker.run({
            jobHash: this.props.jobHash,
            format: FORMATS.json
        })
            .then(result => {
                let sequences = new Map();
                let tmp;

                result.forEach(sequenceOrRegion => {
                    if(sequenceOrRegion.region_md5 !== undefined && sequenceOrRegion.region_md5 !== null){
                        // It's a region!

                        if(sequences.has(sequenceOrRegion.sequence_md5)){
                            tmp = sequences.get(sequenceOrRegion.sequence_md5);

                            tmp.regionStart= sequenceOrRegion.start;
                            tmp.regionEnd= sequenceOrRegion.end;
                        } else {
                            sequences.set(sequenceOrRegion.sequence_md5, {
                                regionStart: sequenceOrRegion.start,
                                regionEnd: sequenceOrRegion.end,
                            })
                        }
                    } else {
                        // It's a sequence!

                        if(sequences.has(sequenceOrRegion.sequence_md5)){
                            tmp = sequences.get(sequenceOrRegion.sequence_md5);

                            tmp.sequence = sequenceOrRegion.length;
                            tmp.regionStart = tmp.regionStart || 0;
                            tmp.regionEnd = tmp.regionStart || sequenceOrRegion.sequence.length;
                        } else {
                            sequences.set(sequenceOrRegion.sequence_md5, {
                                sequence: sequenceOrRegion.sequence,
                                regionStart: 0,
                                regionEnd: sequenceOrRegion.sequence.length,
                            })
                        }
                    }
                });

                this.setState({
                    sequences: Array.from(sequences.values())
                });
            })
            .catch(e => console.error(e))
    };

    render() {
        const { classes } = this.props;

        return (
            <ExpansionPanel onChange={() => this.setState({collapsed: !this.state.collapsed})} expanded={this.state.collapsed}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                        Expand to see sequence and region (green) on which the job was run
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                        {this.state.sequences && this.state.sequences.map((sequence, i) =>
                            <div key={i}>
                                {i > 0 &&
                                <br/>
                                }
                                {this.state.sequences.length > 1 &&
                                <Typography variant={"h6"} className={classes.heading}>
                                    {["First", "Second"][i] + " sequence:"}
                                </Typography>
                                }

                                <SequenceHighlighter
                                    region={[sequence.regionStart, sequence.regionEnd]}
                                    string={sequence.sequence}
                                />
                            </div>

                        )}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

RegionDisplay.propTypes = {
    jobHash: PropTypes.string.isRequired,
};

export default withStyles(styles)(storeComponentWrapper(RegionDisplay));