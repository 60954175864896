import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import storeComponentWrapper from '../../../stores/jobDispatcher';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RunData from '../../../brokers/runs/info/RunData';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { capitalize } from "../../../utilities/StringUtilities";
import delay from "../../../utilities/ActionDelayer";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from '@material-ui/icons/Done';
import Timer from '@material-ui/icons/Timer';
import Error from '@material-ui/icons/ErrorOutline';
import Email from '@material-ui/icons/Email';
import {states as requestState} from "../../../utilities/Requests";
import {Link} from 'react-router-dom';

// TODO: draw your own icons
import Align from '@material-ui/icons/FormatAlignLeft';
import Couplings from '@material-ui/icons/SettingsInputComponent';
import Compare from '@material-ui/icons/CompareArrows';
import Mutate from '@material-ui/icons/ChangeHistory';
import Fold from '@material-ui/icons/Map';
import Concatenate from '@material-ui/icons/Link';


const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    iconElement: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "inherit"
    },
    button: {
        margin: theme.spacing.unit,
    },
    avatar: {
        backgroundColor: '#fff',
        color: "black",
        margin: 10,
    },
    icon: {
        width: 40,
        height: 40,
    },
    center: {
        textAlign: "center"
    },
    infoIcon: {
        width: 17,
        color: "#858787",
        height: "inherit"
    },
    verticalCenter: {
        margin: "auto 0"
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});

const defaultJobInfo = {
    createdAt: "",
    jobHash: "",
    runHash: "",
    stage: "Loading...",
    status: "Loading...",
    updatedAt: ""
};

// const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;
const ONE_DAY = 1 * 24 * 60 * 60 * 1000;
// const TWELVE_HOURS =  12 * 60 * 60 * 1000;

const ALLOCATED_RUN_TIME = ONE_DAY;


class RunInfoNugget extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            requestStatus: requestState.LOADING,
            jobInfo: defaultJobInfo,
        };
    }

    componentDidMount(){
        this.fetchJobInfo();
    }

    componentWillReceiveProps(newProps) {
        this.props = newProps;
        this.fetchJobInfo();
    };

    fetchJobInfo = () => {
        if(this.props.refresh === true){
            this.setState({
                requestStatus: requestState.LOADING
            });

            RunData.run(this.props.runHash)
                .then(([result]) => {

                    this.setState({
                        jobInfo: {...result},
                        requestStatus: (result.status === undefined || result.status === null ? requestState.NO_RESULTS : requestState.LOADED),
                    });

                    if(result.status === "done" || result.status === "failed"){
                        this.switchAutoUpdate();
                    }

                    delay("GET_RUN_INFO_" + this.props.runHash, this.fetchJobInfo, this.props.refreshRate);

                })
                .catch(() => {
                    this.setState({
                        requestStatus: requestState.ERROR,
                    });
                });
        }
    };

    switchAutoUpdate = () => {
        this.props.refreshSwitch && this.props.refreshSwitch();
    };

    render() {
        const { classes } = this.props;

        let lastUpdated = new Date(this.state.jobInfo.updatedAt);
        let hasBeenRunningTooLong = ((new Date()) - lastUpdated) > ALLOCATED_RUN_TIME;

        let status = <CircularProgress color="primary"/>;
        let stage = <CircularProgress color="primary"/>;

        switch(this.state.jobInfo.stage){
            case "align":
            case "align_1":
            case "align_2":
                stage = <Align color="secondary" className={classes.icon}/>;
                break;
            case "couplings":
                stage = <Couplings color="secondary" className={classes.icon}/>;
                break;
            case "compare":
                stage = <Compare color="secondary" className={classes.icon}/>;
                break;
            case "concatenate":
                stage = <Concatenate color="secondary" className={classes.icon}/>;
                break;
            case "mutate":
                stage = <Mutate color="secondary" className={classes.icon}/>;
                break;
            case "fold":
                stage = <Fold color="secondary" className={classes.icon}/>;
                break;
            case null:
                stage = <Timer color="secondary" className={classes.icon}/>;
                break;
            default:
                stage = <CircularProgress color="secondary"/>;
        }

        switch(this.state.jobInfo.status){
            case "done":
                status = <Done color="primary" className={classes.icon}/>;
                stage = <Done color="secondary" className={classes.icon}/>;
                break;
            case "failed":
                status = <Error color="primary" className={classes.icon}/>;
                break;
            case null:
                status = <Timer color="primary" className={classes.icon}/>;
                break;
            default:
                if(hasBeenRunningTooLong){
                    status = <Error color="primary" className={classes.icon}/>;
                } else {
                    status = <CircularProgress color="primary"/>;
                }
        }

        return (
            <div>
                <Paper className={classes.paper} square elevation={2} >
                    <Grid spacing={16} container>
                        <Grid item xl={4} md={4} xs={12} >
                            <Grid spacing={16} container>
                                <Grid item xl={6} md={6} xs={6} >
                                    <div className={classes.iconElement}>
                                        <Avatar className={classes.avatar}>
                                            {status}
                                        </Avatar>
                                    </div>
                                    <Typography variant="caption" className={classes.center}>
                                        {capitalize(this.state.jobInfo.status || "Pending")}
                                    </Typography>
                                </Grid>
                                <Grid item xl={6} md={6} xs={6} >
                                    <div className={classes.iconElement}>
                                        <Avatar className={classes.avatar}>
                                            {stage}
                                        </Avatar>
                                    </div>
                                    <Typography variant="caption" className={classes.center}>
                                        {capitalize(this.state.jobInfo.stage || "Pending")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={8} md={8} xs={12}>
                            <FormGroup row>
                                <Typography component={"div"} variant="body1">
                                    {"Run cutoff: " + this.props.runHash.slice(33)}
                                    <Link to="/faq/results#runCutoff" className={classes.link}>
                                        <InfoIcon className={classes.infoIcon} />
                                    </Link>
                                </Typography>
                            </FormGroup>
                            <br/>
                            { this.state.jobInfo.status !== "done" && this.state.jobInfo.status !== "failed" &&
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.props.refresh}
                                            onChange={this.switchAutoUpdate}
                                            color={"primary"}
                                        />
                                    }
                                    label="Auto update"
                                />
                            </FormGroup>
                            }
                            {this.state.jobInfo.status !== "done" && (this.state.jobInfo.status === "failed" || hasBeenRunningTooLong) && <FormGroup row>
                                <IconButton color={"primary"}
                                >
                                    <a href={
                                        "mailto:support@evcouplings.org"
                                        + "?subject=[" + (this.state.jobInfo.status === "failed" ? "FAILED" : "TIMEOUT") + "] run " + this.props.runHash
                                        + "&body=Check the following run stack trace:%0A%0A%0A"
                                        + "Run: \t" + this.props.runHash + "%0A"
                                        + "Job: \t" + this.state.jobInfo.jobHash + "%0A"
                                        + "Job URL: \t https%3A%2F%2Fv1.evcouplings.org%2Fjobs%2F" + this.state.jobInfo.jobHash + "%0A%0A%0A"
                                        + "Additional comments: %0A"
                                        + "==========================%0A"
                                        + "<PUT ADDITIONAL COMMENTS HERE>%0A"
                                        + "==========================%0A"
                                    }>
                                        <Email/>
                                    </a>
                                </IconButton>

                                {hasBeenRunningTooLong && this.state.jobInfo.status !== "failed" &&
                                <Typography variant="body1" className={classes.verticalCenter}>
                                    This run has been running for too long. <Link to="/faq/results#timeout">Check the FAQ for common reasons as to why this happens.</Link> Alternatively, drop us an email to check on it.
                                </Typography>
                                }
                                {this.state.jobInfo.status === "failed" &&
                                <Typography variant="body1" className={classes.verticalCenter}>
                                    Let us know this run has failed.
                                </Typography>
                                }
                            </FormGroup>}
                            <br/>
                            <Typography variant="caption">
                                This run's id is {this.props.runHash}.
                            </Typography>
                            {this.state.jobInfo.createdAt &&
                            <Typography variant="caption">
                                This run was created on {new Date(this.state.jobInfo.createdAt).toUTCString()}.
                            </Typography>
                            }
                            {this.state.jobInfo.updatedAt &&
                            <Typography variant="caption">
                                This run was last updated on {new Date(this.state.jobInfo.updatedAt).toUTCString()}.
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

RunInfoNugget.propTypes = {
    classes: PropTypes.object.isRequired,
    refreshRate: PropTypes.number.isRequired,
    refreshSwitch: PropTypes.func,
    refresh: PropTypes.bool,
    runHash: PropTypes.string
};

export default withRouter(storeComponentWrapper(withStyles(styles)(RunInfoNugget)));