const status = {
    UNSUBMITTED: 4,
    INVALID: 0,
    SUBMITTED: 5,

    isEditable: (incomingStatus) => {
        return incomingStatus === status.UNSUBMITTED || incomingStatus === status.INVALID;
    },
};

const initial = {
    // SubmitJob component
    jobValidity: status.UNSUBMITTED

};

function JobValidationStatus(state = initial, action) {
    switch (action.type) {
        case 'UPDATE_JOB_STATUS':
            return {
                ...state,
                ...action.payload
            };

        case 'RESET_JOB_SUBMISSION':
            return initial;

        default:
            return state;
    }
}

export default JobValidationStatus;

export const jobValidationStatus = status;

