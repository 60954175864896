import {PFAM_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";
import xml2json from '../utils/xml2json';

class pfamBroker {

    static run(uniprotId){

        const endpoint = PFAM_ENDPOINTS.pfamRemote;

        let endpoint_URI = endpoint.uri.clone();

        endpoint_URI.addQuery("entry", uniprotId);
        endpoint_URI.addQuery("output", "xml");

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (xml) => {
                    return resolve(xml2json(xml));
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default pfamBroker;