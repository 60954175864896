import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../../global";
import jQuery from "jquery";

class RunResults {
    static run(hash, result, file){

        const endpoint = API_ENDPOINTS.runResults;

        let endpoint_URI = endpoint.uri.clone();

        endpoint_URI.addQuery("q", hash);
        endpoint_URI.addQuery("r", result);
        endpoint_URI.addQuery("f", file);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                dataType: 'text',
                success: (...args) => {
                    return resolve(JSON.parse(args[0].replace(/NaN/g, "null")));
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default RunResults;

export const RESULTS_KEYS = {
    CSVToJSON: "csv_to_json",
    predictedPDBRanking: "predicted_pdb_ranking",
};