export const readTextFile = file => {
    let rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, true);
    return new Promise((resolve, reject) => {

        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    let allText = rawFile.responseText;

                    resolve(allText);
                } else {
                    reject();
                }
            }
        };
        rawFile.send();
    });

};