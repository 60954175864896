import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Protein, autodetect, validInput, parsers } from 'protein-parser';
import storeComponentWrapper from '../../../stores/jobDispatcher'
import {proteinStatus} from "../../../stores/job/JobParameters";
import delay from "../../../utilities/ActionDelayer";
import classnames from 'classnames';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: "100%",
        height: "100%"
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    underline: {
        textDecoration: "underline"
    }
});

class SequenceInput extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            proteinSequenceInput: ''
        };

        this.proteinStatusAction = this.props.complexSubmissionIdentifier !== undefined ? "SET_COMPLEX_PROTEIN_STATUS" : "SET_PROTEIN_STATUS";
        this.jobParametersAction = this.props.complexSubmissionIdentifier !== undefined ? "SET_COMPLEX_JOB_PARAMETERS" : "SET_JOB_PARAMETERS";

        this.handleChange = this.handleChange.bind(this);
        this.deleyedKeyUp = this.deleyedKeyUp.bind(this);
    }

    componentWillUnmount(){
        this.props.action({
            type: "RESET_JOB_SUBMISSION"
        });
    }

    handleChange() {
        let textInput = this.state.proteinSequenceInput;

        if(textInput.length < 3){
            this.props.action({
                type: this.proteinStatusAction,
                payload: {
                    proteinStatus: proteinStatus.INVALID,
                    forProtein: this.props.complexSubmissionIdentifier
                }
            });
            return;
        }

        textInput = textInput.toUpperCase();

        // It's going to be !== undefined only if a valid sequence or UniProt accession
        let retrievingFunction = autodetect(textInput);

        if(retrievingFunction !== undefined){
            retrievingFunction(textInput)
                .then(([proteins, _]) => {
                    if(proteins.length > 1 && proteins[0] !== undefined){
                        if(proteins[0].uniprotData !== undefined){
                            this.props.action({
                                type: this.jobParametersAction,
                                payload: {
                                    protein: proteins[0],
                                    proteinStatus: proteinStatus.UNIPROT,
                                    forProtein: this.props.complexSubmissionIdentifier
                                }
                            });
                        } else {
                            this.props.action({
                                type: this.jobParametersAction,
                                payload: {
                                    protein: proteins[0],
                                    proteinStatus: proteinStatus.MULTIPLESEQUENCES,
                                    forProtein: this.props.complexSubmissionIdentifier
                                }
                            });
                        }
                    } else if(proteins[0] !== undefined){

                        let parser = validInput(textInput);

                        if(parser === parsers.accession){
                            this.props.action({
                                type: this.jobParametersAction,
                                payload: {
                                    protein: proteins[0],
                                    proteinStatus: proteinStatus.UNIPROT,
                                    forProtein: this.props.complexSubmissionIdentifier
                                }
                            });
                        } else if(parser === parsers.fasta){
                            if(proteins[0].uniprotData !== undefined){
                                this.props.action({
                                    type: this.jobParametersAction,
                                    payload: {
                                        protein: proteins[0],
                                        proteinStatus: proteinStatus.UNIPROT,
                                        forProtein: this.props.complexSubmissionIdentifier
                                    }
                                });
                            } else {
                                this.props.action({
                                    type: this.jobParametersAction,
                                    payload: {
                                        protein: proteins[0],
                                        proteinStatus: proteinStatus.FASTA,
                                        forProtein: this.props.complexSubmissionIdentifier
                                    }
                                });
                            }
                        } else if(parser === parsers.protein_name){
                            this.props.action({
                                type: this.jobParametersAction,
                                payload: {
                                    protein: proteins[0],
                                    proteinStatus: proteinStatus.UNIPROT,
                                    forProtein: this.props.complexSubmissionIdentifier
                                }
                            });
                        } else if(parser === parsers.aa){
                            this.props.action({
                                type: this.jobParametersAction,
                                payload: {
                                    protein: proteins[0],
                                    proteinStatus: proteinStatus.AA,
                                    forProtein: this.props.complexSubmissionIdentifier
                                }
                            });
                        } else {
                            console.error("Unexpected error when validating protein retrieving function");
                            this.props.action({
                                type: "SET_PROTEIN_STATUS",
                                payload: {
                                    proteinStatus: proteinStatus.INVALID,
                                    forProtein: this.props.complexSubmissionIdentifier
                                }
                            });
                        }
                    } else {

                        this.props.action({
                            type: this.jobParametersAction,
                            payload: {
                                proteinStatus: proteinStatus.INVALID,
                                forProtein: this.props.complexSubmissionIdentifier
                            }
                        });
                    }
                })
                .catch((e) => {
                    console.error(e);

                    this.props.action({
                        type: this.jobParametersAction,
                        payload: {
                            proteinStatus: proteinStatus.INVALID,
                            forProtein: this.props.complexSubmissionIdentifier
                        }
                    });
                });
        } else {
            this.props.action({
                type: this.jobParametersAction,
                payload: {
                    proteinStatus: proteinStatus.INVALID,
                    forProtein: this.props.complexSubmissionIdentifier
                }
            });
        }
    }

    loadSequence = type => event => {
        let fillerProtein =  new Protein("MALLHSARVLSGVASAFHPGLAAAASARASSWWAHVEMGPPDPILGVTEAYKRDTNSKKMNLGVGAYRDDNGKPYVLPSVRKAEAQIAAKGLDKEYLPIGGLAEFCRASAELALGENSEVVKSGRFVTVQTISGTGALRIGASFLQRFFKFSRDVFLPKPSWGNHTPIFRDAGMQLQSYRYYDPKTCGFDFTGALEDISKIPEQSVLLLHACAHNPTGVDPRPEQWKEIATVVKKRNLFAFFDMAYQGFASGDGDKDAWAVRHFIEQGINVCLCQSYAKNMGLYGERVGAFTVICKDADEAKRVESQLKILIRPMYSNPPIHGARIASTILTSPDLRKQWLQEVKGMADRIIGMRTQLVSNLKKEGSTHSWQHITDQIGMFCFTGLKPEQVERLTKEFSIYMTKDGRISVAGVTSGNVGYLAHAIHQVTK");

        fillerProtein.setUniprotData({
            accession: "P12345"
        });

        switch (type) {
            case 'fasta':
                this.setState({
                    proteinSequenceInput: `>My sequence
MALLHSARVLSGVASAFHPGLAAAASARASSWWAHVEMGPPDPILGVTEAYKRDTNSKKM
NLGVGAYRDDNGKPYVLPSVRKAEAQIAAKGLDKEYLPIGGLAEFCRASAELALGENSEV
VKSGRFVTVQTISGTGALRIGASFLQRFFKFSRDVFLPKPSWGNHTPIFRDAGMQLQSYR
YYDPKTCGFDFTGALEDISKIPEQSVLLLHACAHNPTGVDPRPEQWKEIATVVKKRNLFA
FFDMAYQGFASGDGDKDAWAVRHFIEQGINVCLCQSYAKNMGLYGERVGAFTVICKDADE
AKRVESQLKILIRPMYSNPPIHGARIASTILTSPDLRKQWLQEVKGMADRIIGMRTQLVS
NLKKEGSTHSWQHITDQIGMFCFTGLKPEQVERLTKEFSIYMTKDGRISVAGVTSGNVGY
LAHAIHQVTK`,
                });
                this.props.action({
                    type: this.jobParametersAction,
                    payload: {
                        proteinStatus: proteinStatus.FASTA,
                        protein: fillerProtein,
                        forProtein: this.props.complexSubmissionIdentifier
                    }
                });
                break;

            case 'aa':
                this.setState({
                    proteinSequenceInput: "MALLHSARVLSGVASAFHPGLAAAASARASSWWAHVEMGPPDPILGVTEAYKRDTNSKKMNLGVGAYRDDNGKPYVLPSVRKAEAQIAAKGLDKEYLPIGGLAEFCRASAELALGENSEVVKSGRFVTVQTISGTGALRIGASFLQRFFKFSRDVFLPKPSWGNHTPIFRDAGMQLQSYRYYDPKTCGFDFTGALEDISKIPEQSVLLLHACAHNPTGVDPRPEQWKEIATVVKKRNLFAFFDMAYQGFASGDGDKDAWAVRHFIEQGINVCLCQSYAKNMGLYGERVGAFTVICKDADEAKRVESQLKILIRPMYSNPPIHGARIASTILTSPDLRKQWLQEVKGMADRIIGMRTQLVSNLKKEGSTHSWQHITDQIGMFCFTGLKPEQVERLTKEFSIYMTKDGRISVAGVTSGNVGYLAHAIHQVTK",
                });
                this.props.action({
                    type: this.jobParametersAction,
                    payload: {
                        proteinStatus: proteinStatus.AA,
                        protein: fillerProtein,
                        forProtein: this.props.complexSubmissionIdentifier
                    }
                });
                break;

            case 'accession':
                this.setState({
                    proteinSequenceInput: "P12345",
                });
                this.props.action({
                    type: this.jobParametersAction,
                    payload: {
                        proteinStatus: proteinStatus.UNIPROT,
                        protein: fillerProtein,
                        forProtein: this.props.complexSubmissionIdentifier
                    }
                });
                break;
            case 'protein_name':
                this.setState({
                    proteinSequenceInput: "AATM_RABIT",
                });
                this.props.action({
                    type: this.jobParametersAction,
                    payload: {
                        proteinStatus: proteinStatus.UNIPROT,
                        protein: fillerProtein,
                        forProtein: this.props.complexSubmissionIdentifier
                    }
                });
                break;
            default:
                break;
        }
    };

    deleyedKeyUp(event) {

        this.setState({
            proteinSequenceInput: event.target.value
        });

        if(this.props.jobParameters.proteinStatus !== proteinStatus.LOADING){
            this.props.action({
                type: this.proteinStatusAction,
                payload: {
                    proteinStatus: proteinStatus.LOADING,
                    forProtein: this.props.complexSubmissionIdentifier
                }
            });
        }

        delay("SEQUENCE_INPUT_CHANGE", this.handleChange, 1000);
    }

    render() {
        const { classes } = this.props;

        const postPendices = ["First", "Second", "Third"];

        return (
            <div>
                <Paper square elevation={2} className={classes.paper}>
                    <Typography variant="h6">
                        {this.props.complexSubmissionIdentifier !== undefined ? postPendices[this.props.complexSubmissionIdentifier] + " sequence:" : "Sequence:"}
                    </Typography>
                    <form className={classes.container} noValidate autoComplete="off">
                        <TextField
                            id="proteinSequenceInput"
                            multiline
                            data-gramm_editor={false}
                            className={classes.textField}
                            value={this.state.proteinSequenceInput}
                            onChange={this.deleyedKeyUp}
                            margin="normal"
                            autoFocus
                        />
                    </form>
                    <Typography component={"div"} variant="body1">
                        {'Sequence can be in '}
                        <strong className={classnames("pointer", classes.underline)} onClick={this.loadSequence('fasta')}>{"FASTA format"}</strong>
                        {', a '}
                        <strong className={classnames("pointer", classes.underline)} onClick={this.loadSequence('accession')}>{"UniProt Accession"}</strong>
                        {' number or '}
                        <strong className={classnames("pointer", classes.underline)} onClick={this.loadSequence('protein_name')}>{"UniProt Protein Name"}</strong>
                        {', or  '}
                        <strong className={classnames("pointer", classes.underline)} onClick={this.loadSequence('aa')}>{"AA sequence"}</strong>
                        .
                    </Typography>
                    <Typography component={"div"} variant="caption">
                        Click the bold text for examples.
                    </Typography>
                </Paper>
            </div>
        );
    }
}

SequenceInput.propTypes = {
    action: PropTypes.func,
    classes: PropTypes.object.isRequired,
    jobParameters: PropTypes.object,
    complexSubmissionIdentifier: PropTypes.number
};

export default storeComponentWrapper(withStyles(styles)(SequenceInput));