import URI from 'urijs';

export const API_LOCATION = process.env.REACT_APP_API || "http://localhost:5001";

const API_URL = new URI(API_LOCATION);

export const API_ENDPOINTS = {
    api: {
        method: 'GET',
        uri: API_URL.clone()
    },
    jobSubmission: {
        method: "POST",
        uri: new URI(API_URL.path(URI.joinPaths("api", "jobs")))
    },
    complexJobSubmission: {
        method: "POST",
        uri: new URI(API_URL.path(URI.joinPaths("api", "jobs", "complex")))
    },
    jobSearch: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "jobs", "search")))
    },
    jobInfo: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "jobs")))
    },
    sequences: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "sequences")))
    },
    runSearch: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs", "search")))
    },
    runData: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs")))
    },
    runInfo: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs", "info")))
    },
    runResults: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs", "results")))
    },
    runMetadata: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs", "metadata")))
    },
    runDownload: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs", "download")))
    },
    runGetFilesList: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "runs", "download", "info")))
    },
    userLogin: {
        method: 'POST',
        uri: new URI(API_URL.path(URI.joinPaths("api", "user", "login")))
    },
    userLogout: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "user", "logout")))
    },
    userSignup: {
        method: 'POST',
        uri: new URI(API_URL.path(URI.joinPaths("api", "user", "signup")))
    },
    userInfo: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "user")))
    },
    userJobs: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "user", "jobs")))
    },
    precomputedJob: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "local")))
    },
    precomputedJobSearch: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "local", "search")))
    },
    CASPJobSearch: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "local", "casp")))
    },
    precomputedStructureRanking: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "local", "results", "structures", "ranking")))
    },
    precomputedJobDownload: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "local", "download")))
    },
    precomputedResultsInJSON: {
        method: 'GET',
        uri: new URI(API_URL.path(URI.joinPaths("api", "local", "results", "json")))
    },
};

export const PFAM_LOCATION = "https://pfam.xfam.org";

const PFAM_URL = new URI(PFAM_LOCATION);

export const PFAM_ENDPOINTS = {
    pfamRemote: {
        method: 'GET',
        uri: new URI(PFAM_URL.path(URI.joinPaths("protein")))
    }
};

export const ALIGNMENT_VIEWER_LOCATION = "https://alignmentviewer.org/";

const ALIGNMENT_VIEWER_URL = new URI(ALIGNMENT_VIEWER_LOCATION);

export const ALIGNMENT_VIEWER_ENDPOINTS = {
    alignmentViewer: {
        method: 'GET',
        uri: ALIGNMENT_VIEWER_URL.clone()
    }
};

export const EVZOOM_LOCATION = "https://debbiemarkslab.github.io/EVzoom/";
const EVZOOM_URL = new URI(EVZOOM_LOCATION);


export const EVZOOM_ENDPOINTS = {
    evzoom: {
        method: 'GET',
        uri: EVZOOM_URL.clone()
    }
};

export const REQUEST_TIMEOUT = 20000;