// Web workers
import WebWorker from './WebWorker';
import MyWorker from './Downloader';

// Endpoints
import {API_ENDPOINTS} from "../brokers/global";
import {queryType} from "../brokers/precomputed/PrecomputedDownload";

const requestStatus = {
    initialized: 0,
    downloading: 1,
    error: 3,
    downloaded: 4
};

export default class Retriever {
    constructor(run){
        // Worker initialisation
        this.worker = new WebWorker(MyWorker);
        this.run = run;
        this.status = requestStatus.initialized;
    }

    fetch = (file) => {
        return new Promise((resolve, reject)=> {
            let _download = (data) => {
                if(data !== null){
                    resolve(data);
                } else {
                    reject();
                }
            };

            let _handleWorker = (event) => {
                const { data } = event;

                switch(this.status){
                    case requestStatus.downloading:
                        _download(data);
                        break;
                    default:
                        this.status= requestStatus.error;
                        reject()
                }
            };

            this.worker.addEventListener("message", _handleWorker, false);

            let endpoint;
            let endpoint_URI;

            if(this.run.runHash !== undefined){
                endpoint = API_ENDPOINTS.runDownload;
                endpoint_URI = endpoint.uri.clone();

                endpoint_URI.addQuery("q", this.run.runHash);
                endpoint_URI.addQuery("f", file.query);
            } else {
                endpoint = API_ENDPOINTS.precomputedJobDownload;
                endpoint_URI = endpoint.uri.clone();

                endpoint_URI.addQuery("q", this.run.uniprotID);
                endpoint_URI.addQuery("b", this.run.batch);
                endpoint_URI.addQuery("start", this.run.start);
                endpoint_URI.addQuery("end", this.run.end);

                switch (file.type) {
                    case queryType.alias:
                        endpoint_URI.addQuery("a", file.query);
                        break;
                    case queryType.file:
                        endpoint_URI.addQuery("f", file.query);
                        break;
                    default:
                    // Do nothing
                }
            }

            this.status = requestStatus.downloading;
            this.worker.postMessage({
                endpoint: endpoint_URI.href(),
                method: endpoint.method,
                type: "text"
            });

        });

    };
}