import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withRouter} from 'react-router-dom';

import UserInfoBroker from "../../brokers/users/UserInfoBroker";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import Logout from "@material-ui/icons/ExitToApp";
import UserLogoutBroker from "../../brokers/users/UserLogoutBroker";


const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});


class UserInfo extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: "",
            phone: "",
        };

        this.getUserInfo();
    }

    logout = () => {
        UserLogoutBroker.run()
            .then(() => {
                let { history } = this.props;
                history.push("/");
            });
    };

    getUserInfo = () => {
        UserInfoBroker.run()
            .then(results => {
                let userInfo = results[0];
                this.setState({
                    email: userInfo.email,
                    phone: userInfo.phone
                })
            })
            .catch(response => {
                let code = response[0].status;

                switch(code){
                    case 401:
                        let { history } = this.props;
                        history.push("/");
                        break;
                    default:
                        throw response[0];
                }
            });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const { classes } = this.props;

        return <Paper className={classes.paper} square elevation={2} >
            <Grid spacing={16} container>
                <Grid item xs={12} md={10} xl={10}>
                    <Typography variant={"h6"}>
                        {this.state.email}
                    </Typography>
                    {this.state.phone && <Typography variant={"body1"}>
                        Phone: {this.state.phone}
                    </Typography>}
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                    <Button
                        color="secondary"
                        onClick={this.logout}
                        fullWidth={true}
                    >
                        Logout
                        <Logout className={classes.icon}/>
                    </Button>
                </Grid>
            </Grid>
        </Paper>;
    }
}

UserInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(UserInfo));