import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import SimpleDialog from '../dialog/SimpleDialog';
import SubmissionErrorDialog from './SubmissionErrorDialog';

import SignupBroker from "../../brokers/users/SignupBroker";
import validate from "validate.js";
import {withRouter} from "react-router-dom";


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    red: {
        color: "red"
    }
});


class Signup extends React.Component {

    state = {
        email: "",
        phone: "",
        password: "",
        loading: false,
        error: undefined,
        errorMessage: null
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleSubmit = () => {
        this.setState({
            loading: true,
            errorMessage: null
        });

        let validationMessages = validate(this.state, {
            email: {presence: {allowEmpty: false}, email: true},
            password: {presence: {allowEmpty: false}}
        });

        if(validationMessages !== undefined){
            this.setState({
                errorMessage: validationMessages,
                loading: false,
            });

            return false;
        }

        SignupBroker.run(this.state)
            .then(() => {
                this.setState({
                    loading: false
                });

                let { history } = this.props;
                history.push("/login");
            })
            .catch((error) => {
                switch(error[0].status){
                    case 400:
                        this.setState({
                            loading: false,
                            errorMessage: {duplicate: ["This user already exists."]},
                        });
                        break;
                    default:
                        this.setState({
                            loading: false,
                            error: error[0]
                        });
                }
            })
    };

    render() {
        const { classes } = this.props;

        return <Paper square elevation={2} >
            {this.state.loading && <LinearProgress />}
            {this.state.error !== undefined && <SimpleDialog
                component={<SubmissionErrorDialog error={this.state.error}/>}
                open={true}
                dialogClosed={()=> this.setState({error: undefined})}
                title={"Request Error"}/>}
            <div className={classes.content}>
                <Typography variant={"h6"}>
                    Sign up
                </Typography>
                <TextField
                    fullWidth={true}
                    label="Email"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                    margin="normal"
                    inputProps={{
                        type: "email"
                    }}
                />
                <TextField
                    fullWidth={true}
                    label="Phone"
                    className={classes.textField}
                    value={this.state.phone}
                    onChange={this.handleChange('phone')}
                    margin="normal"
                    inputProps={{
                        type: "number"
                    }}
                />
                <TextField
                    fullWidth={true}
                    label="Password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                    inputProps={{
                        type: "password"
                    }}
                />
                <div className={classes.content}>
                    {this.state.errorMessage && Object.keys(this.state.errorMessage).map(e => <Typography className={classes.red} key={e} variant={"body1"}>{"- " + this.state.errorMessage[e].join(", ")}</Typography>)}
                </div>
            </div>
            <Button fullWidth={true} variant={"contained"} color="secondary" onClick={this.handleSubmit}>Sign Up</Button>

        </Paper>;
    }
}

Signup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Signup));