import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


// Store
import storeComponentWrapper from '../../stores/jobDispatcher';

// Components
import UserInfo from '../../components/users/UserInfo';

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    fadingComponents: {
        transition: '.5s'
    },
});

class LoginPage extends React.Component {
    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xs={12}>
                    {<UserInfo/>}
                </Grid>
            </Grid>
        </div>);
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    match: PropTypes.object,
    jobSearch: PropTypes.object
};

export default storeComponentWrapper(withStyles(styles)(LoginPage));