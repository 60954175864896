import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";

class PrecomputedFetch {

    static run(queue, start, end){

        const endpoint = API_ENDPOINTS.precomputedJob;

        let endpoint_URI = endpoint.uri.clone();

        endpoint_URI.addQuery("q", queue);
        endpoint_URI.addQuery("start", start);
        endpoint_URI.addQuery("end", end);

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default PrecomputedFetch;