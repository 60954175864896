import protein from './pictures/protein.png';
import complex from './pictures/complex.png';
import families from './pictures/families.png';
import mutation from './pictures/mutation.png';
import rna from './pictures/rna.png';
import disorder from './pictures/disorder.png';

/*
 *   EXAMPLE
 *
 *
   {
        title: "",
        description: "",
        link: "", // external
        route: "", // internal
        updated: true, // optional
        pic:
    },

 */


export const webservers = [
    {
        title: "SARS-CoV-2",
        description: "3D structure and mutation effect prediction for SARS-CoV-2 proteins and RNAs.",
        link: "https://marks.hms.harvard.edu/sars-cov-2/",
        updated: true,
        pic: protein
    },
    {
        title: "EVcouplings server",
        description: "Compute evolutionary couplings from sequence alignments and predict 3D structure for your protein of interest. " +
            "This webserver allows to run former EVcouplings, EVmutation, EVfold and EVcomplex jobs.",
        route: "/choice",
        pic: protein
    },
    {
        title: "Models",
        description: "Precomputed evolutionary couplings and 3D models for thousands of experimentally unsolved proteins.",
        route: "/precomputed",
        pic: families
    },
    {
        title: "EVcomplex submissions",
        description: "Predict interacting residues in protein complexes from sequence covariation for your complex of interest.",
        route: "/complex",
        pic: complex
    },
    {
        title: "3Dseq",
        description: "Data for in-vitro experimental evolution.",
        route: "/3Dseq",
        pic: families
    },
    {
        title: "EVmutation models",
        description: "Context-dependent mutation landscapes predicted for thousands of human proteins.",
        link: "https://marks.hms.harvard.edu/evmutation/",
        pic: mutation
    },
    {
        title: "Protein plasticity",
        description: "Coevolutionary prediction of conformations of disordered regions in the human proteome.",
        link: "https://marks.hms.harvard.edu/disorder/",
        pic: disorder
    },
    {
        title: "RNA structure",
        description: "3D structure of RNA molecules and protein-RNA interactions predicted from nucleotide sequence covariation.",
        link: "https://marks.hms.harvard.edu/ev_rna/",
        pic: rna
    },
];

export const software = [
    {
        title: "EVcouplings",
        description: "Application and Python package providing the complete EVcouplings workflow from alignment to structure. Includes: EVcouplings, EVfold, EVmutation and EVcomplex.",
        link: "https://github.com/debbiemarkslab/EVcouplings",
    },
    {
        title: "EVzoom",
        description: "Tool to interactively visualize the parameters of undirected graphical models of protein families.",
        link: "https://debbiemarkslab.github.io/EVzoom",
    },
    {
        title: "plmc",
        description: "Software to infer undirected pairwise graphical models from multiple sequence alignments.",
        link: "https://github.com/debbiemarkslab/plmc",
    },
    {
        title: "EVmutation (legacy)",
        description: "Python module to predict mutation effects from undirected graphical models of sequences.",
        link: "https://github.com/debbiemarkslab/EVmutation",
    },
];