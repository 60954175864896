import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import storeComponentWrapper from '../../stores/jobDispatcher';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';

import Info from '@material-ui/icons/Info';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Error from '@material-ui/icons/ErrorOutline';
import PlaylistAndAcheck from '@material-ui/icons/PlaylistAddCheck';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const styles = theme => ({
    grayAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: '#aeaeae'
    },
    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: green[500],
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: orange[500],
    },
});

export const type = {
    warning: 0,
    positive: 1,
    negative: 2,
    neutral: 3
};

export const avatar = {
    info: 0,
    check: 1,
    cross: 2,
    warning: 3,
    options: 4,
};

class AvatarMessage extends React.Component {

    state = {
      collapsed: false
    };

    render() {
        const { classes } = this.props;

        let color = classes.grayAvatar;
        let avatarIcon = <Info />;

        switch(this.props.type){
            case type.warning:
                color = classes.orangeAvatar;
                break;
            case type.negative:
                color = classes.redAvatar;
                break;
            case type.positive:
                color = classes.greenAvatar;
                break;
            case type.neutral:
            default:
                color = classes.grayAvatar;
                break;
        }

        switch(this.props.avatar){
            case avatar.check:
                avatarIcon = <Check />;
                break;
            case avatar.cross:
                avatarIcon = <Close />;
                break;
            case avatar.warning:
                avatarIcon = <Error />;
                break;
            case avatar.options:
                avatarIcon = <PlaylistAndAcheck />;
                break;
            case avatar.info:
            default:
                avatarIcon = <Info />;
                break;
        }

        return (<Paper square elevation={2} >
            <CardHeader
                avatar={
                    <Avatar className={color}>
                        {avatarIcon}
                    </Avatar>
                }
                subheader={this.props.message}
            />
            {this.props.expand &&
            <ExpansionPanel onChange={() => this.setState({collapsed: !this.state.collapsed})} expanded={this.state.collapsed && this.props.expansionDisabled !== true} disabled={this.props.expansionDisabled === true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{this.props.expandedHeading}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {this.props.expansionDisabled !== true && this.props.expandedContent}
                </ExpansionPanelDetails>
            </ExpansionPanel>}
        </Paper>)
    }
}


AvatarMessage.propTypes = {
    avatar: PropTypes.number,
    classes: PropTypes.object.isRequired,
    expand: PropTypes.bool,
    expandedContent: PropTypes.object,
    expandedHeading: PropTypes.string,
    expansionDisabled: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.number,
};

export default storeComponentWrapper(withStyles(styles)(AvatarMessage));