import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {options as components} from '../../static/stages';
import ScrollableAnchor from 'react-scrollable-anchor';
import OptionDescription from '../../components/paperElements/OptionDescription';

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    link: {
        textDecoration: "none"
    }
});


class ResultsFAQ extends React.Component {
    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xl={3} md={3} xs={12}>
                    <Paper square elevation={2} className={classes.paper}  >
                        <Typography variant="caption">
                            Go to:
                        </Typography>
                        <List>
                            {components.map(component =>
                                <a className={classes.link} key={component.identifier} href={"#" + component.identifier}>
                                    <ListItem button>
                                        <ListItemText primary={component.title} />
                                    </ListItem>
                                </a>
                            )}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xl={9} md={9} xs={12}>
                    <Grid spacing={16} container>
                        {components.map(component =>
                            <Grid key={component.identifier} item xs={12}>
                                <ScrollableAnchor id={component.identifier}>
                                    <Paper square elevation={2} className={classes.paper} >
                                        <OptionDescription component={component} />
                                    </Paper>
                                </ScrollableAnchor>
                            </Grid>)}
                    </Grid>
                </Grid>
            </Grid>
        </div>);
    }
}

ResultsFAQ.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ResultsFAQ);