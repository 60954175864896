import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import storeComponentWrapper from '../../stores/jobDispatcher';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Right from '@material-ui/icons/KeyboardArrowRight';
import {Link} from 'react-router-dom';


const styles = theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: 1,
        paddingRight: 10,
        color: "inherit"
    },
    a: {
        textDecoration: "none",
        color: "inherit",
    },
    typography: {
        color: theme.palette.secondary.contrastText,
    },
    paper: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
    }
});


class ActionButton extends React.Component {

    render() {
        const { classes } = this.props;

        return (<Paper square className={classes.paper}>
            <Link className={classes.a} to={this.props.href}>
                <div className={classes.container}>
                    <Typography variant="body1" className={classes.typography}>
                        {this.props.text}
                    </Typography>
                    <Right/>
                </div>
            </Link>
        </Paper>)
    }
}


ActionButton.propTypes = {
    classes: PropTypes.object.isRequired,
    href: PropTypes.string,
    text: PropTypes.string
};

export default storeComponentWrapper(withStyles(styles)(ActionButton));