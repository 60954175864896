import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import FilesList from '../../../brokers/runs/download/FilesList';
import RunMetadata, {METADATA_KEYS} from '../../../brokers/runs/info/RunMetadata';
import RunResultsFetcher, {RESULTS_KEYS} from '../../../brokers/runs/results/RunResults';
import RunInfo from '../../../brokers/runs/info/RunInfo';
import DownloadFile from '../../../brokers/runs/download/DownloadFile';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import { capitalize } from "../../../utilities/StringUtilities";
import URI from 'urijs';
import delay from "../../../utilities/ActionDelayer";
import {withRouter} from "react-router-dom";
import {ALIGNMENT_VIEWER_ENDPOINTS, API_ENDPOINTS, EVZOOM_ENDPOINTS} from "../../../brokers/global";
import {files as fileNames} from "../../../brokers/runs/download/DownloadFile";
import InfoIcon from '@material-ui/icons/Info';
import {Link} from 'react-router-dom';
import StudioDispatcher from "../../../stores/studioDispatcher";
import {RemoteRunStore} from "../../../stores/studio/Studio";

import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";

import Align from '@material-ui/icons/FormatAlignLeft';
import Archive from '@material-ui/icons/Archive';
import Table from '@material-ui/icons/TableChart';
import Figure from '@material-ui/icons/Gradient';

const styles = theme => ({
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    noFlex: {
        display: "inherit"
    },
    infoIcon: {
        width: 17,
        color: "white",
        height: "inherit"
    },
    list: {
        width: "90%"
    },
    avatarInfo: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    avatarDownload: {

    },
    avatarExtra: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    good: {
        backgroundColor: green[300],
        color: "white"
    },
    medium: {
        backgroundColor: orange[300],
        color: "white"
    },
    bad: {
        backgroundColor: red[300],
        color: "white"
    },

});

class RunResults extends React.Component {

    state = {
        files: {},
        metadata: {},
        info: {},
        PDBRanking: null
    };

    fetchFileList = () => {
        if(this.props.refresh === true) {
            FilesList.run(this.props.runHash)
                .then(groups => {
                    this.setState({files: {...groups}});
                    delay("GET_RUN_FILE_LIST_" + this.props.runHash, this.fetchFileList, this.props.refreshRate);

                    // Check if results has ranking file, if so: get PDB ranking
                    if(Object.keys(this.state.files).indexOf(fileNames.foldingRankingFile) !== -1){
                        this.fetchPDBRanking()
                    }
                })
                .catch(error => console.error(error));
        }
    };

    fetchInfo = () => {
        // This only runs once, and in case again if there's an error. In theory: this should give the info of the run
        // right away as this is not bound to execution
        if(this.state.info.config === undefined){
            RunInfo.run(this.props.runHash,[
                METADATA_KEYS.numSequences,
                METADATA_KEYS.effectiveSequences
            ])
                .then(groups => {
                    this.setState({info: {...groups[0]}});
                })
                .catch(error => {
                    console.error(error);
                    delay("GET_RUN_INFO_CONFIG_" + this.props.runHash, this.fetchInfo, this.props.refreshRate);
                });
        }
    };

    addToStudio = () => {
        // Todo dynamically chose fileType

        let fileType = null;

        if(this.state.files[fileNames.ECComparedAllFile] !== null && this.state.files[fileNames.ECComparedAllFile] !== undefined){
            fileType = fileNames.ECComparedAllFile;
        } else if(this.state.files[fileNames.ecFile] !== null && this.state.files[fileNames.ecFile] !== undefined){
            fileType = fileNames.ecFile
        } else {
            console.error("cannot produce 2D/3D comparision");
        }

        this.props.studioAction({
            type: "ADD_REMOTE_RUN_STUDIO",
            payload: {
                run: new RemoteRunStore(this.props.runHash, fileType)
            }
        });

        let { history } = this.props;
        history.push("/studio");
    };

    fetchMetadata = () => {
        if(this.props.refresh === true) {
            RunMetadata.run(this.props.runHash,[
                METADATA_KEYS.numSequences,
                METADATA_KEYS.effectiveSequences
            ])
                .then(groups => {
                    this.setState({metadata: {...groups}});
                    delay("GET_RUN_METADATA_LIST_" + this.props.runHash, this.fetchMetadata, this.props.refreshRate);
                })
                .catch(error => console.error(error));
        }
    };

    fetchPDBRanking = () => {
        if(this.state.PDBRanking === null){
            RunResultsFetcher.run(this.props.runHash, RESULTS_KEYS.predictedPDBRanking)
                .then(ranking => {
                    this.setState({PDBRanking: ranking});
                })
                .catch(error => console.error(error));
        }
    };

    componentDidMount(){
        this.fetchFileList();
        this.fetchMetadata();
        this.fetchInfo();
    }

    // TODO: this will get fired when parent refreshes + re-enqueues delayed actions --> produces a ton of requests.
    componentWillReceiveProps(newProps){
        this.props = newProps;

        this.fetchFileList();
        this.fetchMetadata();
        this.fetchInfo();
    }

    render() {
        const {classes, runHash} = this.props;

        let qualityIndex;

        if(this.state.metadata[METADATA_KEYS.effectiveSequences] !== undefined && this.state.info.config !== undefined ){
            if(this.state.info.config.align_1 !== undefined && this.state.info.config.align_2 !== undefined){
                // It's a complex!
                let reg_1 = this.state.info.config.align_1.region[1] - this.state.info.config.align_1.region[0];
                let reg_2 = this.state.info.config.align_2.region[1] - this.state.info.config.align_2.region[0];

                qualityIndex = (this.state.metadata[METADATA_KEYS.effectiveSequences] / (reg_1 + reg_2));
            } else if(this.state.info.config.global.region !== undefined){
                // It's a monomer

                qualityIndex = (this.state.metadata[METADATA_KEYS.effectiveSequences] / (this.state.info.config.global.region[1] - this.state.info.config.global.region[0]));
            }
        }

        let qualityIndicator = undefined;

        if (qualityIndex > 5) {
            qualityIndicator = (
                <Paper className={classnames(classes.paper, classes.good)}>
                    <Typography variant="body1" color="inherit">
                        This run has a very good ratio of effective sequences in alignment to protein length (n_eff/L={qualityIndex.toFixed(2)})
                        <Link to="/faq/results#quality" className={classes.link}>
                            <InfoIcon className={classes.infoIcon} />
                        </Link>
                    </Typography>
                </Paper>
            );
        } else if (qualityIndex > 1) {
            qualityIndicator = (
                <Paper className={classnames(classes.paper, classes.medium)}>
                    <Typography variant="body1" color="inherit">
                        This run has a good ratio of effective sequences in alignment to protein length (n_eff/L={qualityIndex.toFixed(2)})
                        <Link to="/faq/results#quality" className={classes.link}>
                            <InfoIcon className={classes.infoIcon} />
                        </Link>
                    </Typography>
                </Paper>
            );
        } else if (qualityIndex > 0){
            qualityIndicator = (
                <Paper className={classnames(classes.paper, classes.bad)}>
                    <Typography variant="body1" color="inherit">
                        This run has a discrete ratio of effective sequences in alignment to protein length (n_eff/L={qualityIndex.toFixed(2)})
                        <Link to="/faq/results#quality" className={classes.link}>
                            <InfoIcon className={classes.infoIcon} />
                        </Link>
                    </Typography>
                </Paper>
            );
        }

        // Alignment Files Options

        let alignmentFilesOptions = (
            <a
                href={(() => {
                    let alignmentLocation = API_ENDPOINTS.runDownload.uri.clone();


                    alignmentLocation.addQuery("f", fileNames.alignmentFile);
                    alignmentLocation.addQuery("q", runHash);

                    let url = ALIGNMENT_VIEWER_ENDPOINTS.alignmentViewer.uri.clone();

                    url.addQuery("url", encodeURI(alignmentLocation));

                    return url.toString();
                })()}
                className={classes.link}
                key={fileNames.alignmentFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarExtra}>
                            <Figure/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"View alignment in alignmentviewer.org"}
                    />

                </ListItem>
            </a>
        );

        let alignmentStatisticsFile = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("f", fileNames.alignmentStatisticsFile);
                    url.addQuery("q", runHash);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.alignmentStatisticsFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Alignment statistics table"}
                    />
                </ListItem>
            </Link>
        );

        let alignOptions = {
            [fileNames.alignmentStatisticsFile]: alignmentStatisticsFile,
            [fileNames.alignmentFile]: alignmentFilesOptions,
        };

        // Couplings Files Options

        let ECFileOptions = (
            <div  key={fileNames.ecFile}>
                <Link
                    to={(() => {
                        let url = new URI("/table");

                        url.addQuery("f", fileNames.ecFile);
                        url.addQuery("q", runHash);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarInfo}>
                                <Table/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"Raw evolutionary couplings (ECs) table"}
                        />
                    </ListItem>
                </Link>
                <Link
                    to={(() => {
                        let url = new URI("/ribbon");

                        url.addQuery("q", runHash);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View ECs on a circle"}
                        />
                    </ListItem>
                </Link>
                <Link
                    to={(() => {
                        let url = new URI("/contact");

                        url.addQuery("f", fileNames.ecFile);
                        url.addQuery("q", runHash);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View raw contact map"}
                        />
                    </ListItem>
                </Link>
            </div>
        );

        let evZoomOptions = (
            <a
                href={(() => {
                    let alignmentLocation = API_ENDPOINTS.runDownload.uri.clone();

                    alignmentLocation.addQuery("f", fileNames.evZoomFile);
                    alignmentLocation.addQuery("q", runHash);

                    let url = EVZOOM_ENDPOINTS.evzoom.uri.clone();

                    url.addQuery("data", encodeURI(alignmentLocation));

                    return url.toString();
                })()}
                className={classes.link}
                key={fileNames.evZoomFile}
            >
                <ListItem button>
                    <ListItemAvatar>
                        <Avatar className={classes.avatarExtra}>
                            <Figure/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"View in EVzoom.org"}
                    />
                </ListItem>
            </a>
        );

        let enrichmentFileOptions = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("f", fileNames.enrichmentFile);
                    url.addQuery("q", runHash);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.enrichmentFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"EC enrichment table"}
                    />
                </ListItem>
            </Link>
        );

        let couplingsOptions = {
            [fileNames.enrichmentFile]: enrichmentFileOptions,
            [fileNames.ecFile]: ECFileOptions,
            [fileNames.evZoomFile]: evZoomOptions,
        };

        // Compare file options

        let monomerContactsFileOptions = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("f", fileNames.monomerContactsFile);
                    url.addQuery("q", runHash);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.monomerContactsFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Known i-j distances table"}
                    />
                </ListItem>
            </Link>
        );

        let ECComparedAllFileOptions = (
            <div key={fileNames.ECComparedAllFile}>
                <Link
                    to={(() => {
                        let url = new URI("/contact");

                        url.addQuery("f", fileNames.ECComparedAllFile);
                        url.addQuery("q", runHash);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View contact map with known structures"}
                        />
                    </ListItem>
                </Link>
                <Link
                    to={(() => {
                        let url = new URI("/table");

                        url.addQuery("f", fileNames.ECComparedAllFile);
                        url.addQuery("q", runHash);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarInfo}>
                                <Table/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"ECs with known i-j distances table"}
                        />
                    </ListItem>
                </Link>
            </div>
        );

        // If remapped PDB files exists, EC compared all file must exist as well! So check just that.
        let remappedPDBFilesOptions = (
            <ListItem
                button
                onClick={this.addToStudio}
                key={fileNames.remappedPDBFiles}
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatarExtra}>
                        <Figure/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"View contact map and known pdb comparison (must select 'known' source)"}
                />
            </ListItem>
        );

        let compareOptions = {
            [fileNames.monomerContactsFile]: monomerContactsFileOptions,
            [fileNames.ECComparedAllFile]: ECComparedAllFileOptions,
            [fileNames.remappedPDBFiles]: remappedPDBFilesOptions
        };

        // Mutate options

        let mutationMatrixFileOptions = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("f", fileNames.mutationMatrixFile);
                    url.addQuery("q", runHash);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.mutationMatrixFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Mutation matrix table"}
                    />
                </ListItem>
            </Link>
        );

        let mutationMatrixPlotTypes = {
            pdf: {
                name: (model) => capitalize(model)  +" model plot (pdf download)"
            },
            html: {
                name: (model) => capitalize(model) +" model"
            }
        };

        let mutationMatrixPlotOptions = (
            <div key={fileNames.mutationMatrixPlots}>
                {this.state.files[fileNames.mutationMatrixPlots] && this.state.files[fileNames.mutationMatrixPlots].map(fileObject => {

                    // Expect filename to be: 206e554994f88ce78e49383a1664949b_b0.3_independent_model.html
                    let fileNameParts = fileObject.filename
                    // Get rid of id
                        .substr(33)
                        .split("_");

                    // Get rid of batch
                    if((/^b\d.\d/).test(fileNameParts[0]) || (/^e\d/).test(fileNameParts[0])) fileNameParts = fileNameParts.slice(1);

                    // Expect last pos in array to be "model.html"
                    let fileTypeParts = fileNameParts[fileNameParts.length-1].split(".");

                    // Expect position 2 in array to be "html"
                    let plotType = mutationMatrixPlotTypes[fileTypeParts[1]];

                    if(fileTypeParts[1] === "pdf"){
                        return <ListItem
                            key={fileObject._id}
                            onClick={() => {
                                let alignmentLocation = API_ENDPOINTS.runDownload.uri.clone();

                                alignmentLocation.addQuery("q", runHash);
                                alignmentLocation.addQuery("f", fileObject.filename);

                                DownloadFile.download(alignmentLocation);
                            }}
                            button
                        >
                            <ListItemAvatar>
                                <Avatar className={classes.avatarExtra}>
                                    <Figure/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={plotType.name(fileNameParts[0])}
                            />
                        </ListItem>
                    } else {
                        let downloadLocation = API_ENDPOINTS.runResults.uri.clone();

                        downloadLocation.addQuery("q", runHash);
                        downloadLocation.addQuery("f", fileObject.filename);
                        downloadLocation.addQuery("r", "html");

                        return <ListItem
                            key={fileObject._id}
                        >
                            <ListItemText
                                primary={plotType.name(fileNameParts[0])}
                            />
                            <object data={downloadLocation.toString()} width="100%" height="256px">
                                Your browser doesn’t support the object tag.
                            </object>
                        </ListItem>
                    }
                })}
            </div>
        );

        let mutateOptions = {
            [fileNames.mutationMatrixFile]: mutationMatrixFileOptions,
            [fileNames.mutationMatrixPlots]: mutationMatrixPlotOptions
        };

        // Archive File Option

        let archiveOptions = {
            [fileNames.archiveFile]: <ListItem
                key={fileNames.archiveFile}
                onClick={() => {
                    let alignmentLocation = API_ENDPOINTS.runDownload.uri.clone();

                    alignmentLocation.addQuery("q", runHash);
                    alignmentLocation.addQuery("f", fileNames.archiveFile);

                    DownloadFile.download(alignmentLocation);
                }}
                button
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatarExtra}>
                        <Archive/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Download archive"}
                />
            </ListItem>
        };

        // Fold File Option

        let foldingComparisonFile = (
            <Link
                to={(() => {
                    let url = new URI("/table");

                    url.addQuery("q", runHash);
                    url.addQuery("f", fileNames.foldingComparisonFile);

                    return url.path() + url.search();
                })()}
                className={classes.link}
                key={fileNames.foldingComparisonFile}
            >
                <ListItem
                    button
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatarInfo}>
                            <Table/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={"Folding assessment table"}
                    />
                </ListItem>
            </Link>
        );

        // If ranking file exists, predicted pdb structures exist and EC file exists
        let foldedStructuresOptions = (
            <ListItem
                button
                onClick={this.addToStudio}
                key={fileNames.foldingRankingFile}
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatarExtra}>
                        <Figure/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"View contact map and predicted pdb comparison"}
                />
            </ListItem>
        );

        let foldOptions = {
            [fileNames.foldingRankingFile]: foldedStructuresOptions,
            [fileNames.foldingComparisonFile]: foldingComparisonFile,
        };

        // Info

        let numSequencesOptions = (
            <ListItem
                key={METADATA_KEYS.numSequences}
            >
                <ListItemAvatar>
                    <Avatar>
                        <Align />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Number of sequences in alignment: " + parseInt(this.state.metadata[METADATA_KEYS.numSequences], 10)}
                />
            </ListItem>
        );

        let effectiveSequencesOptions = (
            <ListItem
                key={METADATA_KEYS.effectiveSequences}
            >
                <ListItemAvatar>
                    <Avatar>
                        <Align />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={"Number of effective sequences: " + parseInt(this.state.metadata[METADATA_KEYS.effectiveSequences], 10)}
                />
            </ListItem>
        );

        let metadataOptions = {
            [METADATA_KEYS.numSequences]: numSequencesOptions,
            [METADATA_KEYS.effectiveSequences]: effectiveSequencesOptions,
        };

        let topPredictedPDFFiles = this.state.PDBRanking &&
            this.state.PDBRanking.slice(0,1).map((PDBFile, index) => (
                <Link
                    to={(() => {
                        let url = new URI("/ngl");

                        url.addQuery("f", PDBFile.filename);
                        url.addQuery("q", runHash);

                        return url.path() + url.search();
                    })()}
                    className={classes.link}
                    key={PDBFile.filename}
                >
                    <ListItem
                        button
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatarExtra}>
                                <Figure />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={"View predicted structures"}
                        />
                    </ListItem>
                </Link>
            ));

        let fileOptions = {
            alignOptions: [],
            couplingsOptions: [],
            compareOptions: [],
            mutateOptions: [],
            foldOptions: [],
            archiveOptions: []
        };

        Object.keys(this.state.files).forEach(k => {
            if(alignOptions[k]){
                return fileOptions['alignOptions'].push(alignOptions[k]);
            } else if(couplingsOptions[k]){
                return fileOptions['couplingsOptions'].push(couplingsOptions[k]);
            } else if(compareOptions[k]){
                return fileOptions['compareOptions'].push(compareOptions[k]);
            } else if(mutateOptions[k]){
                return fileOptions['mutateOptions'].push(mutateOptions[k]);
            } else if(foldOptions[k]){
                return fileOptions['foldOptions'].push(foldOptions[k]);
            } else if(archiveOptions[k]){
                return fileOptions['archiveOptions'].push(archiveOptions[k]);
            }
        });

        return <div>
            {qualityIndicator}
            <Paper className={classes.paper}>
                <List subheader={<ListSubheader disableSticky={true}>Info</ListSubheader>}>
                    {Object.keys(this.state.metadata).map(k => {
                        return metadataOptions[k];
                    })}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Align stage results options</ListSubheader>}>
                    {fileOptions['alignOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Couplings stage results options</ListSubheader>}>
                    {fileOptions['couplingsOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Compare stage results options</ListSubheader>}>
                    {fileOptions['compareOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Mutate stage results options</ListSubheader>}>
                    {fileOptions['mutateOptions']}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Fold stage results options</ListSubheader>}>
                    {fileOptions['foldOptions']}

                    {topPredictedPDFFiles}
                </List>
                <List subheader={<ListSubheader disableSticky={true}>Archive file options</ListSubheader>}>
                    {fileOptions['archiveOptions']}
                </List>
            </Paper>
        </div>;
    }
}

RunResults.propTypes = {
    classes: PropTypes.object.isRequired,
    runHash: PropTypes.string,
    refreshRate: PropTypes.number.isRequired,
    refresh: PropTypes.bool
};

export default StudioDispatcher(withRouter(withStyles(styles)(RunResults)));