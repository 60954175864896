import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";
import {queryType} from "./PrecomputedDownload";

class PrecomputedResultsJSON {

    static run(queue, batch, start, end, file){

        const endpoint = API_ENDPOINTS.precomputedResultsInJSON;

        let endpoint_URI = endpoint.uri.clone();

        endpoint_URI.addQuery("q", queue);
        endpoint_URI.addQuery("start", start);
        endpoint_URI.addQuery("end", end);
        endpoint_URI.addQuery("b", batch);

        switch (file.type) {
            case queryType.alias:
                endpoint_URI.addQuery("a", file.query);
                break;
            case queryType.file:
                endpoint_URI.addQuery("f", file.query);
                break;
            default:
            // Do nothing
        }

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args[0]);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default PrecomputedResultsJSON;

export {queryType};