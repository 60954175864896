import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";

class PrecomputedDownload {

    static run(query, batch, start, end, file){

        const endpoint = API_ENDPOINTS.precomputedJobDownload;

        let endpoint_URI = endpoint.uri.clone();

        endpoint_URI.addQuery("q", query);
        endpoint_URI.addQuery("b", batch);
        endpoint_URI.addQuery("start", start);
        endpoint_URI.addQuery("end", end);

        switch (file.type) {
            case queryType.alias:
                endpoint_URI.addQuery("a", file.query);
                break;
            case queryType.file:
                endpoint_URI.addQuery("f", file.query);
                break;
            default:
                // Do nothing
        }


        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default PrecomputedDownload;

export const queryType = {
    file: "f",
    alias: "a"
};