import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';
import {getTitle, getOption} from "../../../static/options";
import storeComponentWrapper from '../../../stores/jobDispatcher';
import SimpleDialog from '../../dialog/SimpleDialog';
import OptionDescription from '../../paperElements/OptionDescription';
import delay from '../../../utilities/ActionDelayer';

const styles = theme => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridInput: {
        width: "100%"
    },
    badge: {
        padding: "0 .6em 0 0"
    },
    infoIcon: {
        width: 17,
        color: "#858787",
        cursor: "pointer",
        height: "inherit"
    }
});

class JobOptions extends React.Component {

    constructor(props){
        super(props);

        this.state = {...this.props.complexJobParameters.proteins[this.props.complexJobProteinNumber]};
    }

    handleChange = key => event => {
        let value = event.target.value;

        this.setState({
            [key]: value,
        });

        delay("UPDATE_INDIVIDUAL_COMPLEX_JOB_OPTIONS_" + this.props.complexJobProteinNumber + "_" + key, () => this.props.action({
            type: "SET_COMPLEX_JOB_PARAMETERS",
            payload: {
                [key]: value,
                forProtein: this.props.complexJobProteinNumber
            }
        }), 1500);

    };

    openOptionDialog = (optionName) => () => {
        this.setState({
            dialogObject: getOption(optionName)
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                {this.state.dialogObject && <SimpleDialog
                    component={<OptionDescription component={this.state.dialogObject}/>}
                    open={true}
                    dialogClosed={()=> this.setState({dialogObject: undefined})}
                    title={""}/>}
                <form className={classes.form}>
                    <Grid spacing={16} container>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Alignment parameters
                            </Typography>
                            <Grid spacing={16} container>
                                <Grid item xl={6} md={6} xs={12} >
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCalculationFunction")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCalculationFunction')}
                                    />
                                    <br />
                                    <FormControl>
                                        <RadioGroup
                                            value={this.state.distanceCalculationFunction}
                                            onChange={this.handleChange('distanceCalculationFunction')}
                                        >
                                            <FormControlLabel value="bitscore" control={<Radio />} label="Bitscore" />
                                            <FormControlLabel value="eval" control={<Radio />} label="E value" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                {this.state.distanceCalculationFunction === "bitscore" &&
                                <Grid item className={classes.gridInput} xl={6} md={6} xs={12}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCalculationFunctionBitscoreRange")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCalculationFunctionBitscoreRange')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.distanceCalculationFunctionBitscoreRange}
                                        onChange={this.handleChange('distanceCalculationFunctionBitscoreRange')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            max: 1,
                                            step: .1
                                        }}
                                        error={this.state.distanceCalculationFunctionBitscoreRange < 0 || this.state.distanceCalculationFunctionBitscoreRange > 1}
                                    />
                                </Grid>
                                }

                                {this.state.distanceCalculationFunction === "eval" &&
                                <Grid item className={classes.gridInput} xl={6} md={6} xs={12}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("distanceCalculationFunctionEValRange")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('distanceCalculationFunctionEValRange')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.distanceCalculationFunctionEValRange}
                                        onChange={this.handleChange('distanceCalculationFunctionEValRange')}
                                        inputProps={{
                                            type: "number",
                                            min: 0,
                                            step: .1
                                        }}
                                        error={this.state.distanceCalculationFunctionEValRange < 0}
                                    />
                                </Grid>
                                }

                                <Grid item xl={6} md={6} xs={12}>
                                    <FormLabel className={classes.pointer}>
                                        {getTitle("nOfIterations")}
                                    </FormLabel>
                                    <InfoIcon
                                        className={classes.infoIcon}
                                        onClick={this.openOptionDialog('nOfIterations')}
                                    />
                                    <br/>
                                    <Input
                                        value={this.state.nOfIterations}
                                        onChange={this.handleChange('nOfIterations')}
                                        inputProps={{
                                            type: "number",
                                            min: 1,
                                            max: 10,
                                            step: 1
                                        }}
                                        error={this.state.nOfIterations < 1 || this.state.nOfIterations > 10}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

JobOptions.propTypes = {
    action: PropTypes.func,
    classes: PropTypes.object.isRequired,
    complexJobParameters: PropTypes.object.isRequired,
    complexJobProteinNumber: PropTypes.number.isRequired
};

export default storeComponentWrapper(withStyles(styles)(JobOptions));
