/*  Full Example:


    {
        identifier: "",
        title: "",
        description: [""],
        references: [""],
        seeAlso:[],
        valueLimitations: "The value ranges between 0 and 1.",
        values: [{
            value: "",
            meaning: ""
        }]
    }

*/

export const options = [
    {
        identifier: "saveModel",
        title: "Save amino acid specific parameters",
        description: ["Save amino acid specific parameters to a binary file. This file can be downloaded for additional user-defined for mutation analysis. ",
            "These files contain J_ij and h_i parameters from (Hopf+Ingraham17).",
            "These files tend to be large."],
        references: ["https://www.nature.com/articles/nbt.3769"],
    }, {
        identifier: "theta",
        title: "Clustering threshold for downweighting redundant sequences",
        description: ["The clustering threshold theta is used to downweigth similar sequences during couplings calculation",
            "and during computation of the effective number of sequences (Meff).",
            "Sequences that are more than 100 * theta percent identical will be downweighted." ],
        valueLimitations: "The value ranges between 0 and 1.",
        values: [{
            value: 1,
            meaning: "A value of 1 downweights sequences which are 100% identical."
        }, {
            value: .8,
            meaning: "A value of 0.8 downweights sequences that are at least 80% identical \n" +
            "The default of 0.8 empirically works well for most sequence alignments."
        }],
        references: ["https://doi.org/10.1093/bioinformatics/btt211"]
    }, {
        identifier: "distanceCalculationFunction",
        title: "Type of sequence inclusion threshold",
        description: ["Chose E-value or bitscore the inclusion threshold for candidate sequences during alignment."],
        seeAlso: ["https://www.ncbi.nlm.nih.gov/BLAST/tutorial/Altschul-1.html"],
    },  {
        identifier: "distanceCalculationFunctionBitscoreRange",
        title: "Bitscore range",
        valueLimitations: "The value ranges between 0 and 1.",
        description: ["The range of threshold values to be tested.",
            "Typically, users should aim to construct the largest multiple sequence alignment possible, without introducing a large number of gaps in the sequences or alignment columns (be sure to compare the alignment_statistics.csv file to make sure this is the case).",
            "Smaller bitscores include more sequences in the alignment."],
    }, {
        identifier: "distanceCalculationFunctionEValRange",
        title: "E value range",
        valueLimitations: "The value needs to be bigger than 0.",
        description: ["The range of threshold values to be tested.",
            "Typically, users should aim to construct the largest multiple sequence alignment possible, without introducing a large number of gaps in the sequences or alignment columns (be sure to compare the alignment_statistics.csv file to make sure this is the case).",
            "Smaller input E values include more sequences for the alignment, because the value is interpreted as 10^-x, where x is the e value."],
    }, {
        identifier: "database",
        title: "Database",
        description: ["Database used to build the multiple sequence alignment."]
    }, {
        identifier: "columnThreshold",
        title: "Position gap threshold",
        valueLimitations: "The value ranges between 0 and 1.",
        description: ["Only include alignment positions (ie columns) with at least this fraction of residues, rather than gaps, during model inference.",
            "Inferring the couplings using columns with many gaps may lead to false positive evolutionary coupling scores."],
        values: [{
            value: .2,
            meaning: "A value of 0.2 will allow columns composed of up to 80% gaps."
        }, {
            value: .8,
            meaning: "A value of 0.8 will allow columns composed of up to 20% gaps."
        }]
    }, {
        identifier: "fragmentThreshold",
        title: "Sequence gap threshold",
        valueLimitations: "The value ranges between 0 and 1.",
        description: ["Only include sequences with at least this fraction residues (rather than gaps) during model inference.",
            "Sequences with many gaps may not have evolved from the same family as the reference sequence."],
        values: [{
            value: .2,
            meaning: "A value of 0.2 will allow sequences composed of up to 80% gaps."
        }, {
            value: .8,
            meaning: "A value of 0.8 will allow sequences composed of up to 20% gaps."
        }]
    }, {
        identifier: "nOfIterations",
        title: "Number of iterations",
        valueLimitations: "The value ranges between 1 and 10.",
        description:["The number of jackhmmer iterations used during alignment construction."]
    }, {
        identifier: "couplingsProtocol",
        title: "Couplings protocol",
        description:["Model inference protocol.",
        "Select Pseudo-likelihood maximization (plmDCA) or mean-field."],
        references:["https://doi.org/10.1103/PhysRevE.87.012707",
        "https://doi.org/10.1016/j.jcp.2014.07.024"],
        seeAlso:["https://en.wikipedia.org/wiki/Mean_field_theory"]
    }, {
        identifier: "plmcIterations",
        title: "Number of iterations for plmDCA",
        valueLimitations: "Integer greater than 0.",
        description:[""]
    }, {
        identifier: "compareSearchBy",
        title: "Comparison search",
        description:["Choose to search the PDB database for homologous structures using either jackhmmer from the query sequence (less senstivie) or hmmsearch from the constructed alignment (more sensitive)"]
    }, {
        identifier: "compareBitscore",
        title: "Bitscore",
        valueLimitations: "Number greater than 0.",
        description:["Sequence inclusion threshold for comparison search against the PDB"]
    }, {
        identifier: "nOfStructures",
        title: "Number of structures",
        valueLimitations: "The value ranges between 1 and 100.",
        description:["Limit number of PDB structures comparison."]
    }, {
        identifier: "nOfHits",
        title: "Number of hits",
        valueLimitations: "The value ranges between the number of structures and 100.",
        description:["Limit number of PDB chains for comparison. A single structure may have multiple PDB chains"]
    }, {
        identifier: "atomFilter",
        title: "Atom filter",
        description:["Compute C_alpha or minimum atom distances."],
        seeAlso:["https://en.wikipedia.org/wiki/Protein_contact_map"]
    }, {
        identifier: "distanceCutOff",
        title: "Distance cutoff",
        valueLimitations: "The value ranges between 1 and 15.",
        description:["Distance cutoff in Angstroms for a true positive residue contact during comparison to existing structures."]
    }, {
        identifier: "foldHighest",
        title: "Highest fold",
        valueLimitations: "The value ranges between 0 and 3.",
        description:["Create folded model structures using up to this maximum number of ECs.",
            "Integers will be interpreted as absolute number of ECs, floats as a fraction of L (model length)."]
    }, {
        identifier: "foldLowest",
        title: "Lowest fold",
        valueLimitations: "The value ranges between 0 and 3.",
        description:["Create folded model structures starting from this minimum number of ECs.",
            "Integers will be interpreted as absolute number of ECs, floats as a fraction of L (model length)."]
    }, {
        identifier: "foldIncrease",
        title: "Fold increase",
        valueLimitations: "The value ranges between 0 and 2.",
        description:["Increase in number of ECs to use for each folded model, between the lowest fold and highest fold values.",
            "Integers will be interpreted as absolute number of ECs, floats as a fraction of L (model length)."]
    }, {
        identifier: "concatenateProtocol",
        title: "Concatenate protocol",
        description:["Concatenation protocol.",
            "Select genome_distance to pair sequences that are closest neighbors on the genome.",
            "Select best_hit to, for each genome, pair the sequences that have the highest % identity to the target sequence."
        ],
    }, {
        identifier: "genomeDistanceThreshold",
        title: "Genome Distance Threshold",
        description:[
            "Maximum distance in bases allowed between pairs of proteins to be concatenated. " +
            "If there are multiple hits within the distance threshold, the closest hit will be selected.",
            "Required for genome_distance protocol only."
        ],
        values: [{
            value: 0,
            meaning: "Genes within 0 base pairs on the genome will be paired - ie, only continuous genes. (Not recommended)"
        }, {
            value: 100000,
            meaning: "Genes within 100,000 base pairs on the genome will be paired in concatenation."
        }]
    }, {
        identifier: "useBestReciprocal",
        title: "Use Best Reciprocal",
        description:["For best hit protocol, user can set this to yes to take the best reciprocal hits only (recommended)"],
        values: [{
            value: "Yes",
            meaning: "Take the best hit per genome if and only if that hit would return the query sequence when searched against the query genome."
        }, {
            value: "No",
            meaning: "Take the best hit per genome without analyzing reciprocal hits."
        }]
    },
];

export const getTitle = (identifier) => {
    return options.find(e => e.identifier === identifier).title;
};

export const getShortDescription = (identifier) => {
    const STOP_AT = 70;
    let result = "";
    let candidate = options.find(e => e.identifier === identifier).description[0];

    result += (candidate.length > STOP_AT ? candidate.substring(0, candidate.indexOf(" ", STOP_AT)) + "..." : candidate.substring(0,STOP_AT));
    result += " [click for more]";

    return result;
};

export const getOption = (identifier) => {
    return options.find(e => e.identifier === identifier);
};
