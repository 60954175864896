import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        paddingBottom: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 20,
        paddingBottom: 20
    }),
    card: {
        position: "relative"
    },
    new: {
        position: "absolute",
        top: 5,
        right: 5,
        background: theme.palette.primary.main,
        borderRadius: "5%",
        padding: 3,
        textAlign: "center",
    },
    newText: {
        color: theme.palette.primary.contrastText,
    },
    cardContent: {
        height: 100,
    },
    link: {
        textDecoration: "none"
    },
});


class PrecomputedListPage extends React.Component {

    render(){
        const { classes } = this.props;

        return (<div className={classes.content}>

            <Grid spacing={16} container>
                <Grid xs={12} item style={{textAlign: "center"}}>
                    <Typography component={"div"} variant="h5">
                        Curated list of precomputed runs
                    </Typography>
                    <Typography component={"div"} variant="body1">
                        This list is coming soon...
                    </Typography>
                </Grid>

                <Grid xs={12} item>
                    {/*List here*/}
                </Grid>
            </Grid>
        </div>);
    }
}

PrecomputedListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PrecomputedListPage);