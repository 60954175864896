import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        paddingBottom: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 20,
        paddingBottom: 20
    }),
    card: {
        position: "relative"
    },
    new: {
        position: "absolute",
        top: 5,
        right: 5,
        background: theme.palette.primary.main,
        borderRadius: "5%",
        padding: 3,
        textAlign: "center",
    },
    newText: {
        color: theme.palette.primary.contrastText,
    },
    cardContent: {
        height: 100,
    },
    link: {
        textDecoration: "none"
    },
});


class PrecomputedChoicePage extends React.Component {

    render(){
        const { classes } = this.props;

        return (<div className={classes.content}>

            <Grid spacing={16} container>
                <Grid xs={12} item style={{textAlign: "center"}}>
                    <Typography component={"div"} variant="h5">
                        Chose the precomputed jobs you want to see
                    </Typography>
                    <Typography component={"div"} variant="body1">
                        You can search for all precomputed jobs from our lab, open a curated list of jobs or search for CASP targets.
                    </Typography>
                </Grid>

                <Grid xl={6} md={6} xs={12} item>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {"Curated list of precomputed jobs"}
                            </Typography>
                            <Typography component="p">
                                {"View a list of curated, precomputed jobs"}
                            </Typography>
                        </CardContent>
                        {/* TODO: calculate the precomputed list on the backend, display here. @KB */}
                        {/*<Link to={'/precomputed/list'} className={classes.link}>*/}
                        {/*</Link>*/}
                        <a href={'https://marks.hms.harvard.edu/evcouplings/models/'} target={"_blank"} className={classes.link}>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Open
                                </Button>
                            </CardActions>
                        </a>
                    </Card>
                </Grid>

                <Grid xl={6} md={6} xs={12} item>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {"All precomputed jobs"}
                            </Typography>
                            <Typography component="p">
                                {"Search all precomputed jobs (excluding CASP targets)."}
                            </Typography>
                        </CardContent>

                        <Link to={'/precomputed/search'} className={classes.link}>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Go
                                </Button>
                            </CardActions>
                        </Link>
                    </Card>
                </Grid>

                {/*<Grid xl={4} md={4} xs={12} item>*/}
                    {/*<Card className={classes.card}>*/}
                        {/*<CardContent className={classes.cardContent}>*/}
                            {/*<Typography gutterBottom variant="h5" component="h2">*/}
                                {/*{"CASP targets"}*/}
                            {/*</Typography>*/}
                            {/*<Typography component="p">*/}
                                {/*{"Precomputed runs on CASP 11 and 12 targets."}*/}
                            {/*</Typography>*/}
                        {/*</CardContent>*/}

                        {/*<Link to={'/precomputed/casp'} className={classes.link}>*/}
                            {/*<CardActions>*/}
                                {/*<Button size="small" color="primary">*/}
                                    {/*Go*/}
                                {/*</Button>*/}
                            {/*</CardActions>*/}
                        {/*</Link>*/}
                    {/*</Card>*/}
                {/*</Grid>*/}

            </Grid>
        </div>);
    }
}

PrecomputedChoicePage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PrecomputedChoicePage);