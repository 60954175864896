import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../../global";
import jQuery from "jquery";

class RunMetadata {

    static run(hash, keys){

        const endpoint = API_ENDPOINTS.runMetadata;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);

        keys.forEach(k => {
            endpoint_URI.addQuery("k", k);
        });

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    return resolve(args[0]);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default RunMetadata;

export const METADATA_KEYS = {
    numSequences: "num_sequences",
    effectiveSequences: "effective_sequences",
};