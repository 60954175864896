let initial = {
    remoteRuns: [],
    localRuns: []
};

class LocalRunStore {
    constructor(uniprotID, batch, start, end, filename){
        this.uniprotID = uniprotID;
        this.start = start;
        this.end = end;
        this.batch = batch;
        this.filename = filename;
    }

    equals(localRun){
        return (
            localRun.uniprotID !== undefined && localRun.uniprotID   === this.uniprotID &&
            localRun.start !== undefined     && localRun.start       === this.start &&
            localRun.end !== undefined       && localRun.end         === this.end &&
            localRun.batch !== undefined     && localRun.batch       === this.batch &&
            localRun.filename !== undefined     && localRun.filename       === this.filename
        );
    }
}

class RemoteRunStore {
    constructor(runHash, filename){
        this.runHash = runHash;
        this.filename = filename;
    }

    equals(remoteRun){
        return (
            remoteRun.runHash !== undefined  && remoteRun.runHash    === this.runHash  &&
            remoteRun.filename !== undefined  && remoteRun.filename    === this.filename

        );
    }
}

let localStudio = localStorage.getItem('studio');

if(localStudio !== null) {
    try {
        let tmp = JSON.parse(localStudio);

        tmp.remoteRuns = tmp.remoteRuns.map(e => Object.assign(new RemoteRunStore(), e));
        tmp.localRuns = tmp.localRuns.map(e => Object.assign(new LocalRunStore(), e));

        initial = tmp;
    } catch (e) {
        console.error("Error reading localStorage, wiping out...");
        console.error(e);

        localStorage.setItem('studio', JSON.stringify(initial));
    }
}

/*
TODO 1: Make the state independent of the current session BUT dependent on local storage
TODO 2: Collapse ADD cases into one single case that infers the type of the new addition
TODO 3: Makre removal more efficient (many ways to do so...)
 */

function Studio(state = initial, action) {
    let newState = {
        ...state,
    };

    let newRun;

    switch (action.type) {
        case 'ADD_REMOTE_RUN_STUDIO':
            newRun = action.payload.run;

            if(newState.remoteRuns.filter(remoteRun => newRun.equals(remoteRun)).length > 0){
                return newState;

            } else {
                newState.remoteRuns = [...newState.remoteRuns.concat(newRun)];

                localStorage.setItem('studio', JSON.stringify(newState));

                return newState;
            }

        case 'ADD_LOCAL_RUN_STUDIO':
            newRun = action.payload.run;

            if(newState.localRuns.filter(localRun => newRun.equals(localRun)).length > 0){
                return newState;

            } else {
                newState.localRuns = [...newState.localRuns.concat(newRun)];

                localStorage.setItem('studio', JSON.stringify(newState));

                return newState;
            }

        case 'REMOVE_RUN_STUDIO':
            newRun = action.payload.run;

            for(let i=0; i < newState.remoteRuns.length; i++){
                if(newState.remoteRuns[i].equals(newRun)){
                    newState.remoteRuns.splice(i, 1);
                    localStorage.setItem('studio', JSON.stringify(newState));
                    break;
                }
            }

            for(let i=0; i < newState.localRuns.length; i++){
                if(newState.localRuns[i].equals(newRun)){
                    newState.localRuns.splice(i, 1);
                    localStorage.setItem('studio', JSON.stringify(newState));
                    break;
                }
            }

            return newState;

        case 'LOAD_STORED_STATE':
            return action.storedState.studio;

        default:
            return newState;
    }
}

export default Studio;

export {LocalRunStore, RemoteRunStore};