import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import storeComponentWrapper from '../../stores/jobDispatcher';
import UserJobsBroker from "../../brokers/users/UserJobsBroker";
import JobInfoNugget from "../../components/jobs/info/JobInfoNugget";
import ActionButtonElement from '../../components/paperElements/ActionButton';
import Typography from "@material-ui/core/Typography/Typography";
import UserInfo from "../../components/users/UserInfo";


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    fadingComponents: {
        transition: '.5s'
    },
});

class UserJobsPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            jobs: []
        };
    }

    componentDidMount() {
        UserJobsBroker.run()
            .then(results => {
                this.setState({
                    jobs: results[0]
                })
            });
    }

    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xs={12}>
                    {<UserInfo/>}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component={"div"}>
                        {"Your jobs"}
                    </Typography>
                </Grid>
                {this.state.jobs.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map(job => (
                        <Grid key={job.jobHash} item xs={12}>
                            <JobInfoNugget jobHash={job.jobHash}/>
                            <ActionButtonElement href={"/jobs/" + job.jobHash}
                                                 text={"Click here to see the job status and explore results"}/>
                        </Grid>
                    )
                )}
            </Grid>
        </div>);
    }
}

UserJobsPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    match: PropTypes.object,
    jobSearch: PropTypes.object
};

export default storeComponentWrapper(withStyles(styles)(UserJobsPage));