import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../../global";
import jQuery from "jquery";
import {proteinStatus} from "../../../stores/job/JobParameters";
import {jobParametersSerializer} from "../utils/jobParametersSerializer";

class JobSubmission {
    static run(data) {
        const endpoint = API_ENDPOINTS.jobSubmission;

        if(data.proteinStatus === proteinStatus.INVALID ||
            data.proteinStatus === proteinStatus.NULL ||
            data.proteinStatus === proteinStatus.LOADING){
            return new Promise((_, reject) => {reject("No valid protein states")});
        }

        let payload = jobParametersSerializer(data);

        return new Promise((resolve,reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint.uri,
                timeout: REQUEST_TIMEOUT,
                xhrFields: { withCredentials: true },
                data: JSON.stringify(payload),
                success: (...args) => {
                    resolve(...args);
                },
                dataType: "json",
                error: (...args) => {
                    reject(...args)
                },
            })
        })
    }
}


export default JobSubmission;