const status = {
    INVALID: 0,
    LOADING: 2,
    NULL: 3,

    // Protein input type
    MULTIPLESEQUENCES: 4,
    FASTA: 5,
    AA: 6,
    UNIPROT: 7,

    isValid: (incomingStatus) => {
        return incomingStatus === status.UNIPROT || incomingStatus === status.AA || incomingStatus === status.FASTA ||
            incomingStatus === status.MULTIPLESEQUENCES
    },
    needsIndexing: (incomingStatus) => {
        return incomingStatus === status.AA || incomingStatus === status.FASTA || incomingStatus === status.MULTIPLESEQUENCES
    },
};

const initial = {
    // sequenceInput component
    proteinStatus: status.NULL,
    protein: undefined,
    // jobOptions component
    theta: 0.8,
    saveModel: "no",
    distanceCalculationFunction: "bitscore",
    distanceCalculationFunctionBitscoreRange: [0.2, 0.3, 0.4],
    distanceCalculationFunctionEValRange: [2, 5, 10],
    columnThreshold: 0.5,
    fragmentThreshold: 0.7,
    nOfIterations: 5,
    database: "uniref90",
    couplingsProtocol: "standard",
    plmcIterations: 100,
    compareSearchBy: "jackhmmer",
    compareBitscore: 0.3,
    nOfStructures: 10,
    nOfHits: 25,
    distanceCutOff: 5,
    atomFilter: "closest_atom",
    foldHighest: 1.5,
    foldLowest: 0.3,
    foldIncrease: 0.1,
    // startingPositionElement component
    startingPosition: 1,
    // SubmitJob component
    name: "",
    email: "",
    // RegionSelection component
    regionStart: undefined,
    regionEnd: undefined
};


function JobParameters(state = initial, action) {
    switch (action.type) {
        case 'SET_PROTEIN_STATUS':
            return {
                ...state,
                proteinStatus: action.payload.proteinStatus
            };
        case 'SET_PROTEIN_OBJECT':
            return {
                ...state,
                protein: action.payload.protein
            };
        case 'SET_JOB_PARAMETERS':
            return {
                ...state,
                ...action.payload
            };
        case 'RESET_JOB_SUBMISSION':
            return initial;

        default:
            return state;
    }
}

export default JobParameters;

export const proteinStatus = status;