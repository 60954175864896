import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { evcouplings, UserSection, markslab } from './drawerElements';

import ev from './ev.png';

// CHANGE IN Navigation.js TOO
const DRAWERWIDTH = 250;

const styles = theme => ({
    drawerInner: {
        // Make the items inside not wrap when transitioning:
        width: DRAWERWIDTH
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    caption: {
        marginLeft: 10,
        marginTop: 10
    }
});

class DrawerElement extends React.Component {
    state = {
        open: false,
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.drawerState
        });
    }

    handleDrawer = (open) => () => {
        this.props.onDrawerChange(open);
    };

    render() {
        const { classes, theme } = this.props;

        return (
            <Drawer
                open={this.state.open}
                onClose={this.handleDrawer(false)}
                onOpen={this.handleDrawer(true)}
            >
                <div className={classes.drawerInner} onClick={this.handleDrawer(false)}>
                    <div className={classes.drawerHeader}>
                        <IconButton>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                        <Typography className="pointer" variant="body2">Close</Typography>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <img alt="Evcouplings small logo" width="96" height="48" src={ev}/>
                    </div>
                    <List className={classes.list}>{markslab}</List>
                    <Divider />
                    <Typography className={classes.caption} variant="caption">Jobs</Typography>
                    <List className={classes.list}>{evcouplings}</List>
                    <Divider />
                    <Typography className={classes.caption} variant="caption">User</Typography>
                    {this.state.open && <List className={classes.list}><UserSection /></List>}
                </div>
            </Drawer>
        );
    }
}

DrawerElement.propTypes = {
    classes: PropTypes.object.isRequired,
    drawerState: PropTypes.bool,
    onDrawerChange: PropTypes.func,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DrawerElement);