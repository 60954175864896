import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Panel from "../../components/studio/Panel";
import StudioDispatcher from '../../stores/studioDispatcher';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
    link: {
        textDecoration: "none"
    },
});


class Studio extends React.Component {

    render(){
        const { classes, studio } = this.props;
        const { remoteRuns, localRuns } = studio;

        return (<div className={classes.content}>
            <Grid spacing={16} container>
                {remoteRuns.map(run => <Panel key={run.runHash + run.filename} run={run} />)}
                {localRuns.map(run => <Panel key={run.uniprotID + run.batch + run.start + "-" +  run.end + run.filename} run={run} />)}

                {remoteRuns.length < 1 && localRuns.length < 1 && <Grid xs={12} item>
                    Please, select at least one run from your jobs.
                </Grid>}
            </Grid>
        </div>);
    }
}

Studio.propTypes = {
    classes: PropTypes.object.isRequired
};

export default StudioDispatcher(withStyles(styles)(Studio));