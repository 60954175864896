import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../global";
import jQuery from "jquery";

class SequencesBroker {

    /**
     *
     * @param options JSON object containing (opt) format, (opt) sequenceMD5, (opt) regionMD5, (opt) jobHash
     * @returns {Promise<any>}
     */
    static run(options){

        const endpoint = API_ENDPOINTS.sequences;

        let endpoint_URI = endpoint.uri.clone();

        if(options.format){
            endpoint_URI.addQuery("f", options.format);
        }

        if(options.jobHash){
            endpoint_URI.addQuery("j", options.jobHash);
        }

        if(options.sequenceMD5){
            endpoint_URI.addQuery("s", options.sequenceMD5);
        }

        if(options.regionMD5){
            endpoint_URI.addQuery("r", options.regionMD5);
        }

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (result) => {
                    return resolve(result);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}

export const FORMATS = {
    json: 'json',
    aa: 'aa',
    fasta: 'fasta'
};

export default SequencesBroker;