import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { webservers, software } from "../../static/markslab/resources/sections";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

// TODO: if link is internal, use link and not href!
import {Link} from 'react-router-dom';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        paddingBottom: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 20,
        paddingBottom: 20
    }),
    card: {
        position: "relative"
    },
    new: {
        position: "absolute",
        top: 5,
        right: 5,
        background: theme.palette.primary.main,
        borderRadius: "5%",
        padding: 3,
        textAlign: "center",
    },
    newText: {
        color: theme.palette.primary.contrastText,
    },
    cardContent: {
        height: 150,
    },
    link: {
        textDecoration: "none"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: "inherit"
    },
});


class Resources extends React.Component {

    render(){
        const { classes } = this.props;

        return (<div className={classes.content}>

            <Grid spacing={16} container>
                <Grid xs={12} item>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Webserver and resources
                        </Typography>
                        <Typography variant="body1">
                            Evolutionary couplings can be used to predict many interesting aspects about protein and RNA molecules from sequence alone. Here is what we have worked on so far:
                        </Typography>
                    </Paper>
                </Grid>

                {webservers.map((e,i) => {
                    let {title, pic, description, link, route, updated} = e;

                    let LinkElement = "a";

                    if(route !== undefined) LinkElement = Link;

                    return (
                        <Grid xs={12} md={3} xl={3} key={i} item>
                            <Card className={classes.card}>
                                {pic &&
                                <LinkElement to={route} href={link} className={classes.link}>
                                    <CardMedia
                                        className={classes.media}
                                        image={pic}
                                        title={title}
                                    />
                                </LinkElement>}
                                {updated && <div className={classes.new}>
                                    <Typography className={classes.newText} variant="body1">Updated!</Typography>
                                </div>}
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {title}
                                    </Typography>
                                    <Typography component="p">
                                        {description}
                                    </Typography>
                                </CardContent>

                                <LinkElement to={route} href={link} className={classes.link}>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Go
                                        </Button>
                                    </CardActions>
                                </LinkElement>
                            </Card>
                        </Grid>);
                })}
                <Grid xs={12} item>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Software and source code
                        </Typography>
                        <Typography variant="body1">
                            We provide access to our evolutionary couplings methods as open source software. For details about each tool, please refer to the individual repositories.
                        </Typography>
                    </Paper>
                </Grid>

                {software.map((e,i) => {
                    let {title, pic, description, link, updated} = e;

                    return (
                        <Grid xs={12} md={3} xl={3} key={i} item>
                            <Card className={classes.card}>
                                {pic &&
                                <a href={link} className={classes.link}>
                                    <CardMedia
                                        className={classes.media}
                                        image={pic}
                                        title={title}
                                    />
                                </a>
                                }
                                {updated && <div className={classes.new}>
                                    <Typography className={classes.newText} variant="body1">Updated!</Typography>
                                </div>}
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {title}
                                    </Typography>
                                    <Typography component="p">
                                        {description}
                                    </Typography>
                                </CardContent>
                                <a href={link} className={classes.link}>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Go
                                        </Button>
                                    </CardActions>
                                </a>

                            </Card>
                        </Grid>);
                })}
            </Grid>
        </div>);
    }
}

Resources.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Resources);