import {API_ENDPOINTS, REQUEST_TIMEOUT} from "../../global";
import jQuery from "jquery";

class FilesList {

    static run(hash, file){

        const endpoint = API_ENDPOINTS.runGetFilesList;

        let endpoint_URI = endpoint.uri.clone();
        endpoint_URI.addQuery("q", hash);

        if(file !== undefined){
            endpoint_URI.addQuery("f", file);
        }

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                type: endpoint.method,
                contentType: "application/json; charset=utf-8",
                url: endpoint_URI,
                timeout: REQUEST_TIMEOUT,
                success: (...args) => {
                    let files = args[0];

                    let groups = {};

                    files.forEach((f) => {
                        f.aliases.filter(a => a.match(/(.+)(file)(.*)/)).map(a => groups[a] !== undefined ? groups[a].push(f) : groups[a] = [f]);
                    });

                    return resolve(groups);
                },
                error: (...args) => {
                    return reject(args);
                },
            })
        });
    }
}


export default FilesList;