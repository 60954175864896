function JobSearch(state = {
    jobSearchStatus: jobSearchStatus.NO_RESULTS,
    jobs: []

}, action) {
    switch (action.type) {
        case 'JOB_SEARCH_RESULTS':
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default JobSearch;

export const jobSearchStatus = {
    NO_RESULTS: 0,
    REQUESTING: 1,
    RESULTS: 2,
    REQUEST_ERROR: 3
};

