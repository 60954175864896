import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {fetchNGLDataFromFile, NGLComponent} from 'chell-viz';
import Retriever from '../../utilities/Retriever';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FileError } from "../../utilities/FileErrors";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {queryType} from "../../brokers/precomputed/PrecomputedDownload";
import PrecomputedStructuresRanking from "../../brokers/precomputed/PrecomputedStructuresRanking";
import FilesList from "../../brokers/runs/download/FilesList";
import RunResultsFetcher, {RESULTS_KEYS} from "../../brokers/runs/results/RunResults";
import {files as fileNames} from "../../brokers/runs/download/DownloadFile";
import PrecomputedBroker from "../../brokers/precomputed/PrecomputedFetch";

const styles = theme => ({
    paper: {
        padding: 0,
        minHeight: 400,
    },
    center: {
        textAlign: "center"
    },
    loading: {
        minHeight: 400,
        backgroundColor: "#fafafa"
    },
    spinner: {
        marginTop: 150
    },
    errorIcon: {
        lineHeight: "300px",
        fontSize: "4em"
    },
    justify: {
        textAlign: "justify"
    }
});

const requestStatus = {
    loading: 1,
    error: 2,
    downloaded: 3
};

const sources = {
    predicted: 1,
    known: 2,
    none: 0
};

class PDBViewer extends React.Component {

    state = {
        status: requestStatus.loading,
        fileNeeded: null,
        fileDownloaded: null,
        PDBRanking: null,
        PDBData: null,
        remappedPDBFiles: null,
        source: sources.none,
        stepsNeeded: 2
    };

    getPDBRanking = () => {
        if(this.props.run.runHash !== undefined){
            RunResultsFetcher.run(this.props.run.runHash, RESULTS_KEYS.predictedPDBRanking)
                .then(ranking => {
                    this.setState({
                        PDBRanking: ranking,
                        fileNeeded: this.props.filename || ranking[0].filename,
                        stepsNeeded: 1,
                        source: sources.predicted
                    }, this.getData);
                })
                .catch(error => this.handleError(error));
        } else {
            PrecomputedStructuresRanking.run(this.props.run.uniprotID, this.props.run.start, this.props.run.end, this.props.run.batch)
                .then(results => {
                    let ranking = results[0];

                    this.setState({
                        PDBRanking: ranking,
                        fileNeeded: this.props.filename || ranking[0].filename,
                        stepsNeeded: 1
                    }, this.getData);
                })
                .catch(e => console.error(e))
        }
    };

    getRemappedFilesList = () => {
        if(this.props.run.runHash !== undefined){
            FilesList.run(this.props.run.runHash, fileNames.remappedPDBFiles)
                .then(list => {
                    this.setState({
                        remappedPDBFiles: list[fileNames.remappedPDBFiles],
                    });
                })
                .catch(error => this.handleError(error));
        } else {
            PrecomputedBroker.run(this.props.run.uniprotID, this.props.run.start, this.props.run.end)
                .then(results => {

                    let job = results[0];
                    let batch = job.batches[this.props.run.batch];
                    let remappedPDBFiles = batch[fileNames.remappedPDBFiles];

                    this.setState({
                        remappedPDBFiles: remappedPDBFiles.map(filePath => {

                            let pathParts = filePath.split('/');

                            return {
                                filename: pathParts[pathParts.length-1]
                            }
                        })
                    });
                })
                .catch(error => this.handleError(error));
        }
    };

    componentDidMount(){
        this.getPDBRanking();
        this.getRemappedFilesList();
    }

    handleError = (error) => {
        console.error(error);

        this.setState({
            status: requestStatus.error
        });
    };

    handleChange = event => {
        let filename = event.target.value;

        if(filename !== this.state.fileNeeded){
            this.props.onFileChange && this.props.onFileChange(filename);

            this.setState({
                fileNeeded: filename,
                stepsNeeded: 1,
                status: requestStatus.loading
            }, this.getData);
        }
    };

    handleSourceChange = event => {
        let source = event.target.value;

        if(source !== this.state.source){
            switch (source) {
                case sources.known:
                    this.setState({
                        fileNeeded: this.state.remappedPDBFiles && this.state.remappedPDBFiles[0].filename,
                        source: source,
                    }, this.getData);
                    break;
                case sources.predicted:
                    this.setState({
                        fileNeeded: this.state.PDBRanking && this.state.PDBRanking[0].filename,
                        source: source,
                    }, this.getData);
                    break;
                default:
                    // Unknown source!!
                    console.error("Trying to select unexisting source " + source);
            }
        }
    };

    getData = () => {
        let retriever = new Retriever(this.props.run);

        retriever
            .fetch({
                type: queryType.file,
                query: this.state.fileNeeded
            })
            .then(data => {
                this.setState({
                    fileDownloaded: {
                        file: this.state.fileNeeded,
                        data: data,
                        stepsNeeded: 0
                    },
                }, this.buildComponent)
            })
            .catch(error => this.handleError(error));
    };

    buildComponent = () => {
        let f = this.state.fileDownloaded;

        if(f.file.substr(f.file.length-4, f.file.length) === ".pdb"){
            let stringBlob = new Blob( [ f.data ], { type: 'text/plain'} );

            fetchNGLDataFromFile(stringBlob, {ext: "pdb"})
                .then(e => {
                    this.setState({
                        PDBData: e,
                        status: requestStatus.downloaded,
                        stepsNeeded: 0
                    })
                })
                .catch(error => this.handleError(error));
        } else {
            throw new FileError("Cannot interpret " + f.file);
        }
    };

    render() {
        const { classes } = this.props;
        const { status } = this.state;

        let display =  <Paper square elevation={0} className={classes.paper}>Loading...</Paper>;

        switch(status){
            case requestStatus.error:
                display =  <div className={classes.center}>
                    <div className={classes.loading}>
                        <span role={"img"} aria-label={"Not found"} className={classes.errorIcon}>🧐</span>
                    </div>
                    <Typography variant={"body1"} className={classes.justify}>
                        {`The files necessary to render the component can not be downloaded.
                                If the run you are exploring has not terminated yet, check back later!
                                You can change the source (known structure hits from MSA vs. predicted structures) from the dropdown above.
                                If this problem persists, drop us an email.
                                `}
                    </Typography>
                </div>;
                break;
            case requestStatus.downloaded:
                display =
                    <NGLComponent
                        data={this.state.PDBData}
                        width="100%"
                        height={ this.props.height || "400px"}
                    />;
                break;
            case requestStatus.loading:
            default:
                display =  <div className={classes.center}>
                    <div className={classes.loading}>
                        <CircularProgress className={classes.spinner}/>
                    </div>
                    <Typography variant={"body1"}>
                        {this.state.stepsNeeded + " step" + (this.state.stepsNeeded !== 1 ? "s" : "") + " left."}
                    </Typography>
                </div>;
                break;
        }

        return <Paper square elevation={0} className={classes.paper}>
            <FormControl className={classes.formControl}>
                <Select
                    value={this.state.source}
                    onChange={this.handleSourceChange}
                >
                    {this.state.PDBRanking && <MenuItem value={sources.predicted}>
                        Predicted structures
                    </MenuItem>}
                    {this.state.remappedPDBFiles && <MenuItem value={sources.known}>
                        Known structures
                    </MenuItem>}
                    {this.state.source === sources.none && <MenuItem value={sources.none}>
                        Select a source
                    </MenuItem>}
                </Select>
                <br />
                {this.state.source === sources.predicted && this.state.PDBRanking &&
                <Select
                    value={this.state.fileNeeded}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'file',
                        id: 'pdb-selection',
                    }}
                >
                    {this.state.PDBRanking.map( (f, i) =>
                        <MenuItem
                            key={f.filename}
                            value={f.filename}
                        >
                            {this.props.run.runHash && "Ranked " + (i+1) + " structure: " + f.filename.substr(33, f.filename.length-33-4)}
                            {this.props.run.uniprotID && "Ranked " + (i+1) + " structure: " + f.filename.substr(0, f.filename.length-4)}
                        </MenuItem>)}
                </Select>}
                {this.state.source === sources.known && this.state.remappedPDBFiles &&
                <Select
                    value={this.state.fileNeeded}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'pdb-file',
                        id: 'known-pdb-selection',
                    }}
                >
                    {this.state.remappedPDBFiles.map( f =>
                        <MenuItem
                            key={f.filename}
                            value={f.filename}
                        >
                            {this.props.run.runHash && "Structure hit: " + f.filename.substr(33, f.filename.length-33-4)}
                            {this.props.run.uniprotID && "Structure hit: " + f.filename.substr(0, f.filename.length-4)}
                        </MenuItem>
                    )}
                </Select>}
                <br />
            </FormControl>
            {display}
        </Paper>;

    }
}

PDBViewer.propTypes = {
    classes: PropTypes.object.isRequired,
    run: PropTypes.object.isRequired,
    filename: PropTypes.string,
    height: PropTypes.string,
    onFileChange: PropTypes.func
};

export default withStyles(styles)(PDBViewer);