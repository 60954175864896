import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";

// Store
import storeComponentWrapper from '../../stores/jobDispatcher';

// Components
import Signup from '../../components/users/Signup';

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 500,
        },
    },
    fadingComponents: {
        transition: '.5s'
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16
    }),
});

class SignUpPage extends React.Component {
    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xs={12}>
                    <Signup/>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Link className={classes.a} to={"/login"}>
                            <Typography variant={"body1"}>
                                Already have an account?
                            </Typography>
                        </Link>
                    </Paper>
                </Grid>
            </Grid>
        </div>);
    }
}

SignUpPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    match: PropTypes.object,
    jobSearch: PropTypes.object
};

export default storeComponentWrapper(withStyles(styles)(SignUpPage));