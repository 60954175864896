import React, { Component } from 'react';
import { theme } from './theme';
import './root.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { store, history } from '../../stores/index'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { Route, Switch } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';

import Navigation from '../../components/navigation/Navigation';
import Drawer from '../../components/navigation/Drawer';

import ReactGA from 'react-ga';

// Pages
import JobSubmissionPage from '../jobs/JobSubmissionPage';
import ComplexJobSubmissionPage from '../jobs/ComplexJobSubmissionPage';
import JobChoice from '../jobs/JobChoice';
import OptionsFAQ from '../FAQ/OptionsFAQ';
import ResultsFAQ from '../FAQ/ResultsFAQ';
import StagesFAQ from '../FAQ/StagesFAQ';
import FAQ from "../FAQ/FAQ";
import JobStatusPage from '../jobs/JobStatusPage';
import LoginPage from '../users/LoginPage';
import SignUpPage from '../users/SignUpPage';
import UserInfoPage from '../users/UserInfoPage';
import UserJobsPage from '../users/UserJobsPage';
import Studio from '../studio/Studio';
import FourOhFOur from './404';
import Resources from '../markslab/Resources';
import Home from '../markslab/Home';
import People from '../markslab/People';
import Cite from '../markslab/Cite';
import TablePage from '../table/TablePage';
import NGLPage from '../NGL/NGLPage';
import PrecomputedSearchPage from '../precomputed/PrecomputedSearchPage';
import PrecomputedListPage from '../precomputed/PrecomputedListPage';
import PrecomputedChoicePage from '../precomputed/PrecomputedChoicePage';
import CASPPrecomputedPage from '../precomputed/CASPPrecomputedPage';
import RibbonViewerPage from "../RibbonViewer/RibbonViewerPage";
import ContactMapPage from "../contactMap/ContactMapPage";
import ThreeDSeq from '../3Dseq/3Dseq'


const styles = theme => ({
    root: {
        width: '100%',
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
    },
    content: {
        width: '100%',
        marginTop: 52,
        [theme.breakpoints.down('sm')]: {
            marginTop: 44,
        },
    },
});

class App extends Component {
    state = {
        drawerStateOpen: false,
    };

    handleDrawerChange = (state) => {
        this.setState({ drawerStateOpen: state });
    };

    render() {
        const { classes } = this.props;

        ReactGA.initialize(process.env.REACT_GA || "UA-116331218-3");

        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root}>
                            <div className={classes.appFrame}>
                                <Navigation
                                    drawerState={this.state.drawerStateOpen}
                                    onDrawerChange={this.handleDrawerChange}
                                />
                                <Drawer
                                    drawerState={this.state.drawerStateOpen}
                                    onDrawerChange={this.handleDrawerChange}
                                />
                                <main className={classes.content}>
                                    <Switch>
                                        <Route exact path="/" component={Home}/>
                                        <Route exact path="/job" component={JobSubmissionPage}/>
                                        <Route exact path="/complex" component={ComplexJobSubmissionPage}/>
                                        <Route exact path="/choice" component={JobChoice}/>
                                        <Route exact path="/people" component={People}/>
                                        <Route exact path="/cite" component={Cite}/>
                                        <Route exact path="/resources" component={Resources}/>

                                        <Route exact path="/3Dseq" component={ThreeDSeq}/>


                                        <Route exact path="/faq" component={FAQ}/>
                                        <Route exact path="/faq/options" component={OptionsFAQ}/>
                                        <Route exact path="/faq/results" component={ResultsFAQ}/>
                                        <Route exact path="/faq/pipelines" component={StagesFAQ}/>

                                        <Route exact path="/user" component={UserInfoPage}/>
                                        <Route exact path="/user/jobs" component={UserJobsPage} />

                                        <Route path="/jobs/:jobHash" component={JobStatusPage}/>

                                        <Route exact path="/precomputed" component={PrecomputedChoicePage}/>
                                        <Route exact path="/precomputed/list" component={PrecomputedListPage}/>
                                        <Route exact path="/precomputed/search" component={PrecomputedSearchPage}/>
                                        <Route exact path="/precomputed/casp" component={CASPPrecomputedPage}/>
                                        {/*<Route path="/precomputed/:searchTerm" component={JobStatusPage}/>*/}


                                        <Route path="/login" component={LoginPage}/>
                                        <Route path="/signup" component={SignUpPage}/>
                                        <Route path="/studio" component={Studio} />
                                        <Route path="/table" component={TablePage} />
                                        <Route path="/ribbon" component={RibbonViewerPage} />
                                        <Route path="/ngl" component={NGLPage} />
                                        <Route path="/contact" component={ContactMapPage} />
                                        <Route path="/whops" component={FourOhFOur} />
                                        <Route component={FourOhFOur} />
                                    </Switch>
                                </main>
                            </div>
                        </div>
                    </MuiThemeProvider>
                </ConnectedRouter>
            </Provider>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};


export default withStyles(styles, { withTheme: true })(App);


