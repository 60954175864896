import React from 'react';
import PropTypes from 'prop-types';
import storeComponentWrapper from '../../../stores/jobDispatcher';
import Input from '@material-ui/core/Input';

class StartingPositionElement extends React.Component {

    constructor(props){
        super(props);

        if(this.props.complexSubmissionIdentifier !== undefined){
            this.state = {
                startingPosition: this.props.complexJobParameters.proteins[this.props.complexSubmissionIdentifier].startingPosition || 1,
            };
        } else {
            this.state = {
                startingPosition: this.props.jobParameters.startingPosition || 1,
            };
        }

        this.jobParametersAction = this.props.complexSubmissionIdentifier !== undefined ? "SET_COMPLEX_JOB_PARAMETERS" : "SET_JOB_PARAMETERS";
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });

        this.props.action({
            type: this.jobParametersAction,
            payload: {
                [name]: event.target.value,
                forProtein: this.props.complexSubmissionIdentifier
            }
        });
    };

    render() {

        return (<Input
            value={this.state.startingPosition}
            onChange={this.handleChange('startingPosition')}
            inputProps={{
                type: "number",
                min: 0,
                step: 1
            }}
        />)
    }
}

StartingPositionElement.propTypes = {
    action: PropTypes.func,
    jobParameters: PropTypes.object,
    complexSubmissionIdentifier: PropTypes.number
};


export default storeComponentWrapper((StartingPositionElement));