import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";

// Images
import protein from '../../static/markslab/resources/pictures/protein.png';
import complex from '../../static/markslab/resources/pictures/complex.png';



const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        paddingBottom: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 20,
        paddingBottom: 20
    }),
    card: {
        position: "relative"
    },
    new: {
        position: "absolute",
        top: 5,
        right: 5,
        background: theme.palette.primary.main,
        borderRadius: "5%",
        padding: 3,
        textAlign: "center",
    },
    newText: {
        color: theme.palette.primary.contrastText,
    },
    cardContent: {
        height: 150,
    },
    link: {
        textDecoration: "none"
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: "inherit"
    },
});


class JobChoice extends React.Component {

    render(){
        const { classes } = this.props;

        return (<div className={classes.content}>

            <Grid spacing={16} container>
                <Grid xs={12} item style={{textAlign: "center"}}>
                    <Typography component={"div"} variant="h5">
                        Chose the type of job you want to submit
                    </Typography>
                    <Typography component={"div"} variant="body1">
                        You can submit a monomer (single protein) or complex (two proteins) job.
                    </Typography>
                </Grid>

                <Grid xl={6} md={6} xs={12} item>
                    <Card className={classes.card}>
                        <Link to={'job'} className={classes.link}>
                            <CardMedia
                                className={classes.media}
                                image={protein}
                                title={"monomer pipeline"}
                            />
                        </Link>
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {"Monomer pipeline"}
                            </Typography>
                            <Typography component="p">
                                {"Compute evolutionary couplings from sequence alignments and predict 3D structure for your protein of interest."}
                            </Typography>
                        </CardContent>

                        <Link to={'job'} className={classes.link}>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Go
                                </Button>
                            </CardActions>
                        </Link>
                    </Card>
                </Grid>

                <Grid xl={6} md={6} xs={12} item>
                    <Card className={classes.card}>
                        <Link to={'complex'} className={classes.link}>
                            <CardMedia
                                className={classes.media}
                                image={complex}
                                title={"complex pipeline"}
                            />
                        </Link>
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {"Complex pipeline"}
                            </Typography>
                            <Typography component="p">
                                {"Predict interacting residues in protein complexes from sequence covariation for your complex of interest."}
                            </Typography>
                        </CardContent>

                        <Link to={'complex'} className={classes.link}>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Go
                                </Button>
                            </CardActions>
                        </Link>
                    </Card>
                </Grid>

            </Grid>
        </div>);
    }
}

JobChoice.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(JobChoice);