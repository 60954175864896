import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        textAlign: "center"
    }),
    big: {
        fontSize: "5em"
    }
});


class FourOhFOur extends React.Component {

    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Paper className={classes.paper}>
                <Typography variant={"h6"}>
                    This page was not found.
                </Typography>
                <br />
                <br />
                <span role={"img"} aria-label={"don't know"} className={classes.big}>🤷‍♀️</span>
            </Paper>
        </div>);
    }
}

FourOhFOur.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FourOhFOur);