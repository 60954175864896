import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

const styles = theme => ({
    content: theme.mixins.gutters({
        paddingTop: 30,
        flex: '1 1 100%',
        maxWidth: '100%',
        margin: '0 auto',
    }),
    [theme.breakpoints.up(900 + theme.spacing.unit * 6)]: {
        content: {
            maxWidth: 1140,
        },
    },
    paper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        minHeight: 100
    }),
    link: {
        textDecoration: "none"
    }
});


class FAQ extends React.Component {
    render(){
        const { classes } = this.props;
        return (<div className={classes.content}>
            <Grid spacing={16} container>
                <Grid item xl={6} md={6} xs={12}>
                    <Link to="/faq/options" className={classes.link}>
                        <Paper square elevation={2} className={classes.paper}  >
                            <Typography variant="h6">
                                Advanced options explained
                            </Typography>
                            <Typography variant="body1">
                                Click here for information about the advanced options found
                                in the pipeline submission.
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                    <Link to="/faq/results" className={classes.link}>
                        <Paper square elevation={2} className={classes.paper}  >
                            <Typography variant="h6">
                                Results and common problems explained
                            </Typography>
                            <Typography variant="body1">
                                Click here for information about the fields found in the results page.
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                    <Link to="/faq/pipelines" className={classes.link}>
                        <Paper square elevation={2} className={classes.paper}  >
                            <Typography variant="h6">
                                Pipelines explained
                            </Typography>
                            <Typography variant="body1">
                                Click here for information about what the monomer and complex pipelines do.
                            </Typography>
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/debbiemarkslab/EVcouplings" className={classes.link}>
                        <Paper square elevation={2} className={classes.paper}  >
                            <Typography variant="h6">
                                Source on GitHub
                            </Typography>
                            <Typography variant="body1">
                                Stay up to date with the latest version of the Python pipeline
                                from our GitHub repository.
                            </Typography>
                        </Paper>
                    </a>
                </Grid>

            </Grid>
        </div>);
    }
}

FAQ.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FAQ);