import { createStore , combineReducers, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import ReactGa from 'react-ga';
import { analyticsPageMiddleware } from './analyticsPageMiddleware';

import JobParameters from './job/JobParameters';
import ComplexJobParameters from './job/ComplexJobParameters';
import JobValidationStatus from './job/JobValidationStatus';
import JobSearch from './job/JobSearch';
import RunSearch from './run/RunSearch';
import Studio from './studio/Studio';

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory({
    basename: ""
});



// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

function trackEventInGoogleAnalytics({ getState }) {
    return next => action => {
        if (action.type !== '@@router/UPDATE_LOCATION' && action.type !== '@@router/LOCATION_CHANGE' ){
            ReactGa.event({
                category: action.type,
                action: JSON.stringify(action.payload)
            });
        }

        return next(action);
    };
}

const reducer = combineReducers({
    jobParameters: JobParameters,
    complexJobParameters: ComplexJobParameters,
    jobValidationStatus: JobValidationStatus,
    jobSearch: JobSearch,
    runSearch: RunSearch,
    studio: Studio,
    router: routerReducer,
});

const store  = createStore(reducer, applyMiddleware(middleware, trackEventInGoogleAnalytics, analyticsPageMiddleware));

export { store, history };