import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import md5 from 'md5';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Space = () => <div style={{margin: "2em 0"}} />;

const styles = theme => ({
    referenceListItem: {
        marginLeft: '.3em',
    },
    section: {
        marginTop: "1em",
        marginBottom :"1em"
    },
    tableName: {
        padding: 0
    }
});

class OptionDescription extends React.Component {
    render() {
        const { classes, component } = this.props;

        return (
            <div>
                <Typography variant="h6">
                    {component.title}
                </Typography>
                <Typography variant="body1" style={{fontSize: ".7em"}}>
                    #{component.identifier}
                </Typography>
                {component.description.map(description =>
                    <Typography key={md5(description)} variant="body1" gutterBottom align="justify">
                        {description}
                    </Typography>
                )}

                {component.values && <div className={classes.section}>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableName}>Value</TableCell>
                                <TableCell>Interpretation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {component.values.map(valueExample => {
                                return (
                                    <TableRow key={valueExample.value}>
                                        <TableCell className={classes.tableName}><code>{valueExample.value}</code></TableCell>
                                        <TableCell>{valueExample.meaning}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>}

                {component.valueLimitations && <div className={classes.section}>
                    <Typography variant="caption">
                        Limitations:
                    </Typography>
                    <Typography variant="body1">
                        {component.valueLimitations}
                    </Typography>
                </div>}

                {(component.references || component.seeAlso) && <Space />}

                {component.references && <Typography variant="caption" className={classes.section}>
                    References: {component.references.map((value, index) => <span className={classes.referenceListItem} key={md5(value)}><a target={"_tab"} href={value}>[{1+index}]</a></span>)}
                </Typography>}
                {component.seeAlso && <Typography variant="caption" className={classes.section}>
                    See also: {component.seeAlso.map((value, index) => <span className={classes.referenceListItem} key={md5(value)}><a target={"_tab"} href={value}>[{1+index}]</a></span>)}
                </Typography>}
            </div>
        );
    }
}

OptionDescription.propTypes = {
    classes: PropTypes.object.isRequired,
    component: PropTypes.object,
};

export default withStyles(styles)(OptionDescription);